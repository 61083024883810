body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.tox .tox-notification--in {
  display: none !important;
}

.tox .tox-statusbar {
  display: none !important;
}

.video-styling-candidate{
  width: 500px;
  height: 200px;
}

@media (max-width: 896px) {
  .video-styling-candidate{
  width: 300px;
  height: 200px;
  }

}