.home-slider .carousel-control-prev, .carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: 0.5;
    transition: opacity 0.15s ease;
}

.home-slider .carousel-control-next:focus, .carousel-control-prev:focus {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 15%;
    color: #fff;
    text-align: center !important;
    opacity: 0.5;
    transition: opacity 0.15s ease;
}

.home-slider .carousel-control-next:hover, .carousel-control-prev:hover {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}