
/*---------------------------------------------
	17.0 Pricing Style
---------------------------------------------*/
$primaryColor: #246df8;
$fontColor: #6f7484;
$white: #ffffff;
$black: #000000;
$blackRussian: #101725;
$blackRussianLite: #232630;
$aliceBlue: #f7f9fc;
$aliceBlueLite: #f9fbfe;
$aliceBluedark: #515666;
$solitude: #f0f3f7;
$snow: #fbfbfb;


$headerFont: 'Poppins', sans-serif;
$bodyFont: 'Roboto', sans-serif;

.pricing-controller {
  display: flex;
  margin-bottom: 50px;
  justify-content: center;
  .switch-wrap {
    background: rgba($primaryColor, .1);
    height: 26px;
    width: 50px;
    border-radius: 30px;
    position: relative;
    margin: 0 15px;
    cursor: pointer;
    .price-switch {
      background: #19bb4c;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      display: inline-block;
      position: absolute;
      top: 3px;
      left: 3px;
      transition: all .6s ease;
      &.year-active {
        left: 27px;
      }
    }
  }
  .duration {
    font-size: 1.6rem;
    font-weight: 500;
    color: #aaa; 
    &.active {
      color: $blackRussian;
    }
    span {
      color: #19bb4c;
      font-size: 1.2rem;
      text-transform: uppercase;
      padding: 2px 4px;
      border-radius: 3px;
    }
  }
}

.package-wrapper {
	display: flex;
	.package {
		flex-grow: 1;
		.package-header {
			padding: 40px;
			background: $primaryColor;
			color: $white;
			.cost {
				font-size: 2.6rem;
				font-family: $headerFont;
				font-weight: 500;
				span {
					font-family: $bodyFont;
					font-weight: 400;
					font-size: 1.4rem;
					margin-left: 5px;
				}
			}
			.cost.hidden {
				display: none;
				visibility: hidden;
			}
			.title {
				font-size: 1.6rem;
				text-transform: uppercase;
				font-family: $bodyFont;
				font-weight: 400;
				letter-spacing: 3px;
				margin-top: 5px;
				display: block;
			}
		}
		&.professional {
			.package-header {
				background: url(../images/bg/package-header-bg.jpg) no-repeat center;
				background-size: cover;
			}
		}
		.package-features {
			padding: 40px;
			padding-bottom: 0;
			ul {
				padding: 0;
				margin: 0;
				list-style: none;
				font-size: 0;
				li {
					font-size: 1.4rem;
					color: #bdbdbd;
					position: relative;
					padding-left: 30px;
					&.active {
						color: $fontColor;
					}
					&:before {
						content: '\f058';
						font-family: 'Font Awesome\ 5 Free';
						font-weight: 900;
						position: absolute;
						font-size: 1.2rem;
						top: 2px;
						left: 0;
					}
					&.active:before {
						color: #19bb4c;
					}
					+ li {
						margin-top: 10px;
					}
				}
			}
			.button {
				margin-top: 30px;
				background: $primaryColor;
				padding: 11px 25px;
				color: $white;
			}
		}
	}
}



@media all and (max-width: 767px){
	.package-wrapper {
		display: block;
		.package+.package {
			margin-top: 40px;
		}
	}
}