
/*---------------------------------------------
	22.0 About Style
---------------------------------------------*/

.others-block {
  box-shadow: 0px 5px 20px 0px rgba(#000000, 0.03);
}

.about-company {
	padding: 60px 30px;
	// background: url(../images/bg/bg-map.jpg) no-repeat center;
	background-size: cover;
}

.feature-content {
	h3 {
		font-family: 'Poppins', sans-serif;
		font-weight: 600;
		font-size: 2.6rem;
		margin-bottom: 30px;
	}
	p {
		color: #6f7484;
	}
	p + p {
		margin-top: 20px;
	}
	.button {
		background: #246df8;
		color: #ffffff;
		border-radius: 3px;
		font-family: 'Poppins', sans-serif;
		font-weight: 500;
		font-size: 1.4rem;
		padding: 11px 30px;
		margin-top: 20px;
	}
}


.infobox-wrap {
	padding: 0 30px 60px;
}

.info-box {
	position: relative;
	padding-left: 65px;
	padding-bottom: 30px;
	&:before {
		position: absolute;
		content: '';
		top: 60px;
		left: 25px;
		bottom: 30px;
		width: 2px;
		border-left: 2px dashed rgba(#000000, .08);
	}
	.icon {
		position: absolute;
		height: 50px;
		width: 50px;
		border-radius: 50%;
		left: 0;
		top: 0;
		border: 1px solid rgba(#000000, .08);
		svg {
			height: 20px;
			width: 20px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: #246df8;
		}
	}
	h4 {
		font-size: 1.6rem;
		font-family: 'Poppins', sans-serif;
		font-weight: 600;
		padding-bottom: 10px;
	}
	p {
		color: #6f7484;
	}
}


@media all and (max-width: 991px) {
	.feature-content {
		margin-top: 30px;
	}
}