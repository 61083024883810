
/*---------------------------------------------
	02.0 Banner Style
---------------------------------------------*/
$primaryColor: #246df8;
$fontColor: #6f7484;
$white: #ffffff;
$black: #000000;
$blackRussian: #101725;
$blackRussianLite: #232630;
$aliceBlue: #f7f9fc;
$aliceBlueLite: #f9fbfe;
$aliceBluedark: #515666;
$solitude: #f0f3f7;
$snow: #fbfbfb;


$headerFont: 'Poppins', sans-serif;
$bodyFont: 'Roboto', sans-serif;

.banner-1-bg {
	background: url(../images/bg/banner-1-bg.jpg) no-repeat bottom left;
	background-size: cover;
}

.banner-1 .banner-content {
	padding: 250px 0 270px;
	color: $white;
	h1 {
		font-family: $headerFont;
		font-size: 4.2rem;
		font-weight: 600;
		margin-bottom: 15px;
	}
	p {
		color: #f5f8fe;
		font-size: 1.6rem;
		font-family: $bodyFont;
		max-width: 500px;
		position: relative;
		&:before {
			position: absolute;
	    content: url(../images/curv-arrow-2.png);
	    top: 100%;
	    left: 190px;
		}

	}
	.button {
		background: rgba($white, .15);
		padding: 14px 30px;
		margin-top: 20px;
		font-family: $headerFont;
		font-weight: 500;
		text-transform: uppercase;
		&:hover {
			background: $white;
			color: $primaryColor;
		}
	}
}

.banner-2-bg {
	background: url(../images/bg/banner-2-bg.jpg) no-repeat bottom left;
	background-size: cover;
}

.banner-2 .banner-content {
	padding: 215px 0 170px;
	color: $blackRussianLite;
	h1 {
		font-family: $headerFont;
		font-size: 4.2rem;
		font-weight: 600;
		margin-bottom: 25px;
	}
	p {
		color: $fontColor;
		font-size: 1.6rem;
		font-family: $bodyFont;
		max-width: 500px;
	}
	.short-infos {
		display: flex;
		max-width: 350px;
		margin-top: 40px;
		.info {
			flex-grow: 1;
			h4 {
				font-size: 2.8rem;
				color: $blackRussianLite;
				font-weight: 600;
			}
			span {
				font-size: 1.6rem;
				font-family: $bodyFont;
				font-weight: 400;
				color: $fontColor;
			}
		}
	}
}

.banner-3-bg {
	background: url(../images/bg/banner-3-bg.jpg) no-repeat center left;
	background-size: cover;
}

.banner-3 .banner-content {
	padding: 70px 0 190px;
	h1 {
		font-family: $headerFont;
		font-size: 4.2rem;
		font-weight: 600;
		margin-bottom: 15px;
		color: $blackRussianLite;
	}
	p {
		color: $fontColor;
		font-size: 1.6rem;
		font-family: $bodyFont;
		max-width: 500px;
		position: relative;
		&:before {
			position: absolute;
			content: url(../images/curv-arrow.png);
			top: 100%;
			left: 190px;
		}
	}
	.button {
		background: $primaryColor;
		color: $white;
		padding: 14px 30px;
		margin-top: 20px;
		font-family: $headerFont;
		font-weight: 500;
		text-transform: uppercase;
		&:hover {
			color: $white;
			background: $primaryColor;
		}
	}
}

.banner-4-bg {
	background: url(../images/bg/banner-4-bg.jpg) no-repeat center;
	background-size: cover;
}

.banner-4 .banner-content {
	padding: 290px 0 210px;
	text-align: center;
	color: $white;
	h1 {
		font-size: 4.2rem;
		margin-bottom: 20px;
	}
	p {
		font-size: 1.6rem;
	}
	.banner-search {
		background: rgba($white, .2);
		max-width: 970px;
		margin: 40px auto 0;
		padding: 30px;
		border-radius: 5px;
		.search-form {
			input {
				height: 55px;
				border: 0;
				border-bottom: 2px solid rgba($white, .15);
				background: transparent;
				padding: 0;
				color: $white;
				outline: none;
				width: calc(50% - 110px);
				margin-right: 30px;
				&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
				  color: $white;
				}
				&::-moz-placeholder { /* Firefox 19+ */
				  color: $white;
				}
				&:-ms-input-placeholder { /* IE 10+ */
				  color: $white;
				}
				&:-moz-placeholder { /* Firefox 18- */
				  color: $white;
				}
			}
			.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
				width: calc(50% - 110px);
				margin-right: 30px;
			}
			.btn {
				padding: 0;
				border: 0;
				border-bottom: 2px solid rgba($white, .15);
				height: 55px;
				background: transparent;
				border-radius: 0;
			}
			.bootstrap-select .dropdown-toggle .filter-option-inner-inner {
				line-height: 55px;
				font-size: 1.4rem;
				color: $white;
			}
			.dropdown-toggle::after {
		    border-top: 5px solid $white;
		    border-right: 5px solid transparent;
		    border-bottom: 0;
		    border-left: 5px solid transparent;
			}
			.bootstrap-select:focus {
				outline: none !important;
				box-shadow: none;
			}
			.btn:focus,
			.btn-light:focus {
				box-shadow: none;
			}
			.bootstrap-select .dropdown-toggle:active:focus {
				background: transparent;
				outline: none !important;
			}
			.bootstrap-select .dropdown-toggle:focus {
				outline: none !important;
				color: $white;
			}
			.dropdown-menu {
				font-size: 1.4rem;
			}
			.button {
				width: 150px;
				color: $white;
				height: 55px;
				border-radius: 5px;
				i {
					margin-right: 10px;
				}
			}
		}
		.trending-key {
			font-size: 1.4rem;
			font-weight: 500;
			margin-top: 30px;
			text-align: left;
			span {
				margin-right: 10px;
			}
			a {
				font-style: italic;
				margin-right: 10px;
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}

@media all and (max-width: 991px) {
	.banner-2 .banner-content {
		padding: 150px 0 170px;
	}
}

@media all and (max-width: 767px) {
	.banner-1 .banner-content,
	.banner-2 .banner-content,
	.banner-3 .banner-content,
	.banner-4 .banner-content {
		h1 {
			font-size: 3.4rem;
		}
	}
	.banner-4 .banner-content {
		padding: 200px 0 150px;
		.banner-search {
			.search-form {
				input,
				.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
					width: 100%;
					margin-right: 0;
					margin-bottom: 20px;
				}
			}
		}
	}
}