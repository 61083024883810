.calendar,
.calendar select,
.calendar table,
.calendar td,
.calendar th {
	background: #fff;
	color: #000;
	text-shadow: none;
	height: auto;
	width: auto;
	padding: 0;
	line-height: normal;
	border: 0;
	box-shadow: none;
	border-radius: 0;
	font-family: sans-serif;
	font-size: 14px;
}

.calendar {
	position: absolute;
	border: 1px solid #c0c0c0;
	text-align: center;
}

.calendar select {
	margin: 3px 5px;
	border: 1px solid #c0c0c0;
}

.calendar td,
.calendar th {
	width: 14%;
	padding: 4px;
	text-align: center;
}

.calendar td {
	cursor: pointer;
}

.calendar .selected {
	font-weight: bold;
}
