
/*----------------------------------------------
  19.0 Pagination Style
-----------------------------------------------*/


$primaryColor: #246df8;
$fontColor: #6f7484;
$white: #ffffff;
$black: #000000;
$blackRussian: #101725;
$blackRussianLite: #232630;
$aliceBlue: #f7f9fc;
$aliceBlueLite: #f9fbfe;
$aliceBluedark: #515666;
$solitude: #f0f3f7;
$snow: #fbfbfb;


$headerFont: 'Poppins', sans-serif;
$bodyFont: 'Roboto', sans-serif;

.pagination-list .pagination {
  padding: 40px 0 30px;
  &.navigation {
    margin-top: 10px;
    &.padding-top-60 {
      margin-top: 0;
    }
  }
  margin: 0;
  display: block;
  font-size: 0;
  .nav-links .page-numbers {
    height: 35px;
    width: 35px;
    line-height: 35px;
    border-radius: 3px;
    text-align: center;
    font-size: 1.5rem;
    display: inline-block;
    margin: 0 5px;
    font-weight: 400;
    color: $fontColor;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    position: relative;
    &:hover {
      color: $primaryColor;
    }
    &:last-child:before {
      border-right: 1px solid rgba($black, 0.08);
    }
    &.current {
      color: $white;
      background: $primaryColor;
      border-color: $primaryColor;
    }
  }
  .next,
  .prev {
    border: 1px solid rgba($black, .08);
    background: $aliceBlueLite;
  }
}




@media all and (max-width: 767px) {
  .pagination-list .pagination .nav-links .page-numbers {
    height: 32px;
    width: 32px;
    line-height: 32px;
    margin: 0 3px;
  }
}