
/*---------------------------------------------
	07.0 Candidate Style
---------------------------------------------*/

$primaryColor: #246df8;
$fontColor: #6f7484;
$white: #ffffff;
$black: #000000;
$blackRussian: #101725;
$blackRussianLite: #232630;
$aliceBlue: #f7f9fc;
$aliceBlueLite: #f9fbfe;
$aliceBluedark: #515666;
$solitude: #f0f3f7;
$snow: #fbfbfb;


$headerFont: 'Poppins', sans-serif;
$bodyFont: 'Roboto', sans-serif;

.job-listing-container {
	box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.03);
	  display: flex;
	  flex-direction: row-reverse;
	  flex-wrap: wrap;
	  .job-filter-wrapper {
		  min-width: 240px;
	  }
	  .filtered-job-listing-wrapper {
		  width: calc(100% - 240px);
	  }
	  .job-search-wrapper {
		  width: 100%;
		  flex-break: after;
	  }
  }

  .job-view-controller-wrapper .job-view-controller, .candidate-view-controller-wrapper .job-view-controller, .employer-view-controller-wrapper .job-view-controller, .job-view-controller-wrapper .candidate-view-controller, .candidate-view-controller-wrapper .candidate-view-controller, .employer-view-controller-wrapper .candidate-view-controller, .job-view-controller-wrapper .employer-view-controller, .candidate-view-controller-wrapper .employer-view-controller, .employer-view-controller-wrapper .employer-view-controller {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
  }

.candidate-container {
	@extend .job-listing-container;
}

.filtered-candidate-wrapper {
	@extend .filtered-job-listing-wrapper;
}

.candidate-view-controller-wrapper {
	@extend .job-view-controller-wrapper;
}

.candidate-filter-result {
	@extend .job-filter-result;
}

.candidate-filter-wrapper {
	@extend .job-filter-wrapper;
}

.candidate-view-controller {
	@extend .job-view-controller;
}

.candidate-view-filter {
	@extend .job-view-filter;
}

.candidate-filter-dropdown {
	@extend .job-filter-dropdown;
}

.candidate-filter {
	@extend .job-filter;
}

.candidate-type {
	@extend .job-type;
}

.candidate-filter-result.grid {
	position: relative;
	border-top: 1px solid rgba($black, .08);
	&:before {
		position: absolute;
		top: 30px;
		left: 50%;
		bottom: 0;
		width: 1px;
		background: rgba($black, .08);
		content: '';
	}
}

.candidate {
	display: flex;
	padding: 30px;
	transition: all .3s ease;
	border-bottom: 1px solid rgba($black, .08);
	.thumb {
		width: 80px;
		border-radius: 3px;
		overflow: hidden;
	}
	.thumb+.body {
		padding-left: 20px;
	}
	.body {
		display: flex;
		justify-content: space-between;
		width: 100%;
		align-items: center;
		.content {
			h4 {
				font-size: 1.8rem;
				font-family: $headerFont;
				font-weight: 500;
				margin-bottom: 12px;
				&:hover {
					color: $primaryColor;
				}
			}
			.info {
				span {
					color: $fontColor;
					margin-right: 15px;
					&.work-post {
						svg {
							color: rgba($primaryColor, .4);
						}
					}
					svg {
						height: 14px;
						width: 14px;
						margin-right: 7px;
						color: #aaa;
					}
				}
			}
		}
		.button-area {
			a {
				font-size: 1.4rem;
				font-family: $headerFont;
				font-weight: 600;
				padding: 8px 15px;
				color: $blackRussianLite;
				border-radius: 3px;
				background: #f9f9f9;
				border: 1px solid rgba($black, .08);
			}
		}
	}
	&:hover {
		border-bottom-color: transparent;
	  box-shadow: 0px 10px 20px 0px rgba($black, 0.05);
	  .body {
	  	.button-area {
	  		a {
	  			background: $primaryColor;
	  			border-color: $primaryColor;
	  			color: $white;
	  		}
	  	}
	  }
	}
}

.candidate.half-grid {
	width: calc(50% - 30px);
	display: block;
	text-align: center;
	.thumb {
		margin: 0 auto;
	}
	.thumb + .body {
		padding-left: 0;
	}
	.body {
		display: block;
		margin-top: 15px;
		.content {
			span {
				display: inline-block;
				margin-bottom: 8px;
			}
		}
		.button-area {
			margin-top: 10px;
		}
	}
}


/*---------------------------------------------
	Candidate Details
---------------------------------------------*/

.candidate-details {
	background: $white;
	padding: 30px;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.03);
}

.title-and-info {
	display: flex;
	justify-content: space-between;
	align-items: center;
	.title {
		display: flex;
		align-items: center;
		.thumb {
			width: 100px;
			border-radius: 3px;
			overflow: hidden;
		}
		.thumb + .title-body {
			padding-left: 20px;
		}
		.title-body {
			h4 {
				font-size: 2.6rem;
				font-weight: 600;
				padding-bottom: 15px;
			}
			.info {
				span {
					font-size: 1.4rem;
					font-weight: 400;
					font-family: $bodyFont;
					margin-right: 15px;
					color: $fontColor;
					svg {
						height: 15px;
						margin-right: 5px;
					}
				}
				.candidate-designation {
					svg {
						color: rgba($primaryColor, .4);
					}
				}
			}
		}
	}
	.download-resume {
		a {
			padding: 15px 30px;
			border-radius: 3px;
			background: $primaryColor;
			color: $white;
			font-size: 1.4rem;
			font-family: $headerFont;
			font-weight: 600;
			svg {
				height: 18px;
				height: 18px;
				margin-left: 10px;
			}
			&:hover {
				color: $white;
				background: $primaryColor;
			}
		}
	}
}
 
.skill-and-profile {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 30px;
	padding-bottom: 30px;
	border-bottom: 1px solid rgba($black, .08);
	label {
		font-size: 1.4rem;
		font-family: $headerFont;
		font-weight: 600;
		margin-right: 10px;
	}
	.skill {
		a {
			border: 1px solid rgba($black, .05);
			padding: 3px 15px;
			margin-right: 5px;
			border-radius: 3px;
			background-image: linear-gradient(to right, $aliceBlueLite, $white);
			&:hover {
				background: $primaryColor;
				border-color: $primaryColor;
				color: $white;
			}
		}
	}
	.social-profile {
		a {
			font-size: 1.4rem;
			margin-right: 15px;
			color: #828794;
			&:hover {
				color: $primaryColor;
			}
		}
	}
}

.details-information {
	border-bottom: 1px solid rgba($black, .08);
}

.details-section {
	padding-left: 30px;
	h4 {
		font-size: 1.8rem;
		position: relative;
		padding-bottom: 20px;
		svg {
			height: 22px;
			position: absolute;
			top: 0;
			left: -30px;
			color: #aaa;
		}
	}
	p {
		color: $fontColor;
	}
	p + p{
		margin-top: 20px;
	}
}

.details-section + .details-section {
	margin-top: 60px;
}

.edication-background {
	.education-label {
		position: relative;
		padding-left: 25px;
		&:before {
			position: absolute;
			content: '';
			height: 10px;
			width: 10px;
			border-radius: 50%;
			background: $primaryColor;
			top: 7px;
			left: 0;
		}
		.study-year {
			font-size: 1.4rem;
			color: $fontColor;
			margin-bottom: 5px;
			display: inline-block;
		}
		h5 {
			color: $blackRussianLite;
			font-family: $headerFont;
			font-size: 1.4rem;
			font-weight: 500;
			margin-bottom: 10px;
			span {
				font-family: $bodyFont;
				font-weight: 400;
				color: $fontColor;
				margin-left: 10px;
			}
		}
	}
	.education-label + .education-label {
		margin-top: 20px;
	}
}

.experience {
	.experience-section {
		position: relative;
		padding-left: 25px;
		&:before {
			position: absolute;
			content: '';
			height: 10px;
			width: 10px;
			border-radius: 50%;
			background: $primaryColor;
			top: 7px;
			left: 0;
		}
		.service-year {
			font-size: 1.4rem;
			color: $fontColor;
			margin-bottom: 5px;
			display: inline-block;
		}
		h5 {
			color: $blackRussianLite;
			font-family: $headerFont;
			font-size: 1.4rem;
			font-weight: 500;
			margin-bottom: 10px;
			span {
				font-family: $bodyFont;
				font-weight: 400;
				color: $fontColor;
				margin-left: 10px;
			}
		}
	}
	.experience-section + .experience-section {
		margin-top: 20px;
	}
}

.special-qualification {
	ul {
		padding: 0;
		margin: 0;
		list-style: none;
		font-size: 0;
		li {
			font-size: 1.4rem;
			font-family: $bodyFont;
			font-weight: 400;
			color: $fontColor;
			position: relative;
			padding-left: 20px;
			&:before {
				position: absolute;
				content: '\f058';
				font-family: 'Font Awesome\ 5 Free';
				font-weight: 900;
				top: 3px;
				left: 0;
				font-size: 1.2rem;
				color: #aaa;
			}
		}
		li + li {
			margin-top: 8px;
		}
	}
}

.portfolio {
	.portfolio-slider {
		.portfolio-item {
			position: relative;
			&:hover {
				.overlay {
					a {
						margin: 0 5px;
						opacity: 1;
					}
				}
			}
			.overlay {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				z-index: 999;
				width: 100%;
				text-align: center;
				a {
					height: 40px;
					width: 40px;
					border-radius: 50%;
					background: rgba($white, .8);
					text-align: center;
					line-height: 40px;
					margin: 0 23px;
					opacity: 0;
					color: $primaryColor;
					svg {
						height: 20px;
					}
				}
			}
		}
	}
}

.portfolio-slider {
	position: relative;
	.owl-item.active .portfolio-item:before {
		position: absolute;
		height: 100%;
		top: 0;
		left: 0;
		width: 100%;
		content: '';
		background-image: linear-gradient(to right, rgba($white, 1), rgba($white, 0));
	}
	.owl-item.active + .owl-item.active .portfolio-item:before {
		height: 0;
		width: 0;
		background-image: transparent;
	}
	.owl-item.active + .owl-item.active + .owl-item.active .portfolio-item:before {
		position: absolute;
		height: 100%;
		top: 0;
		right: 0;
		width: 100%;
		content: '';
		background-image: linear-gradient(to left, rgba($white, 1), rgba($white, 0));
	}
	.owl-nav {
		.owl-next, 
		.owl-prev {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			font-size: 1.8rem;
			outline: none;
		}
		.owl-next {
			right: 0;
		}
		.owl-prev {
			left: 0;
		}
	}
}

.personal-information {
	margin-top: 60px;
	ul {
		padding: 0;
		margin: 0;
		list-style: none;
		font-size: 0;
		li {
			font-size: 1.4rem;
			color: $fontColor;
			span {
				font-size: $headerFont;
				font-weight: 500;
				margin-right: 5px;
				color: $blackRussianLite;
			}
			+ li {
				margin-top: 8px;
			}
		}
	}
}

.information {
	padding: 30px;
	border: 1px solid rgba($black, .05);
	border-radius: 3px;
	background-image: linear-gradient(to left, rgba($aliceBlue, .0), rgba($aliceBlue, 1));
	h4 {
		font-size: 1.5rem;
		font-family: $headerFont;
		font-weight: 600;
		padding-bottom: 20px;
		margin-bottom: 30px;
		border-bottom: 1px solid rgba($black, .08);
	}
	ul {
		padding: 0;
		margin: 0;
		list-style: none;
		font-size: 0;
		li {
			font-size: 1.4rem;
			color: $fontColor;
			span {
				font-size: $headerFont;
				font-weight: 500;
				margin-right: 5px;
				color: $blackRussianLite;
			}
			+ li {
				margin-top: 8px;
			}
		}
	}
}

.information-and-contact {
	.buttons {
		margin-top: 30px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		.button {
			font-family: $headerFont;
			font-weight: 500;
			font-size: 1.4rem;
			+ .button {
				margin-top: 20px;
			}
		}
		.contact-button {
			flex-break: after;
			width: 100%;
			background: $primaryColor;
			padding: 14px; 
			border-radius: 3px;
			border: 1px solid $primaryColor;
			text-align: center;
			color: $white;
		}
		.cover-download,
		.contact-download {
			padding: 14px 5px;
			text-align: center;
			border: 1px solid rgba($black, .05);
			border-radius: 3px;
			width: calc(50% - 10px);
			background-image: linear-gradient(to left, rgba($aliceBlue, .0), rgba($aliceBlue, 1));
			svg {
				height: 15px;
			}
			&:hover {
				border-color: rgba($primaryColor, .2);
				color: $primaryColor;
			}
		}
	}
}

.contact-form-modal.show {
	display: flex !important;
	align-items: center;
	justify-content: center;
	.modal-dialog {
		max-width: 500px;
		width: 100%;
	}
}

.contact-form-modal {
	.modal-body {
		padding: 40px 30px;
		h4 {
			font-size: 1.5rem;
			text-transform: uppercase;
			margin-bottom: 20px;
			font-weight: 500;
			svg {
				height: 18px;
				margin-right: 10px;
				color: $fontColor;
			}
		}
		form {
			.form-group {
				margin-bottom: 30px;
			}
			.form-control {
				height: 40px;
				border: 0;
				box-shadow: none;
				border-bottom: 1px solid rgba($black, .1);
				transition: all .3s ease;
				border-radius: 0;
				font-size: 1.4rem;
				&:focus {
					border-color: $primaryColor;
				}
			}
			textarea.form-control {
				height: 100px;
			}
			.button {
				background: $primaryColor;
				padding: 11px 30px;
				color: $white;
				font-family: $headerFont;
				font-weight: 500;
			}
		}
	}
}

.basic-info-input {
	.add-new-field {
		color: $primaryColor;
		background: rgba($primaryColor, .15);
		border-radius: 3px;
		border: 0;
		font-size: 1.3rem;
		font-weight: 500;
		padding: 5px 20px;
		margin-bottom: 30px;
	}
}

.post-content-wrapper {
	.input-group {
		.form-control {
			padding-left: 15px;
		}
	}
	.input-group-prepend {
    margin-right: -1px;
    position: relative;
    .input-group-text {
    	width: 80px;
    	background: transparent;
	    margin-right: 1px;
	    border: 0;
	    font-size: 1.4rem;
	    padding-left: 0;
	    border-radius: 0;
	    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    }
    .dropdown-label {
	    overflow: hidden;
	    position: relative;
	    background: white;
	    width: 70px;
	    padding: 0;
	    border-bottom: 0;
	    &:before {
  	    position: absolute;
		    content: '';
		    top: 10px;
		    bottom: 10px;
		    right: 0;
		    width: 1px;
		    background: rgba($black, 0.08);
	    }
	    select {
		    background: transparent;
		    width: 100%;
		    border: 0;
		    border-radius: 0;
		    padding-left: 0.5em;
		    position: relative;
		    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
		    z-index: 10;
	    }
	    i {
  	    position: absolute;
		    right: 0.5em;
		    top: 13px;
		    z-index: 5;
		    color: black;
	    }
		}
	}
	.upload-portfolio-image {
		position: relative;
	}
	.update-photo {
	  height: 100px;
	  position: relative;
	  width: 100px;
	  margin: 0;
	  overflow: hidden;
	  border-radius: 3px;
	}
	.update-photo img {
	  height: 105%;
	  width: initial;
	  max-height: 100%;
	  max-width: initial;
	  position: absolute;
	  top: 50%;
	  left: 50%;
	  transform: translate(-50%, -50%);
	}
	.upload-portfolio-image .file-upload {
	  position: absolute;
	  bottom: 0;
	  left: 0;
	  overflow: hidden;
	  width: 100px;
	  border-bottom-right-radius: 3px;
	  border-bottom-left-radius: 3px;
	  text-align: center;
	  color: #fff;
	  font-size: 1.3rem;
	  border: 0;
	  padding: 5px 0;
	  background: rgba($primaryColor, .7);
	  transition: all 0.3s ease;
	  cursor: pointer;
	  svg {
	  	height: 15px;
	  	width: 15px;
	  }
	  &:hover {
		  background: rgba($primaryColor, .9);
	  }
	}
	.file-upload input.file-input {
	  position: absolute;
	  top: 0;
	  right: 0;
	  margin: 0;
	  padding: 0;
	  font-size: 20px;
	  cursor: pointer;
	  opacity: 0;
	  filter: alpha(opacity=0);
	  height: 100%;
	}
	.add-cv {
    position: relative;
    overflow: hidden;
    text-align: center;
    display: inline-block;
    color: $fontColor;
    font-size: 1.4rem;
    font-weight: 500;
    padding: 5px 15px;
    margin-right: 10px;
    background: $aliceBlue;
    transition: all 0.3s ease;
    cursor: pointer;
    border: 1px solid rgba($black, .08);
    border-radius: 3px;
    input {
	    position: absolute;
	    top: 0;
	    right: 0;
	    margin: 0;
	    padding: 0;
	    font-size: 20px;
	    cursor: pointer;
	    opacity: 0;
	    filter: alpha(opacity=0);
	    height: 100%;
    }
    svg {
    	height: 15px;
    	width: 15px;
    	margin-left: 7px;
    }
	}
}


@media all and (max-width: 991px) {
	.information-and-contact {
		margin-top: 60px;
	}
}

@media all and (max-width: 767px) {
	.candidate {
		.body {
			display: block;
			.button-area {
				margin-top: 20px;
			}
		}
	}
	.title-and-info {
		display: block;
		.download-resume {
			margin-top: 30px;
		}
	}
	.skill-and-profile {
		display: block;
		.social-profile {
			margin-top: 20px;
		}
	}
	.portfolio-slider {
		.owl-item.active .portfolio-item:before {
			height: 0;
			width: 0;
		}
	}
	.candidate-details {
		padding: 20px;
	}
	.contact-form-modal.show {
		display: block !important;
		.modal-dialog {
			padding: 50px 15px;
			margin: 0 auto;
		}
	}
	.contact-form-modal {
		.modal-body {
			form {
				.form-group {
					margin-bottom: 20px;
				}
			}
		}
	}
}

@media all and (max-width: 575px) {
	.candidate-filter-result.grid {
		display: block;
		&:before {
			height: 0;
			width: 0;
		}
	}
	.candidate.half-grid {
		width: 100%;
	}
}

@media all and (max-width: 439px) {
	.candidate {
		display: block;
		.thumb + .body {
			padding-left: 0;
			margin-top: 15px;
		}
	}
	.title-and-info {
		.title {
			display: block;
			.thumb + .title-body {
				padding-left: 0;
				margin-top: 20px;
			}
		}
	}
	.skill-and-profile {
		.skill {
			a {
				margin-bottom: 10px;
			}
		}
	}
	.information-and-contact {
		.buttons {
			display: block;
			.cover-download,
			.contact-download {
				width: 100%;
				svg {
					height: 15px;
				}
				&:hover {
					border-color: rgba($primaryColor, .2);
					color: $primaryColor;
				}
			}
		}
	}
}

