
/*---------------------------------------------
	18.0 Progress-bar Style
---------------------------------------------*/

$primaryColor: #246df8;
$fontColor: #6f7484;
$white: #ffffff;
$black: #000000;
$blackRussian: #101725;
$blackRussianLite: #232630;
$aliceBlue: #f7f9fc;
$aliceBlueLite: #f9fbfe;
$aliceBluedark: #515666;
$solitude: #f0f3f7;
$snow: #fbfbfb;


$headerFont: 'Poppins', sans-serif;
$bodyFont: 'Roboto', sans-serif;


.professonal-skill {
	.progress-group {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		.progress-item {
			width: calc(50% - 15px);
			margin-top: 10px;
			.progress-body {
				position: relative;
				.progress {
					border-radius: 0;
					background: #f0f5fb;
					height: 5px;
					.progress-bar {
						background: $primaryColor;
					}
				}
				.progress-to {
					position: absolute;
					top: -25px;
					right: 0;
				}
			}
		}
		.progress-item:nth-child(2n) {
			flex-break: before;
		}
	}
}

@media all and (max-width: 767px) {
	.professonal-skill {
		.progress-group {
			display: block;
			.progress-item {
				width: 100%;
				margin-top: 20px;
			}
		}
	}
}
