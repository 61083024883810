
/*---------------------------------------------
	04.0 Breadcrumb Style
---------------------------------------------*/
$primaryColor: #246df8;
$fontColor: #6f7484;
$white: #ffffff;
$black: #000000;
$blackRussian: #101725;
$blackRussianLite: #232630;
$aliceBlue: #f7f9fc;
$aliceBlueLite: #f9fbfe;
$aliceBluedark: #515666;
$solitude: #f0f3f7;
$snow: #fbfbfb;


$headerFont: 'Poppins', sans-serif;
$bodyFont: 'Roboto', sans-serif;

.breadcrumb-area {
	h1 {
		font-size: 2.6rem;
		font-weight: 500;
	}
	.breadcrumb {
		padding: 0;
		padding-top: 12px;
		margin: 0;
		background: transparent;
		li {
			color: $fontColor;
			a {
				color: #aaaaaa;
				&:hover {
					color: $primaryColor;
				}
			}
		}
	}
}

.breadcrumb-form {
	text-align: right;
	form {
		max-width: 350px;
		position: relative;
		margin-left: auto;
		input {
			width: 100%;
			border: 0;
			border-radius: 0;
			border-bottom: 2px solid rgba($primaryColor, .2);
			background: transparent;
			outline: none;
			height: 50px;
			padding: 0;
			transition: all .3s ease;
			&:focus {
				border-color: $primaryColor;
			}
		}
		input:focus + button {
			color: $primaryColor;
		}
		button {
			background: transparent;
			position: absolute;
			top: 10px;
			right: 0;
			border: 0;
			box-shadow: none;
			outline: none;
			color: rgba($primaryColor, .3);
			transition: all .3s ease;
			cursor: pointer;
			&:hover {
				color: $primaryColor;
			}
		}
	}
}

@media all and (max-width: 767px) {
	.breadcrumb-form {
		form {
			margin-right: auto;
			margin-left: 0;
			margin-top: 15px;
		}
	}
}