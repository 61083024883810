html {
  font-size: 62.5%;
}

body {
  padding: 0;
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 1.4rem;
  line-height: 1.85;
  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  line-height: 1.3;
  margin-top: 0;
  margin-bottom: 0;
  color: inherit;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
  color: inherit;
}

h1 {
  font-size: 6.4rem;
}

h2 {
  font-size: 4.8rem;
}

h3 {
  font-size: 3.6rem;
}

h4 {
  font-size: 2.8rem;
}

h5 {
  font-size: 2.2rem;
}

h6 {
  font-size: 1.8rem;
}

p {
  color: inherit;
  line-height: 1.85;
  font-size: 1.4rem;
}

p:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

a {
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

a,
a:hover,
a:focus {
  text-decoration: none;
  display: inline-block;
  color: inherit;
  outline: none;
}

.button {
  border: 1px solid transparent;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 3px;
  cursor: pointer;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.primary-color {
  color: #246df8;
}

.primary-bg {
  background: #023b92;
}

.white-text {
  color: #ffffff;
}

.white-bg {
  background: #ffffff;
}

.alice-bg {
  background: #f7f9fc;
}

.section-padding {
  padding: 120px 0;
}

.section-padding-top {
  padding-top: 120px;
}

.section-padding-bottom {
  padding-bottom: 120px;
}

.section-padding-150 {
  padding: 150px 0;
}

.padding-top-90 {
  padding-top: 90px;
}

.padding-bottom-90 {
  padding-bottom: 90px;
}

.padding-top-100 {
  padding-top: 100px;
}

.padding-bottom-100 {
  padding-bottom: 100px;
}

.padding-top-80 {
  padding-top: 80px;
}

.padding-bottom-70 {
  padding-bottom: 70px;
}

.padding-top-70 {
  padding-top: 70px;
}

.padding-top-60 {
  padding-top: 60px;
}

.padding-bottom-60 {
  padding-bottom: 60px;
}

.padding-bottom-40 {
  padding-bottom: 40px;
}

.section-padding-60 {
  padding: 60px 0;
}

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08) !important;
}

.border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.08) !important;
}

.margin-balance {
  margin-bottom: -30px;
  zoom: 1;
  position: relative;
  overflow: hidden;
}

.section-wrapper + .section-wrapper {
  margin-top: 50px;
}

.fix:before {
  display: block;
  content: '';
  clear: both;
}

/*------------------------------------------
  Responsive
-------------------------------------------*/
@media (max-width: 991px) {
  .right-content-md {
    margin-top: 60px;
  }
}

@media all and (max-width: 767px) {
  h1 {
    font-size: 4.8rem;
  }
  h2 {
    font-size: 3.6rem;
  }
  h3 {
    font-size: 3.2rem;
  }
  h4 {
    font-size: 2.4rem;
  }
  .right-content-sm {
    margin-top: 60px;
  }
}

/*---------------------------------------------
  02.0 Banner Style
---------------------------------------------*/
.banner-1-bg {
  background: url(../images/bg/banner-1-bg.jpg) no-repeat bottom left;
  background-size: cover;
}

.banner-1 .banner-content {
  padding: 250px 0 270px;
  color: #ffffff;
}

.banner-1 .banner-content h1 {
  font-family: "Poppins", sans-serif;
  font-size: 4.2rem;
  font-weight: 600;
  margin-bottom: 15px;
}

.banner-1 .banner-content p {
  color: #f5f8fe;
  font-size: 1.6rem;
  font-family: "Roboto", sans-serif;
  max-width: 500px;
  position: relative;
}

.banner-1 .banner-content p:before {
  position: absolute;
  content: url(../images/curv-arrow-2.png);
  top: 100%;
  left: 190px;
}

.banner-1 .banner-content .button {
  background: rgba(255, 255, 255, 0.15);
  padding: 14px 30px;
  margin-top: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
}

.banner-1 .banner-content .button:hover {
  background: #ffffff;
  color: #246df8;
}

.banner-2-bg {
  background: url(../images/bg/banner-2-bg.jpg) no-repeat bottom left;
  background-size: cover;
}

.banner-2 .banner-content {
  padding: 215px 0 170px;
  color: #232630;
}

.banner-2 .banner-content h1 {
  font-family: "Poppins", sans-serif;
  font-size: 4.2rem;
  font-weight: 600;
  margin-bottom: 25px;
}

.banner-2 .banner-content p {
  color: #6f7484;
  font-size: 1.6rem;
  font-family: "Roboto", sans-serif;
  max-width: 500px;
}

.banner-2 .banner-content .short-infos {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 350px;
  margin-top: 40px;
}

.banner-2 .banner-content .short-infos .info {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.banner-2 .banner-content .short-infos .info h4 {
  font-size: 2.8rem;
  color: #232630;
  font-weight: 600;
}

.banner-2 .banner-content .short-infos .info span {
  font-size: 1.6rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #6f7484;
}

.banner-3-bg {
  background: url(../images/bg/banner-3-bg.jpg) no-repeat center left;
  background-size: cover;
}

.banner-3 .banner-content {
  padding: 70px 0 190px;
}

.banner-3 .banner-content h1 {
  font-family: "Poppins", sans-serif;
  font-size: 4.2rem;
  font-weight: 600;
  margin-bottom: 15px;
  color: #232630;
}

.banner-3 .banner-content p {
  color: #6f7484;
  font-size: 1.6rem;
  font-family: "Roboto", sans-serif;
  max-width: 500px;
  position: relative;
}

.banner-3 .banner-content p:before {
  position: absolute;
  content: url(../images/curv-arrow.png);
  top: 100%;
  left: 190px;
}

.banner-3 .banner-content .button {
  background: #246df8;
  color: #ffffff;
  padding: 14px 30px;
  margin-top: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
}

.banner-3 .banner-content .button:hover {
  color: #ffffff;
  background: #246df8;
}

.banner-4-bg {
  background: url(../images/bg/banner-4-bg.jpg) no-repeat center;
  background-size: cover;
}

.banner-4 .banner-content {
  padding: 290px 0 210px;
  text-align: center;
  color: #ffffff;
}

.banner-4 .banner-content h1 {
  font-size: 4.2rem;
  margin-bottom: 20px;
}

.banner-4 .banner-content p {
  font-size: 1.6rem;
}

.banner-4 .banner-content .banner-search {
  background: rgba(255, 255, 255, 0.2);
  max-width: 970px;
  margin: 40px auto 0;
  padding: 30px;
  border-radius: 5px;
}

.banner-4 .banner-content .banner-search .search-form input {
  height: 55px;
  border: 0;
  border-bottom: 2px solid rgba(255, 255, 255, 0.15);
  background: transparent;
  padding: 0;
  color: #ffffff;
  outline: none;
  width: calc(50% - 110px);
  margin-right: 30px;
}

.banner-4 .banner-content .banner-search .search-form input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #ffffff;
}

.banner-4 .banner-content .banner-search .search-form input::-moz-placeholder {
  /* Firefox 19+ */
  color: #ffffff;
}

.banner-4 .banner-content .banner-search .search-form input:-ms-input-placeholder {
  /* IE 10+ */
  color: #ffffff;
}

.banner-4 .banner-content .banner-search .search-form input:-moz-placeholder {
  /* Firefox 18- */
  color: #ffffff;
}

.banner-4 .banner-content .banner-search .search-form .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  width: calc(50% - 110px);
  margin-right: 30px;
}

.banner-4 .banner-content .banner-search .search-form .btn {
  padding: 0;
  border: 0;
  border-bottom: 2px solid rgba(255, 255, 255, 0.15);
  height: 55px;
  background: transparent;
  border-radius: 0;
}

.banner-4 .banner-content .banner-search .search-form .bootstrap-select .dropdown-toggle .filter-option-inner-inner {
  line-height: 55px;
  font-size: 1.4rem;
  color: #ffffff;
}

.banner-4 .banner-content .banner-search .search-form .dropdown-toggle::after {
  border-top: 5px solid #ffffff;
  border-right: 5px solid transparent;
  border-bottom: 0;
  border-left: 5px solid transparent;
}

.banner-4 .banner-content .banner-search .search-form .bootstrap-select:focus {
  outline: none !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.banner-4 .banner-content .banner-search .search-form .btn:focus,
.banner-4 .banner-content .banner-search .search-form .btn-light:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.banner-4 .banner-content .banner-search .search-form .bootstrap-select .dropdown-toggle:active:focus {
  background: transparent;
  outline: none !important;
}

.banner-4 .banner-content .banner-search .search-form .bootstrap-select .dropdown-toggle:focus {
  outline: none !important;
  color: #ffffff;
}

.banner-4 .banner-content .banner-search .search-form .dropdown-menu {
  font-size: 1.4rem;
}

.banner-4 .banner-content .banner-search .search-form .button {
  width: 150px;
  color: #ffffff;
  height: 55px;
  border-radius: 5px;
}

.banner-4 .banner-content .banner-search .search-form .button i {
  margin-right: 10px;
}

.banner-4 .banner-content .banner-search .trending-key {
  font-size: 1.4rem;
  font-weight: 500;
  margin-top: 30px;
  text-align: left;
}

.banner-4 .banner-content .banner-search .trending-key span {
  margin-right: 10px;
}

.banner-4 .banner-content .banner-search .trending-key a {
  font-style: italic;
  margin-right: 10px;
}

.banner-4 .banner-content .banner-search .trending-key a:hover {
  text-decoration: underline;
}

@media all and (max-width: 991px) {
  .banner-2 .banner-content {
    padding: 150px 0 170px;
  }
}

@media all and (max-width: 767px) {
  .banner-1 .banner-content h1,
  .banner-2 .banner-content h1,
  .banner-3 .banner-content h1,
  .banner-4 .banner-content h1 {
    font-size: 3.4rem;
  }
  .banner-4 .banner-content {
    padding: 200px 0 150px;
  }
  .banner-4 .banner-content .banner-search .search-form input,
  .banner-4 .banner-content .banner-search .search-form .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
}

/*---------------------------------------------
  03.0 Search Style
---------------------------------------------*/
.searchAndFilter-wrapper {
  background: #f7f9fc;
}

.searchAndFilter {
  padding: 30px 30px 35px;
  border: 1px solid rgba(0, 0, 0, 0.03);
  background: #ffffff;
  position: relative;
  -webkit-transform: translateY(-120px);
  -ms-transform: translateY(-120px);
  transform: translateY(-120px);
  border-radius: 3px;
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.05);
}

.searchAndFilter input {
  height: 60px;
  border: 0;
  border-bottom: 2px solid rgba(0, 0, 0, 0.08);
  background: transparent;
  outline: none;
  width: calc(33% - 78px);
  margin-right: 25px;
  font-size: 1.5rem;
  color: #6f7484;
}

.searchAndFilter input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(111, 116, 132, 0.8);
}

.searchAndFilter input::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(111, 116, 132, 0.8);
}

.searchAndFilter input:-ms-input-placeholder {
  /* IE 10+ */
  color: rgba(111, 116, 132, 0.8);
}

.searchAndFilter input:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(111, 116, 132, 0.8);
}

.searchAndFilter input:focus {
  border-bottom-color: #246df8;
}

.searchAndFilter select option {
  color: #6f7484;
}

.searchAndFilter .button {
  border: 0;
  padding: 17px 10px;
  width: 155px;
  color: #ffffff;
  outline: none;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1.4rem;
}

.searchAndFilter .button i {
  margin-right: 10px;
}

.searchAndFilter .button:hover {
  -webkit-box-shadow: 0px 5px 15px 0px rgba(36, 109, 248, 0.3);
  box-shadow: 0px 5px 15px 0px rgba(36, 109, 248, 0.3);
}

.searchAndFilter.searchAndFilter-2 {
  background: #023b92;
  -webkit-transform: translateY(-60px);
  -ms-transform: translateY(-60px);
  transform: translateY(-60px);
  z-index: 9;
}

.searchAndFilter.searchAndFilter-2 input {
  color: #ffffff;
  border-bottom: 2px solid rgba(255, 255, 255, 0.4);
}

.searchAndFilter.searchAndFilter-2 input::-webkit-input-placeholder {
  color: #ffffff;
}

.searchAndFilter.searchAndFilter-2 input::-moz-placeholder {
  color: #ffffff;
}

.searchAndFilter.searchAndFilter-2 input:-ms-input-placeholder {
  color: #ffffff;
}

.searchAndFilter.searchAndFilter-2 input:-moz-placeholder {
  color: #ffffff;
}

.searchAndFilter.searchAndFilter-2 input:focus {
  border-bottom: 2px solid rgba(255, 255, 255, 0.8);
}

.searchAndFilter.searchAndFilter-2 .button {
  background: #ffffff;
  color: #232630;
}

.searchAndFilter.searchAndFilter-2 .bootstrap-select .dropdown-toggle:after {
  color: #ffffff;
}

.searchAndFilter .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  border-bottom: 2px solid rgba(0, 0, 0, 0.08);
  width: calc(33% - 78px);
}

.searchAndFilter .bootstrap-select.show:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  border-bottom-color: #246df8;
}

.searchAndFilter.searchAndFilter-2 .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  border-bottom: 2px solid rgba(255, 255, 255, 0.4);
}

.searchAndFilter.searchAndFilter-2 .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn):focus {
  border-bottom: 2px solid rgba(255, 255, 255, 0.8);
}

.searchAndFilter .bootstrap-select {
  margin-right: 25px;
}

.searchAndFilter .bootstrap-select .dropdown-toggle .filter-option-inner {
  padding-top: 2px;
}

.searchAndFilter .bootstrap-select .dropdown-toggle:after {
  border-top: 0 solid;
  border-right: 0 solid transparent;
  border-bottom: 0;
  border-left: 0 solid transparent;
  position: absolute;
  top: 6px;
  right: 20px;
  content: '\f107';
  font-family: Font Awesome\ 5 Free;
  font-weight: 900;
  color: #aaa;
  font-size: 1.4rem;
}

.searchAndFilter .bootstrap-select .dropdown-toggle:after {
  right: 10px;
}

.searchAndFilter .bootstrap-select .dropdown-toggle .filter-option {
  padding: 0 15px;
}

.searchAndFilter .bootstrap-select .dropdown-toggle .filter-option {
  padding: 0;
}

.searchAndFilter .bootstrap-select:focus {
  outline: none !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.searchAndFilter .btn:focus,
.searchAndFilter .btn-light:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.searchAndFilter .btn {
  padding: 0;
}

.searchAndFilter .bootstrap-select .dropdown-toggle {
  height: 56px;
  background: transparent;
  font-size: 1.5rem;
  border-radius: 0;
  color: #6f7484;
  line-height: 51px;
}

.searchAndFilter .bootstrap-select .dropdown-toggle:active:focus {
  background: transparent;
  outline: none !important;
}

.searchAndFilter .bootstrap-select .dropdown-toggle:focus {
  outline: none !important;
  color: #6f7484;
}

.searchAndFilter.searchAndFilter-2 .bootstrap-select .dropdown-toggle {
  color: #ffffff;
}

.searchAndFilter.searchAndFilter-2 .bootstrap-select .dropdown-toggle:focus {
  color: #ffffff;
}

.searchAndFilter .btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active, .show > .btn-light.dropdown-toggle {
  border-color: transparent;
}

.searchAndFilter .btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active, .show > .btn-light.dropdown-toggle {
  color: #6f7484;
  background: transparent;
}

.searchAndFilter .bootstrap-select .dropdown-menu .dropdown-item {
  color: #6f7484;
}

.searchAndFilter .bootstrap-select .dropdown-menu .dropdown-item.selected {
  color: #ffffff;
}

.searchAndFilter-3 .bootstrap-select .dropdown-menu .dropdown-item {
  color: #6f7484;
}

.searchAndFilter-3 .bootstrap-select .dropdown-menu .dropdown-item.selected {
  color: #ffffff;
}

.searchAndFilter.searchAndFilter-2 .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  border-bottom: 2px solid rgba(255, 255, 255, 0.4);
}

.searchAndFilter.searchAndFilter-2 .bootstrap-select.show:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  border-bottom-color: #ffffff;
}

.searchAndFilter.bootstrap-select > .dropdown-toggle.bs-placeholder:hover {
  color: #6f7484;
}

.searchAndFilter.searchAndFilter-2 .bootstrap-select.show {
  border-bottom-color: #ffffff;
}

.searchAndFilter .bootstrap-select .btn-light,
.searchAndFilter.searchAndFilter-2 .bootstrap-select .btn-light {
  border: 0;
}

.searchAndFilter .dropdown-menu {
  font-size: 1.4rem;
}

.searchAndFilter-3 {
  background: #ffffff;
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.05);
  position: relative;
  -webkit-transform: translateY(-120px);
  -ms-transform: translateY(-120px);
  transform: translateY(-120px);
}

.searchAndFilter-3 .search-form {
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.searchAndFilter-3 .search-form label {
  display: block;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
  text-transform: uppercase;
  margin-bottom: 10px;
  line-height: 1;
}

.searchAndFilter-3 .search-form input {
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  width: 100%;
}

.searchAndFilter-3 .search-form .keyword {
  max-width: 300px;
  width: 100%;
  border-right: 1px solid rgba(0, 0, 0, 0.08);
}

.searchAndFilter-3 .search-form .location-input,
.searchAndFilter-3 .search-form .category-input {
  width: 30%;
  padding: 0 30px;
}

.searchAndFilter-3 .search-form .location-input {
  border-right: 1px solid rgba(0, 0, 0, 0.08);
}

.searchAndFilter-3 .search-form .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  width: 100%;
}

.searchAndFilter-3 .search-form .btn-light {
  background: transparent;
}

.searchAndFilter-3 .search-form .btn-light:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.searchAndFilter-3 .search-form .btn-light:not(:disabled):not(.disabled):active {
  border-bottom: 0;
}

.searchAndFilter-3 .search-form .bootstrap-select .dropdown-toggle .filter-option {
  padding: 0;
}

.searchAndFilter-3 .search-form .btn {
  border: 0;
  padding: 0;
  font-size: 1.4rem;
}

.searchAndFilter-3 .search-form .bootstrap-select:focus {
  outline: none !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.searchAndFilter-3 .search-form .bootstrap-select .dropdown-toggle:active:focus {
  background: transparent;
  outline: none !important;
}

.searchAndFilter-3 .search-form .bootstrap-select .dropdown-toggle:focus {
  outline: none !important;
  color: #6f7484;
}

.searchAndFilter-3 .search-form .bootstrap-select .dropdown-menu {
  font-size: 1.4rem;
}

.searchAndFilter-3 .search-form .button {
  padding: 0 30px;
  color: #ffffff;
  margin-left: auto;
}

.searchAndFilter-3 .filter-categories {
  padding: 55px;
}

.searchAndFilter-3 .filter-categories h4 {
  position: relative;
}

.searchAndFilter-3 .filter-categories h4 svg {
  position: absolute;
  top: 0;
  left: -30px;
  height: 20px;
  color: #aaa;
}

.searchAndFilter-3 .filter-categories ul li {
  padding: 8px 0;
}

.searchAndFilter-3 .filter-location {
  padding: 55px;
  padding-top: 0;
}

.searchAndFilter-3 .filter-location h4 {
  position: relative;
  font-size: 1.4rem;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #515666;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.searchAndFilter-3 .filter-location h4 svg {
  position: absolute;
  top: 0;
  left: -30px;
  height: 20px;
  color: #aaa;
}

.searchAndFilter-3 .filter-location ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.searchAndFilter-3 .filter-location ul:after {
  display: block;
  clear: both;
  content: '';
}

.searchAndFilter-3 .filter-location ul li {
  padding: 8px 0;
  float: left;
  width: 33%;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
  position: relative;
  color: #6f7484;
}

.searchAndFilter-3 .filter-location ul li a span {
  color: #aaa;
  font-family: "Roboto", sans-serif;
}

.searchAndFilter-3 .filter-location ul li a:hover {
  color: #246df8;
}

.searchAndFilter-3 .filter-location ul li a:hover span {
  color: #246df8;
}

/*---------------------------------------------
  Category Style
---------------------------------------------*/
.filter-categories {
  padding-top: 45px;
}

.filter-categories h4 {
  font-size: 1.4rem;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #515666;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.filter-categories ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.filter-categories ul:after {
  display: block;
  content: '';
  clear: both;
}

.filter-categories ul li {
  float: left;
  width: 33%;
  padding: 8px 0;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
  position: relative;
  padding-left: 30px;
  color: #6f7484;
}

.filter-categories ul li a svg {
  color: rgba(36, 109, 248, 0.3);
  margin-right: 10px;
  height: 18px;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  position: absolute;
  top: 11px;
  left: 0;
}

.filter-categories ul li a span {
  font-family: "Roboto", sans-serif;
  color: #aaa;
}

.filter-categories ul li a:hover {
  color: #246df8;
}

.filter-categories ul li a:hover span {
  color: #246df8;
}

.filter-categories ul li a:hover svg {
  color: #246df8;
}

.job-filter-wrapper, .candidate-filter-wrapper, .employer-filter-wrapper {
  background-image: -webkit-gradient(linear, left top, right top, from(#ffffff), to(#f7f9fc));
  background-image: -webkit-linear-gradient(left, #ffffff, #f7f9fc);
  background-image: -o-linear-gradient(left, #ffffff, #f7f9fc);
  background-image: linear-gradient(to right, #ffffff, #f7f9fc);
}

.job-filter-wrapper .job-filter, .candidate-filter-wrapper .job-filter, .employer-filter-wrapper .job-filter, .job-filter-wrapper .candidate-filter, .candidate-filter-wrapper .candidate-filter, .employer-filter-wrapper .candidate-filter, .job-filter-wrapper .employer-filter, .candidate-filter-wrapper .employer-filter, .employer-filter-wrapper .employer-filter {
  padding: 30px 30px 30px 50px;
  position: relative;
}

.job-filter-wrapper .job-filter.same-pad, .candidate-filter-wrapper .job-filter.same-pad, .employer-filter-wrapper .job-filter.same-pad, .job-filter-wrapper .same-pad.candidate-filter, .candidate-filter-wrapper .same-pad.candidate-filter, .employer-filter-wrapper .same-pad.candidate-filter, .job-filter-wrapper .same-pad.employer-filter, .candidate-filter-wrapper .same-pad.employer-filter, .employer-filter-wrapper .same-pad.employer-filter {
  padding-left: 30px;
}

.job-filter-wrapper .job-filter:before, .candidate-filter-wrapper .job-filter:before, .employer-filter-wrapper .job-filter:before, .job-filter-wrapper .candidate-filter:before, .candidate-filter-wrapper .candidate-filter:before, .employer-filter-wrapper .candidate-filter:before, .job-filter-wrapper .employer-filter:before, .candidate-filter-wrapper .employer-filter:before, .employer-filter-wrapper .employer-filter:before {
  position: absolute;
  bottom: 0;
  left: 30px;
  right: 0;
  height: 1px;
  background: rgba(0, 0, 0, 0.08);
  content: '';
}

.job-filter-wrapper .job-filter .option-title, .candidate-filter-wrapper .job-filter .option-title, .employer-filter-wrapper .job-filter .option-title, .job-filter-wrapper .candidate-filter .option-title, .candidate-filter-wrapper .candidate-filter .option-title, .employer-filter-wrapper .candidate-filter .option-title, .job-filter-wrapper .employer-filter .option-title, .candidate-filter-wrapper .employer-filter .option-title, .employer-filter-wrapper .employer-filter .option-title {
  font-size: 1.5rem;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  position: relative;
  cursor: pointer;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.job-filter-wrapper .job-filter .option-title:before, .candidate-filter-wrapper .job-filter .option-title:before, .employer-filter-wrapper .job-filter .option-title:before, .job-filter-wrapper .candidate-filter .option-title:before, .candidate-filter-wrapper .candidate-filter .option-title:before, .employer-filter-wrapper .candidate-filter .option-title:before, .job-filter-wrapper .employer-filter .option-title:before, .candidate-filter-wrapper .employer-filter .option-title:before, .employer-filter-wrapper .employer-filter .option-title:before {
  position: absolute;
  content: "\e622";
  font-family: 'themify';
  top: 0;
  right: 0;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.job-filter-wrapper .job-filter .option-title.compress:hover, .candidate-filter-wrapper .job-filter .option-title.compress:hover, .employer-filter-wrapper .job-filter .option-title.compress:hover, .job-filter-wrapper .candidate-filter .option-title.compress:hover, .candidate-filter-wrapper .candidate-filter .option-title.compress:hover, .employer-filter-wrapper .candidate-filter .option-title.compress:hover, .job-filter-wrapper .employer-filter .option-title.compress:hover, .candidate-filter-wrapper .employer-filter .option-title.compress:hover, .employer-filter-wrapper .employer-filter .option-title.compress:hover {
  color: #246df8;
}

.job-filter-wrapper .job-filter .option-title.compress:before, .candidate-filter-wrapper .job-filter .option-title.compress:before, .employer-filter-wrapper .job-filter .option-title.compress:before, .job-filter-wrapper .candidate-filter .option-title.compress:before, .candidate-filter-wrapper .candidate-filter .option-title.compress:before, .employer-filter-wrapper .candidate-filter .option-title.compress:before, .job-filter-wrapper .employer-filter .option-title.compress:before, .candidate-filter-wrapper .employer-filter .option-title.compress:before, .employer-filter-wrapper .employer-filter .option-title.compress:before {
  content: "\e61a";
}

.job-filter-wrapper .job-filter ul, .candidate-filter-wrapper .job-filter ul, .employer-filter-wrapper .job-filter ul, .job-filter-wrapper .candidate-filter ul, .candidate-filter-wrapper .candidate-filter ul, .employer-filter-wrapper .candidate-filter ul, .job-filter-wrapper .employer-filter ul, .candidate-filter-wrapper .employer-filter ul, .employer-filter-wrapper .employer-filter ul {
  padding: 20px 0 0;
  margin: 0;
  list-style: none;
}

.job-filter-wrapper .job-filter ul li, .candidate-filter-wrapper .job-filter ul li, .employer-filter-wrapper .job-filter ul li, .job-filter-wrapper .candidate-filter ul li, .candidate-filter-wrapper .candidate-filter ul li, .employer-filter-wrapper .candidate-filter ul li, .job-filter-wrapper .employer-filter ul li, .candidate-filter-wrapper .employer-filter ul li, .employer-filter-wrapper .employer-filter ul li {
  position: relative;
  padding-left: 20px;
}

.job-filter-wrapper .job-filter ul li + li, .candidate-filter-wrapper .job-filter ul li + li, .employer-filter-wrapper .job-filter ul li + li, .job-filter-wrapper .candidate-filter ul li + li, .candidate-filter-wrapper .candidate-filter ul li + li, .employer-filter-wrapper .candidate-filter ul li + li, .job-filter-wrapper .employer-filter ul li + li, .candidate-filter-wrapper .employer-filter ul li + li, .employer-filter-wrapper .employer-filter ul li + li {
  margin-top: 7px;
}

.job-filter-wrapper .job-filter ul li a, .candidate-filter-wrapper .job-filter ul li a, .employer-filter-wrapper .job-filter ul li a, .job-filter-wrapper .candidate-filter ul li a, .candidate-filter-wrapper .candidate-filter ul li a, .employer-filter-wrapper .candidate-filter ul li a, .job-filter-wrapper .employer-filter ul li a, .candidate-filter-wrapper .employer-filter ul li a, .employer-filter-wrapper .employer-filter ul li a {
  color: #6f7484;
  font-size: 1.4rem;
}

.job-filter-wrapper .job-filter ul li a:hover, .candidate-filter-wrapper .job-filter ul li a:hover, .employer-filter-wrapper .job-filter ul li a:hover, .job-filter-wrapper .candidate-filter ul li a:hover, .candidate-filter-wrapper .candidate-filter ul li a:hover, .employer-filter-wrapper .candidate-filter ul li a:hover, .job-filter-wrapper .employer-filter ul li a:hover, .candidate-filter-wrapper .employer-filter ul li a:hover, .employer-filter-wrapper .employer-filter ul li a:hover {
  color: #246df8;
}

.job-filter-wrapper .job-filter ul li:before, .candidate-filter-wrapper .job-filter ul li:before, .employer-filter-wrapper .job-filter ul li:before, .job-filter-wrapper .candidate-filter ul li:before, .candidate-filter-wrapper .candidate-filter ul li:before, .employer-filter-wrapper .candidate-filter ul li:before, .job-filter-wrapper .employer-filter ul li:before, .candidate-filter-wrapper .employer-filter ul li:before, .employer-filter-wrapper .employer-filter ul li:before {
  position: absolute;
  content: "\e622";
  font-family: 'themify';
  top: 0;
  left: 0;
}

.job-filter-wrapper .job-filter:last-child:after, .candidate-filter-wrapper .job-filter:last-child:after, .employer-filter-wrapper .job-filter:last-child:after, .job-filter-wrapper .candidate-filter:last-child:after, .candidate-filter-wrapper .candidate-filter:last-child:after, .employer-filter-wrapper .candidate-filter:last-child:after, .job-filter-wrapper .employer-filter:last-child:after, .candidate-filter-wrapper .employer-filter:last-child:after, .employer-filter-wrapper .employer-filter:last-child:after {
  position: absolute;
  bottom: 0;
  left: 30px;
  right: 0;
  height: 1px;
  background: rgba(0, 0, 0, 0.08);
  content: '';
}

.job-filter-wrapper .job-filter.job-type ul li, .candidate-filter-wrapper .job-filter.job-type ul li, .employer-filter-wrapper .job-filter.job-type ul li, .job-filter-wrapper .job-type.candidate-filter ul li, .candidate-filter-wrapper .job-type.candidate-filter ul li, .employer-filter-wrapper .job-type.candidate-filter ul li, .job-filter-wrapper .candidate-filter.candidate-type ul li, .candidate-filter-wrapper .candidate-filter.candidate-type ul li, .employer-filter-wrapper .candidate-filter.candidate-type ul li, .job-filter-wrapper .candidate-filter.employer-type ul li, .candidate-filter-wrapper .candidate-filter.employer-type ul li, .employer-filter-wrapper .candidate-filter.employer-type ul li, .job-filter-wrapper .job-filter.candidate-type ul li, .candidate-filter-wrapper .job-filter.candidate-type ul li, .employer-filter-wrapper .job-filter.candidate-type ul li, .job-filter-wrapper .candidate-type.employer-filter ul li, .candidate-filter-wrapper .candidate-type.employer-filter ul li, .employer-filter-wrapper .candidate-type.employer-filter ul li, .job-filter-wrapper .job-type.employer-filter ul li, .candidate-filter-wrapper .job-type.employer-filter ul li, .employer-filter-wrapper .job-type.employer-filter ul li, .job-filter-wrapper .employer-filter.employer-type ul li, .candidate-filter-wrapper .employer-filter.employer-type ul li, .employer-filter-wrapper .employer-filter.employer-type ul li, .job-filter-wrapper .job-filter.employer-type ul li, .candidate-filter-wrapper .job-filter.employer-type ul li, .employer-filter-wrapper .job-filter.employer-type ul li {
  padding-left: 0;
}

.job-filter-wrapper .job-filter.job-type ul li:before, .candidate-filter-wrapper .job-filter.job-type ul li:before, .employer-filter-wrapper .job-filter.job-type ul li:before, .job-filter-wrapper .job-type.candidate-filter ul li:before, .candidate-filter-wrapper .job-type.candidate-filter ul li:before, .employer-filter-wrapper .job-type.candidate-filter ul li:before, .job-filter-wrapper .candidate-filter.candidate-type ul li:before, .candidate-filter-wrapper .candidate-filter.candidate-type ul li:before, .employer-filter-wrapper .candidate-filter.candidate-type ul li:before, .job-filter-wrapper .candidate-filter.employer-type ul li:before, .candidate-filter-wrapper .candidate-filter.employer-type ul li:before, .employer-filter-wrapper .candidate-filter.employer-type ul li:before, .job-filter-wrapper .job-filter.candidate-type ul li:before, .candidate-filter-wrapper .job-filter.candidate-type ul li:before, .employer-filter-wrapper .job-filter.candidate-type ul li:before, .job-filter-wrapper .candidate-type.employer-filter ul li:before, .candidate-filter-wrapper .candidate-type.employer-filter ul li:before, .employer-filter-wrapper .candidate-type.employer-filter ul li:before, .job-filter-wrapper .job-type.employer-filter ul li:before, .candidate-filter-wrapper .job-type.employer-filter ul li:before, .employer-filter-wrapper .job-type.employer-filter ul li:before, .job-filter-wrapper .employer-filter.employer-type ul li:before, .candidate-filter-wrapper .employer-filter.employer-type ul li:before, .employer-filter-wrapper .employer-filter.employer-type ul li:before, .job-filter-wrapper .job-filter.employer-type ul li:before, .candidate-filter-wrapper .job-filter.employer-type ul li:before, .employer-filter-wrapper .job-filter.employer-type ul li:before {
  content: '';
}

.job-filter-wrapper .job-filter.job-type ul li svg, .candidate-filter-wrapper .job-filter.job-type ul li svg, .employer-filter-wrapper .job-filter.job-type ul li svg, .job-filter-wrapper .job-type.candidate-filter ul li svg, .candidate-filter-wrapper .job-type.candidate-filter ul li svg, .employer-filter-wrapper .job-type.candidate-filter ul li svg, .job-filter-wrapper .candidate-filter.candidate-type ul li svg, .candidate-filter-wrapper .candidate-filter.candidate-type ul li svg, .employer-filter-wrapper .candidate-filter.candidate-type ul li svg, .job-filter-wrapper .candidate-filter.employer-type ul li svg, .candidate-filter-wrapper .candidate-filter.employer-type ul li svg, .employer-filter-wrapper .candidate-filter.employer-type ul li svg, .job-filter-wrapper .job-filter.candidate-type ul li svg, .candidate-filter-wrapper .job-filter.candidate-type ul li svg, .employer-filter-wrapper .job-filter.candidate-type ul li svg, .job-filter-wrapper .candidate-type.employer-filter ul li svg, .candidate-filter-wrapper .candidate-type.employer-filter ul li svg, .employer-filter-wrapper .candidate-type.employer-filter ul li svg, .job-filter-wrapper .job-type.employer-filter ul li svg, .candidate-filter-wrapper .job-type.employer-filter ul li svg, .employer-filter-wrapper .job-type.employer-filter ul li svg, .job-filter-wrapper .employer-filter.employer-type ul li svg, .candidate-filter-wrapper .employer-filter.employer-type ul li svg, .employer-filter-wrapper .employer-filter.employer-type ul li svg, .job-filter-wrapper .job-filter.employer-type ul li svg, .candidate-filter-wrapper .job-filter.employer-type ul li svg, .employer-filter-wrapper .job-filter.employer-type ul li svg {
  height: 14px;
  width: 14px;
  margin-right: 7px;
}

.job-filter-wrapper .job-filter.job-type ul .full-time svg, .candidate-filter-wrapper .job-filter.job-type ul .full-time svg, .employer-filter-wrapper .job-filter.job-type ul .full-time svg, .job-filter-wrapper .job-type.candidate-filter ul .full-time svg, .candidate-filter-wrapper .job-type.candidate-filter ul .full-time svg, .employer-filter-wrapper .job-type.candidate-filter ul .full-time svg, .job-filter-wrapper .candidate-filter.candidate-type ul .full-time svg, .candidate-filter-wrapper .candidate-filter.candidate-type ul .full-time svg, .employer-filter-wrapper .candidate-filter.candidate-type ul .full-time svg, .job-filter-wrapper .candidate-filter.employer-type ul .full-time svg, .candidate-filter-wrapper .candidate-filter.employer-type ul .full-time svg, .employer-filter-wrapper .candidate-filter.employer-type ul .full-time svg, .job-filter-wrapper .job-filter.candidate-type ul .full-time svg, .candidate-filter-wrapper .job-filter.candidate-type ul .full-time svg, .employer-filter-wrapper .job-filter.candidate-type ul .full-time svg, .job-filter-wrapper .candidate-type.employer-filter ul .full-time svg, .candidate-filter-wrapper .candidate-type.employer-filter ul .full-time svg, .employer-filter-wrapper .candidate-type.employer-filter ul .full-time svg, .job-filter-wrapper .job-type.employer-filter ul .full-time svg, .candidate-filter-wrapper .job-type.employer-filter ul .full-time svg, .employer-filter-wrapper .job-type.employer-filter ul .full-time svg, .job-filter-wrapper .employer-filter.employer-type ul .full-time svg, .candidate-filter-wrapper .employer-filter.employer-type ul .full-time svg, .employer-filter-wrapper .employer-filter.employer-type ul .full-time svg, .job-filter-wrapper .job-filter.employer-type ul .full-time svg, .candidate-filter-wrapper .job-filter.employer-type ul .full-time svg, .employer-filter-wrapper .job-filter.employer-type ul .full-time svg {
  color: #ff9247;
}

.job-filter-wrapper .job-filter.job-type ul .part-time svg, .candidate-filter-wrapper .job-filter.job-type ul .part-time svg, .employer-filter-wrapper .job-filter.job-type ul .part-time svg, .job-filter-wrapper .job-type.candidate-filter ul .part-time svg, .candidate-filter-wrapper .job-type.candidate-filter ul .part-time svg, .employer-filter-wrapper .job-type.candidate-filter ul .part-time svg, .job-filter-wrapper .candidate-filter.candidate-type ul .part-time svg, .candidate-filter-wrapper .candidate-filter.candidate-type ul .part-time svg, .employer-filter-wrapper .candidate-filter.candidate-type ul .part-time svg, .job-filter-wrapper .candidate-filter.employer-type ul .part-time svg, .candidate-filter-wrapper .candidate-filter.employer-type ul .part-time svg, .employer-filter-wrapper .candidate-filter.employer-type ul .part-time svg, .job-filter-wrapper .job-filter.candidate-type ul .part-time svg, .candidate-filter-wrapper .job-filter.candidate-type ul .part-time svg, .employer-filter-wrapper .job-filter.candidate-type ul .part-time svg, .job-filter-wrapper .candidate-type.employer-filter ul .part-time svg, .candidate-filter-wrapper .candidate-type.employer-filter ul .part-time svg, .employer-filter-wrapper .candidate-type.employer-filter ul .part-time svg, .job-filter-wrapper .job-type.employer-filter ul .part-time svg, .candidate-filter-wrapper .job-type.employer-filter ul .part-time svg, .employer-filter-wrapper .job-type.employer-filter ul .part-time svg, .job-filter-wrapper .employer-filter.employer-type ul .part-time svg, .candidate-filter-wrapper .employer-filter.employer-type ul .part-time svg, .employer-filter-wrapper .employer-filter.employer-type ul .part-time svg, .job-filter-wrapper .job-filter.employer-type ul .part-time svg, .candidate-filter-wrapper .job-filter.employer-type ul .part-time svg, .employer-filter-wrapper .job-filter.employer-type ul .part-time svg {
  color: #6e75d0;
}

.job-filter-wrapper .job-filter.job-type ul .freelance svg, .candidate-filter-wrapper .job-filter.job-type ul .freelance svg, .employer-filter-wrapper .job-filter.job-type ul .freelance svg, .job-filter-wrapper .job-type.candidate-filter ul .freelance svg, .candidate-filter-wrapper .job-type.candidate-filter ul .freelance svg, .employer-filter-wrapper .job-type.candidate-filter ul .freelance svg, .job-filter-wrapper .candidate-filter.candidate-type ul .freelance svg, .candidate-filter-wrapper .candidate-filter.candidate-type ul .freelance svg, .employer-filter-wrapper .candidate-filter.candidate-type ul .freelance svg, .job-filter-wrapper .candidate-filter.employer-type ul .freelance svg, .candidate-filter-wrapper .candidate-filter.employer-type ul .freelance svg, .employer-filter-wrapper .candidate-filter.employer-type ul .freelance svg, .job-filter-wrapper .job-filter.candidate-type ul .freelance svg, .candidate-filter-wrapper .job-filter.candidate-type ul .freelance svg, .employer-filter-wrapper .job-filter.candidate-type ul .freelance svg, .job-filter-wrapper .candidate-type.employer-filter ul .freelance svg, .candidate-filter-wrapper .candidate-type.employer-filter ul .freelance svg, .employer-filter-wrapper .candidate-type.employer-filter ul .freelance svg, .job-filter-wrapper .job-type.employer-filter ul .freelance svg, .candidate-filter-wrapper .job-type.employer-filter ul .freelance svg, .employer-filter-wrapper .job-type.employer-filter ul .freelance svg, .job-filter-wrapper .employer-filter.employer-type ul .freelance svg, .candidate-filter-wrapper .employer-filter.employer-type ul .freelance svg, .employer-filter-wrapper .employer-filter.employer-type ul .freelance svg, .job-filter-wrapper .job-filter.employer-type ul .freelance svg, .candidate-filter-wrapper .job-filter.employer-type ul .freelance svg, .employer-filter-wrapper .job-filter.employer-type ul .freelance svg {
  color: #82d219;
}

.job-filter-wrapper .job-filter.job-type ul .temporary svg, .candidate-filter-wrapper .job-filter.job-type ul .temporary svg, .employer-filter-wrapper .job-filter.job-type ul .temporary svg, .job-filter-wrapper .job-type.candidate-filter ul .temporary svg, .candidate-filter-wrapper .job-type.candidate-filter ul .temporary svg, .employer-filter-wrapper .job-type.candidate-filter ul .temporary svg, .job-filter-wrapper .candidate-filter.candidate-type ul .temporary svg, .candidate-filter-wrapper .candidate-filter.candidate-type ul .temporary svg, .employer-filter-wrapper .candidate-filter.candidate-type ul .temporary svg, .job-filter-wrapper .candidate-filter.employer-type ul .temporary svg, .candidate-filter-wrapper .candidate-filter.employer-type ul .temporary svg, .employer-filter-wrapper .candidate-filter.employer-type ul .temporary svg, .job-filter-wrapper .job-filter.candidate-type ul .temporary svg, .candidate-filter-wrapper .job-filter.candidate-type ul .temporary svg, .employer-filter-wrapper .job-filter.candidate-type ul .temporary svg, .job-filter-wrapper .candidate-type.employer-filter ul .temporary svg, .candidate-filter-wrapper .candidate-type.employer-filter ul .temporary svg, .employer-filter-wrapper .candidate-type.employer-filter ul .temporary svg, .job-filter-wrapper .job-type.employer-filter ul .temporary svg, .candidate-filter-wrapper .job-type.employer-filter ul .temporary svg, .employer-filter-wrapper .job-type.employer-filter ul .temporary svg, .job-filter-wrapper .employer-filter.employer-type ul .temporary svg, .candidate-filter-wrapper .employer-filter.employer-type ul .temporary svg, .employer-filter-wrapper .employer-filter.employer-type ul .temporary svg, .job-filter-wrapper .job-filter.employer-type ul .temporary svg, .candidate-filter-wrapper .job-filter.employer-type ul .temporary svg, .employer-filter-wrapper .job-filter.employer-type ul .temporary svg {
  color: #ff5377;
}

.job-filter-wrapper .job-filter .nstSlider, .candidate-filter-wrapper .job-filter .nstSlider, .employer-filter-wrapper .job-filter .nstSlider, .job-filter-wrapper .candidate-filter .nstSlider, .candidate-filter-wrapper .candidate-filter .nstSlider, .employer-filter-wrapper .candidate-filter .nstSlider, .job-filter-wrapper .employer-filter .nstSlider, .candidate-filter-wrapper .employer-filter .nstSlider, .employer-filter-wrapper .employer-filter .nstSlider {
  width: 100%;
  height: 3px;
  margin: 20px 0 37px;
  background: #e3e3e3;
  cursor: auto;
}

.job-filter-wrapper .job-filter .nstSlider .bar, .candidate-filter-wrapper .job-filter .nstSlider .bar, .employer-filter-wrapper .job-filter .nstSlider .bar, .job-filter-wrapper .candidate-filter .nstSlider .bar, .candidate-filter-wrapper .candidate-filter .nstSlider .bar, .employer-filter-wrapper .candidate-filter .nstSlider .bar, .job-filter-wrapper .employer-filter .nstSlider .bar, .candidate-filter-wrapper .employer-filter .nstSlider .bar, .employer-filter-wrapper .employer-filter .nstSlider .bar {
  height: 3px;
  top: 0;
  min-width: 0;
  background: #246df8;
}

.job-filter-wrapper .job-filter .nstSlider .leftGrip, .candidate-filter-wrapper .job-filter .nstSlider .leftGrip, .employer-filter-wrapper .job-filter .nstSlider .leftGrip, .job-filter-wrapper .candidate-filter .nstSlider .leftGrip, .candidate-filter-wrapper .candidate-filter .nstSlider .leftGrip, .employer-filter-wrapper .candidate-filter .nstSlider .leftGrip, .job-filter-wrapper .employer-filter .nstSlider .leftGrip, .candidate-filter-wrapper .employer-filter .nstSlider .leftGrip, .employer-filter-wrapper .employer-filter .nstSlider .leftGrip,
.job-filter-wrapper .job-filter .nstSlider .rightGrip,
.candidate-filter-wrapper .job-filter .nstSlider .rightGrip,
.employer-filter-wrapper .job-filter .nstSlider .rightGrip,
.job-filter-wrapper .candidate-filter .nstSlider .rightGrip,
.candidate-filter-wrapper .candidate-filter .nstSlider .rightGrip,
.employer-filter-wrapper .candidate-filter .nstSlider .rightGrip,
.job-filter-wrapper .employer-filter .nstSlider .rightGrip,
.candidate-filter-wrapper .employer-filter .nstSlider .rightGrip,
.employer-filter-wrapper .employer-filter .nstSlider .rightGrip {
  height: 15px;
  width: 15px;
  background: #ffffff;
  border: 3px solid #246df8;
  top: -6px;
  padding-left: 5px;
  cursor: pointer;
}

.job-filter-wrapper .job-filter .nstSlider .grip-label, .candidate-filter-wrapper .job-filter .nstSlider .grip-label, .employer-filter-wrapper .job-filter .nstSlider .grip-label, .job-filter-wrapper .candidate-filter .nstSlider .grip-label, .candidate-filter-wrapper .candidate-filter .nstSlider .grip-label, .employer-filter-wrapper .candidate-filter .nstSlider .grip-label, .job-filter-wrapper .employer-filter .nstSlider .grip-label, .candidate-filter-wrapper .employer-filter .nstSlider .grip-label, .employer-filter-wrapper .employer-filter .nstSlider .grip-label {
  padding-top: 20px;
}

.job-filter-wrapper .job-filter .nstSlider .grip-label .leftLabel:before, .candidate-filter-wrapper .job-filter .nstSlider .grip-label .leftLabel:before, .employer-filter-wrapper .job-filter .nstSlider .grip-label .leftLabel:before, .job-filter-wrapper .candidate-filter .nstSlider .grip-label .leftLabel:before, .candidate-filter-wrapper .candidate-filter .nstSlider .grip-label .leftLabel:before, .employer-filter-wrapper .candidate-filter .nstSlider .grip-label .leftLabel:before, .job-filter-wrapper .employer-filter .nstSlider .grip-label .leftLabel:before, .candidate-filter-wrapper .employer-filter .nstSlider .grip-label .leftLabel:before, .employer-filter-wrapper .employer-filter .nstSlider .grip-label .leftLabel:before,
.job-filter-wrapper .job-filter .nstSlider .grip-label .rightLabel:before,
.candidate-filter-wrapper .job-filter .nstSlider .grip-label .rightLabel:before,
.employer-filter-wrapper .job-filter .nstSlider .grip-label .rightLabel:before,
.job-filter-wrapper .candidate-filter .nstSlider .grip-label .rightLabel:before,
.candidate-filter-wrapper .candidate-filter .nstSlider .grip-label .rightLabel:before,
.employer-filter-wrapper .candidate-filter .nstSlider .grip-label .rightLabel:before,
.job-filter-wrapper .employer-filter .nstSlider .grip-label .rightLabel:before,
.candidate-filter-wrapper .employer-filter .nstSlider .grip-label .rightLabel:before,
.employer-filter-wrapper .employer-filter .nstSlider .grip-label .rightLabel:before {
  content: "$";
}

.job-filter-wrapper .job-filter .nstSlider .grip-label .leftLabel:after, .candidate-filter-wrapper .job-filter .nstSlider .grip-label .leftLabel:after, .employer-filter-wrapper .job-filter .nstSlider .grip-label .leftLabel:after, .job-filter-wrapper .candidate-filter .nstSlider .grip-label .leftLabel:after, .candidate-filter-wrapper .candidate-filter .nstSlider .grip-label .leftLabel:after, .employer-filter-wrapper .candidate-filter .nstSlider .grip-label .leftLabel:after, .job-filter-wrapper .employer-filter .nstSlider .grip-label .leftLabel:after, .candidate-filter-wrapper .employer-filter .nstSlider .grip-label .leftLabel:after, .employer-filter-wrapper .employer-filter .nstSlider .grip-label .leftLabel:after {
  content: '-';
  padding-left: 8px;
  padding-right: 5px;
}

.job-filter-wrapper .job-filter .price-range-slider, .candidate-filter-wrapper .job-filter .price-range-slider, .employer-filter-wrapper .job-filter .price-range-slider, .job-filter-wrapper .candidate-filter .price-range-slider, .candidate-filter-wrapper .candidate-filter .price-range-slider, .employer-filter-wrapper .candidate-filter .price-range-slider, .job-filter-wrapper .employer-filter .price-range-slider, .candidate-filter-wrapper .employer-filter .price-range-slider, .employer-filter-wrapper .employer-filter .price-range-slider {
  padding-bottom: 30px;
}

.job-filter-wrapper .job-filter-dropdown, .candidate-filter-wrapper .job-filter-dropdown, .employer-filter-wrapper .job-filter-dropdown, .job-filter-wrapper .candidate-filter-dropdown, .candidate-filter-wrapper .candidate-filter-dropdown, .employer-filter-wrapper .candidate-filter-dropdown, .job-filter-wrapper .employer-filter-dropdown, .candidate-filter-wrapper .employer-filter-dropdown, .employer-filter-wrapper .employer-filter-dropdown {
  padding: 20px 30px 0 50px;
}

.job-filter-wrapper .job-filter-dropdown.same-pad, .candidate-filter-wrapper .job-filter-dropdown.same-pad, .employer-filter-wrapper .job-filter-dropdown.same-pad, .job-filter-wrapper .same-pad.candidate-filter-dropdown, .candidate-filter-wrapper .same-pad.candidate-filter-dropdown, .employer-filter-wrapper .same-pad.candidate-filter-dropdown, .job-filter-wrapper .same-pad.employer-filter-dropdown, .candidate-filter-wrapper .same-pad.employer-filter-dropdown, .employer-filter-wrapper .same-pad.employer-filter-dropdown {
  padding-left: 30px;
}

.job-filter-wrapper .job-filter-dropdown .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn), .candidate-filter-wrapper .job-filter-dropdown .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn), .employer-filter-wrapper .job-filter-dropdown .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn), .job-filter-wrapper .candidate-filter-dropdown .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn), .candidate-filter-wrapper .candidate-filter-dropdown .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn), .employer-filter-wrapper .candidate-filter-dropdown .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn), .job-filter-wrapper .employer-filter-dropdown .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn), .candidate-filter-wrapper .employer-filter-dropdown .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn), .employer-filter-wrapper .employer-filter-dropdown .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  width: 100%;
}

.job-filter-wrapper .job-filter-dropdown .dropdown.bootstrap-select:focus, .candidate-filter-wrapper .job-filter-dropdown .dropdown.bootstrap-select:focus, .employer-filter-wrapper .job-filter-dropdown .dropdown.bootstrap-select:focus, .job-filter-wrapper .candidate-filter-dropdown .dropdown.bootstrap-select:focus, .candidate-filter-wrapper .candidate-filter-dropdown .dropdown.bootstrap-select:focus, .employer-filter-wrapper .candidate-filter-dropdown .dropdown.bootstrap-select:focus, .job-filter-wrapper .employer-filter-dropdown .dropdown.bootstrap-select:focus, .candidate-filter-wrapper .employer-filter-dropdown .dropdown.bootstrap-select:focus, .employer-filter-wrapper .employer-filter-dropdown .dropdown.bootstrap-select:focus {
  outline: none !important;
}

.job-filter-wrapper .job-filter-dropdown .btn-light:not(:disabled):not(.disabled):active:focus, .candidate-filter-wrapper .job-filter-dropdown .btn-light:not(:disabled):not(.disabled):active:focus, .employer-filter-wrapper .job-filter-dropdown .btn-light:not(:disabled):not(.disabled):active:focus, .job-filter-wrapper .candidate-filter-dropdown .btn-light:not(:disabled):not(.disabled):active:focus, .candidate-filter-wrapper .candidate-filter-dropdown .btn-light:not(:disabled):not(.disabled):active:focus, .employer-filter-wrapper .candidate-filter-dropdown .btn-light:not(:disabled):not(.disabled):active:focus, .job-filter-wrapper .employer-filter-dropdown .btn-light:not(:disabled):not(.disabled):active:focus, .candidate-filter-wrapper .employer-filter-dropdown .btn-light:not(:disabled):not(.disabled):active:focus, .employer-filter-wrapper .employer-filter-dropdown .btn-light:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.job-filter-wrapper .job-filter-dropdown .bootstrap-select .dropdown-toggle:focus, .candidate-filter-wrapper .job-filter-dropdown .bootstrap-select .dropdown-toggle:focus, .employer-filter-wrapper .job-filter-dropdown .bootstrap-select .dropdown-toggle:focus, .job-filter-wrapper .candidate-filter-dropdown .bootstrap-select .dropdown-toggle:focus, .candidate-filter-wrapper .candidate-filter-dropdown .bootstrap-select .dropdown-toggle:focus, .employer-filter-wrapper .candidate-filter-dropdown .bootstrap-select .dropdown-toggle:focus, .job-filter-wrapper .employer-filter-dropdown .bootstrap-select .dropdown-toggle:focus, .candidate-filter-wrapper .employer-filter-dropdown .bootstrap-select .dropdown-toggle:focus, .employer-filter-wrapper .employer-filter-dropdown .bootstrap-select .dropdown-toggle:focus {
  outline: none !important;
}

.job-filter-wrapper .job-filter-dropdown .bootstrap-select .dropdown-toggle .filter-option-inner-inner, .candidate-filter-wrapper .job-filter-dropdown .bootstrap-select .dropdown-toggle .filter-option-inner-inner, .employer-filter-wrapper .job-filter-dropdown .bootstrap-select .dropdown-toggle .filter-option-inner-inner, .job-filter-wrapper .candidate-filter-dropdown .bootstrap-select .dropdown-toggle .filter-option-inner-inner, .candidate-filter-wrapper .candidate-filter-dropdown .bootstrap-select .dropdown-toggle .filter-option-inner-inner, .employer-filter-wrapper .candidate-filter-dropdown .bootstrap-select .dropdown-toggle .filter-option-inner-inner, .job-filter-wrapper .employer-filter-dropdown .bootstrap-select .dropdown-toggle .filter-option-inner-inner, .candidate-filter-wrapper .employer-filter-dropdown .bootstrap-select .dropdown-toggle .filter-option-inner-inner, .employer-filter-wrapper .employer-filter-dropdown .bootstrap-select .dropdown-toggle .filter-option-inner-inner {
  font-size: 1.4rem;
  padding: 9px 0;
}

.job-filter-wrapper .job-filter-dropdown .btn:not(:disabled):not(.disabled), .candidate-filter-wrapper .job-filter-dropdown .btn:not(:disabled):not(.disabled), .employer-filter-wrapper .job-filter-dropdown .btn:not(:disabled):not(.disabled), .job-filter-wrapper .candidate-filter-dropdown .btn:not(:disabled):not(.disabled), .candidate-filter-wrapper .candidate-filter-dropdown .btn:not(:disabled):not(.disabled), .employer-filter-wrapper .candidate-filter-dropdown .btn:not(:disabled):not(.disabled), .job-filter-wrapper .employer-filter-dropdown .btn:not(:disabled):not(.disabled), .candidate-filter-wrapper .employer-filter-dropdown .btn:not(:disabled):not(.disabled), .employer-filter-wrapper .employer-filter-dropdown .btn:not(:disabled):not(.disabled) {
  height: 40px;
}

.job-filter-wrapper .job-filter-dropdown .bootstrap-select > .dropdown-toggle.bs-placeholder, .candidate-filter-wrapper .job-filter-dropdown .bootstrap-select > .dropdown-toggle.bs-placeholder, .employer-filter-wrapper .job-filter-dropdown .bootstrap-select > .dropdown-toggle.bs-placeholder, .job-filter-wrapper .candidate-filter-dropdown .bootstrap-select > .dropdown-toggle.bs-placeholder, .candidate-filter-wrapper .candidate-filter-dropdown .bootstrap-select > .dropdown-toggle.bs-placeholder, .employer-filter-wrapper .candidate-filter-dropdown .bootstrap-select > .dropdown-toggle.bs-placeholder, .job-filter-wrapper .employer-filter-dropdown .bootstrap-select > .dropdown-toggle.bs-placeholder, .candidate-filter-wrapper .employer-filter-dropdown .bootstrap-select > .dropdown-toggle.bs-placeholder, .employer-filter-wrapper .employer-filter-dropdown .bootstrap-select > .dropdown-toggle.bs-placeholder {
  height: 40px;
  color: #6f7484;
}

.job-filter-wrapper .job-filter-dropdown .bootstrap-select .dropdown-toggle .filter-option, .candidate-filter-wrapper .job-filter-dropdown .bootstrap-select .dropdown-toggle .filter-option, .employer-filter-wrapper .job-filter-dropdown .bootstrap-select .dropdown-toggle .filter-option, .job-filter-wrapper .candidate-filter-dropdown .bootstrap-select .dropdown-toggle .filter-option, .candidate-filter-wrapper .candidate-filter-dropdown .bootstrap-select .dropdown-toggle .filter-option, .employer-filter-wrapper .candidate-filter-dropdown .bootstrap-select .dropdown-toggle .filter-option, .job-filter-wrapper .employer-filter-dropdown .bootstrap-select .dropdown-toggle .filter-option, .candidate-filter-wrapper .employer-filter-dropdown .bootstrap-select .dropdown-toggle .filter-option, .employer-filter-wrapper .employer-filter-dropdown .bootstrap-select .dropdown-toggle .filter-option {
  padding: 0;
}

.job-filter-wrapper .job-filter-dropdown .btn-light, .candidate-filter-wrapper .job-filter-dropdown .btn-light, .employer-filter-wrapper .job-filter-dropdown .btn-light, .job-filter-wrapper .candidate-filter-dropdown .btn-light, .candidate-filter-wrapper .candidate-filter-dropdown .btn-light, .employer-filter-wrapper .candidate-filter-dropdown .btn-light, .job-filter-wrapper .employer-filter-dropdown .btn-light, .candidate-filter-wrapper .employer-filter-dropdown .btn-light, .employer-filter-wrapper .employer-filter-dropdown .btn-light {
  background: transparent;
  border: 0;
  border-bottom: 2px solid rgba(0, 0, 0, 0.08);
}

.job-filter-wrapper .job-filter-dropdown .btn, .candidate-filter-wrapper .job-filter-dropdown .btn, .employer-filter-wrapper .job-filter-dropdown .btn, .job-filter-wrapper .candidate-filter-dropdown .btn, .candidate-filter-wrapper .candidate-filter-dropdown .btn, .employer-filter-wrapper .candidate-filter-dropdown .btn, .job-filter-wrapper .employer-filter-dropdown .btn, .candidate-filter-wrapper .employer-filter-dropdown .btn, .employer-filter-wrapper .employer-filter-dropdown .btn {
  padding: 0;
}

.job-filter-wrapper .job-filter-dropdown .dropdown-toggle::after, .candidate-filter-wrapper .job-filter-dropdown .dropdown-toggle::after, .employer-filter-wrapper .job-filter-dropdown .dropdown-toggle::after, .job-filter-wrapper .candidate-filter-dropdown .dropdown-toggle::after, .candidate-filter-wrapper .candidate-filter-dropdown .dropdown-toggle::after, .employer-filter-wrapper .candidate-filter-dropdown .dropdown-toggle::after, .job-filter-wrapper .employer-filter-dropdown .dropdown-toggle::after, .candidate-filter-wrapper .employer-filter-dropdown .dropdown-toggle::after, .employer-filter-wrapper .employer-filter-dropdown .dropdown-toggle::after {
  position: absolute;
  top: 17px;
  right: 0;
}

.job-filter-wrapper .job-filter-dropdown .bootstrap-select .dropdown-menu li a, .candidate-filter-wrapper .job-filter-dropdown .bootstrap-select .dropdown-menu li a, .employer-filter-wrapper .job-filter-dropdown .bootstrap-select .dropdown-menu li a, .job-filter-wrapper .candidate-filter-dropdown .bootstrap-select .dropdown-menu li a, .candidate-filter-wrapper .candidate-filter-dropdown .bootstrap-select .dropdown-menu li a, .employer-filter-wrapper .candidate-filter-dropdown .bootstrap-select .dropdown-menu li a, .job-filter-wrapper .employer-filter-dropdown .bootstrap-select .dropdown-menu li a, .candidate-filter-wrapper .employer-filter-dropdown .bootstrap-select .dropdown-menu li a, .employer-filter-wrapper .employer-filter-dropdown .bootstrap-select .dropdown-menu li a {
  font-size: 1.4rem;
}

.job-filter-wrapper .selected-options, .candidate-filter-wrapper .selected-options, .employer-filter-wrapper .selected-options {
  padding: 30px;
  padding-left: 50px;
  display: none;
  position: relative;
}

.job-filter-wrapper .selected-options.same-pad, .candidate-filter-wrapper .selected-options.same-pad, .employer-filter-wrapper .selected-options.same-pad {
  padding-left: 30px;
}

.job-filter-wrapper .selected-options.same-pad:before, .candidate-filter-wrapper .selected-options.same-pad:before, .employer-filter-wrapper .selected-options.same-pad:before {
  left: 30px;
}

.job-filter-wrapper .selected-options:before, .candidate-filter-wrapper .selected-options:before, .employer-filter-wrapper .selected-options:before {
  position: absolute;
  bottom: 0;
  left: 50px;
  right: 0;
  height: 1px;
  background: rgba(0, 0, 0, 0.08);
  content: '';
}

.job-filter-wrapper .selected-options .selection-title, .candidate-filter-wrapper .selected-options .selection-title, .employer-filter-wrapper .selected-options .selection-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.job-filter-wrapper .selected-options .selection-title h4, .candidate-filter-wrapper .selected-options .selection-title h4, .employer-filter-wrapper .selected-options .selection-title h4 {
  font-size: 1.5rem;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  position: relative;
}

.job-filter-wrapper .selected-options .selection-title a, .candidate-filter-wrapper .selected-options .selection-title a, .employer-filter-wrapper .selected-options .selection-title a {
  font-weight: 500;
  font-size: 1.2rem;
  padding: 5px 8px;
  border-radius: 3px;
  background: rgba(248, 36, 71, 0.1);
  color: #f82447;
  line-height: 1.3;
}

.job-filter-wrapper .selected-options .filtered-options, .candidate-filter-wrapper .selected-options .filtered-options, .employer-filter-wrapper .selected-options .filtered-options {
  padding: 0;
  padding-top: 20px;
  margin: 0;
  list-style: none;
}

.job-filter-wrapper .selected-options .filtered-options li + li, .candidate-filter-wrapper .selected-options .filtered-options li + li, .employer-filter-wrapper .selected-options .filtered-options li + li {
  margin-top: 5px;
}

.job-filter-wrapper .selected-options .filtered-options li, .candidate-filter-wrapper .selected-options .filtered-options li, .employer-filter-wrapper .selected-options .filtered-options li {
  color: #246df8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.job-filter-wrapper .selected-options .filtered-options li span, .candidate-filter-wrapper .selected-options .filtered-options li span, .employer-filter-wrapper .selected-options .filtered-options li span {
  font-size: 1rem;
  color: #f82447;
  cursor: pointer;
}

.filtered-job-listing-wrapper, .filtered-candidate-wrapper, .filtered-employer-wrapper {
  padding: 40px 30px;
  background: #ffffff;
}

.listing-with-map .job-listing-container .filtered-job-listing-wrapper, .listing-with-map .candidate-container .filtered-job-listing-wrapper, .listing-with-map .employer-container .filtered-job-listing-wrapper, .listing-with-map .job-listing-container .filtered-candidate-wrapper, .listing-with-map .candidate-container .filtered-candidate-wrapper, .listing-with-map .employer-container .filtered-candidate-wrapper, .listing-with-map .job-listing-container .filtered-employer-wrapper, .listing-with-map .candidate-container .filtered-employer-wrapper, .listing-with-map .employer-container .filtered-employer-wrapper {
  width: calc(100% - 280px);
}

.listing-with-map .job-listing-container .filtered-job-listing-wrapper.change-padding, .listing-with-map .candidate-container .filtered-job-listing-wrapper.change-padding, .listing-with-map .employer-container .filtered-job-listing-wrapper.change-padding, .listing-with-map .job-listing-container .change-padding.filtered-candidate-wrapper, .listing-with-map .candidate-container .change-padding.filtered-candidate-wrapper, .listing-with-map .employer-container .change-padding.filtered-candidate-wrapper, .listing-with-map .job-listing-container .change-padding.filtered-employer-wrapper, .listing-with-map .candidate-container .change-padding.filtered-employer-wrapper, .listing-with-map .employer-container .change-padding.filtered-employer-wrapper {
  padding-left: 50px;
}

.listing-with-map .job-listing-container .job-filter-wrapper, .listing-with-map .candidate-container .job-filter-wrapper, .listing-with-map .employer-container .job-filter-wrapper, .listing-with-map .job-listing-container .candidate-filter-wrapper, .listing-with-map .candidate-container .candidate-filter-wrapper, .listing-with-map .employer-container .candidate-filter-wrapper, .listing-with-map .job-listing-container .employer-filter-wrapper, .listing-with-map .candidate-container .employer-filter-wrapper, .listing-with-map .employer-container .employer-filter-wrapper {
  min-width: 280px;
}

.listing-with-map .job-listing-container .filtered-job-listing-wrapper, .listing-with-map .candidate-container .filtered-job-listing-wrapper, .listing-with-map .employer-container .filtered-job-listing-wrapper, .listing-with-map .job-listing-container .filtered-candidate-wrapper, .listing-with-map .candidate-container .filtered-candidate-wrapper, .listing-with-map .employer-container .filtered-candidate-wrapper, .listing-with-map .job-listing-container .filtered-employer-wrapper, .listing-with-map .candidate-container .filtered-employer-wrapper, .listing-with-map .employer-container .filtered-employer-wrapper {
  padding-right: 50px;
}

.job-view-controller-wrapper, .candidate-view-controller-wrapper, .employer-view-controller-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 30px;
}

.job-view-controller-wrapper .job-view-controller, .candidate-view-controller-wrapper .job-view-controller, .employer-view-controller-wrapper .job-view-controller, .job-view-controller-wrapper .candidate-view-controller, .candidate-view-controller-wrapper .candidate-view-controller, .employer-view-controller-wrapper .candidate-view-controller, .job-view-controller-wrapper .employer-view-controller, .candidate-view-controller-wrapper .employer-view-controller, .employer-view-controller-wrapper .employer-view-controller {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.job-view-controller-wrapper .job-view-controller .controller, .candidate-view-controller-wrapper .job-view-controller .controller, .employer-view-controller-wrapper .job-view-controller .controller, .job-view-controller-wrapper .candidate-view-controller .controller, .candidate-view-controller-wrapper .candidate-view-controller .controller, .employer-view-controller-wrapper .candidate-view-controller .controller, .job-view-controller-wrapper .employer-view-controller .controller, .candidate-view-controller-wrapper .employer-view-controller .controller, .employer-view-controller-wrapper .employer-view-controller .controller {
  margin-right: 15px;
  height: 40px;
  width: 40px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  color: #b1b1b1;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.job-view-controller-wrapper .job-view-controller .controller.active, .candidate-view-controller-wrapper .job-view-controller .controller.active, .employer-view-controller-wrapper .job-view-controller .controller.active, .job-view-controller-wrapper .candidate-view-controller .controller.active, .candidate-view-controller-wrapper .candidate-view-controller .controller.active, .employer-view-controller-wrapper .candidate-view-controller .controller.active, .job-view-controller-wrapper .employer-view-controller .controller.active, .candidate-view-controller-wrapper .employer-view-controller .controller.active, .employer-view-controller-wrapper .employer-view-controller .controller.active {
  color: #246df8;
  border-color: rgba(36, 109, 248, 0.3);
}

.job-view-controller-wrapper .job-view-controller .controller svg, .candidate-view-controller-wrapper .job-view-controller .controller svg, .employer-view-controller-wrapper .job-view-controller .controller svg, .job-view-controller-wrapper .candidate-view-controller .controller svg, .candidate-view-controller-wrapper .candidate-view-controller .controller svg, .employer-view-controller-wrapper .candidate-view-controller .controller svg, .job-view-controller-wrapper .employer-view-controller .controller svg, .candidate-view-controller-wrapper .employer-view-controller .controller svg, .employer-view-controller-wrapper .employer-view-controller .controller svg {
  height: 24px;
}

.job-view-controller-wrapper .showing-number span, .candidate-view-controller-wrapper .showing-number span, .employer-view-controller-wrapper .showing-number span {
  display: inline-block;
  font-size: 1.6rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.job-view-filter .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn), .candidate-view-filter .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn), .employer-view-filter .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  width: 150px;
}

.job-view-filter .bootstrap-select .dropdown-toggle .filter-option-inner-inner, .candidate-view-filter .bootstrap-select .dropdown-toggle .filter-option-inner-inner, .employer-view-filter .bootstrap-select .dropdown-toggle .filter-option-inner-inner {
  font-size: 1.4rem;
  padding: 9px 15px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.job-view-filter .btn:not(:disabled):not(.disabled), .candidate-view-filter .btn:not(:disabled):not(.disabled), .employer-view-filter .btn:not(:disabled):not(.disabled) {
  height: 40px;
}

.job-view-filter .bootstrap-select > .dropdown-toggle.bs-placeholder, .candidate-view-filter .bootstrap-select > .dropdown-toggle.bs-placeholder, .employer-view-filter .bootstrap-select > .dropdown-toggle.bs-placeholder {
  height: 40px;
}

.job-view-filter .bootstrap-select .dropdown-toggle .filter-option, .candidate-view-filter .bootstrap-select .dropdown-toggle .filter-option, .employer-view-filter .bootstrap-select .dropdown-toggle .filter-option {
  padding: 0;
}

.job-view-filter .btn, .candidate-view-filter .btn, .employer-view-filter .btn {
  padding: 0;
}

.job-view-filter .dropdown-toggle::after, .candidate-view-filter .dropdown-toggle::after, .employer-view-filter .dropdown-toggle::after {
  position: absolute;
  top: 17px;
  right: 15px;
}

.job-view-filter .dropdown.bootstrap-select:focus, .candidate-view-filter .dropdown.bootstrap-select:focus, .employer-view-filter .dropdown.bootstrap-select:focus {
  outline: none !important;
}

.job-view-filter .btn-light:not(:disabled):not(.disabled):active:focus, .candidate-view-filter .btn-light:not(:disabled):not(.disabled):active:focus, .employer-view-filter .btn-light:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.job-view-filter .bootstrap-select .dropdown-toggle:focus, .candidate-view-filter .bootstrap-select .dropdown-toggle:focus, .employer-view-filter .bootstrap-select .dropdown-toggle:focus {
  outline: none !important;
}

.job-view-filter .bootstrap-select .dropdown-menu li a, .candidate-view-filter .bootstrap-select .dropdown-menu li a, .employer-view-filter .bootstrap-select .dropdown-menu li a {
  font-size: 1.4rem;
}

.error-page-wrap {
  -webkit-box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.03);
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.03);
}

.error-page-wrap .icon {
  margin-bottom: 50px;
}

.error-page-wrap h1 {
  font-size: 6.0rem;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  margin-bottom: 20px;
}

.error-page-wrap p {
  color: #656565;
  margin-bottom: 30px;
}

.error-page-wrap .button {
  background: #023b92;
  border-radius: 3px;
  color: #ffffff;
  font-size: 1.4rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  padding: 10px 30px;
  letter-spacing: 2px;
}

@media all and (max-width: 1199px) {
  .filtered-job-listing-wrapper, .filtered-candidate-wrapper, .filtered-employer-wrapper {
    padding: 30px 20px;
    background: #ffffff;
  }
}

@media all and (max-width: 991px) {
  .listing-with-map .job-listing-container .filtered-job-listing-wrapper, .listing-with-map .candidate-container .filtered-job-listing-wrapper, .listing-with-map .employer-container .filtered-job-listing-wrapper, .listing-with-map .job-listing-container .filtered-candidate-wrapper, .listing-with-map .candidate-container .filtered-candidate-wrapper, .listing-with-map .employer-container .filtered-candidate-wrapper, .listing-with-map .job-listing-container .filtered-employer-wrapper, .listing-with-map .candidate-container .filtered-employer-wrapper, .listing-with-map .employer-container .filtered-employer-wrapper {
    width: 100%;
    padding-right: 20px;
  }
  .job-listing-container .job-search-wrapper, .candidate-container .job-search-wrapper, .employer-container .job-search-wrapper {
    padding: 30px 20px;
  }
  .searchAndFilter input {
    width: 50%;
    margin-bottom: 25px;
  }
  .searchAndFilter .bootstrap-select {
    margin-bottom: 25px;
  }
  .searchAndFilter .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
    width: calc(50% - 30px);
  }
  .searchAndFilter .bootstrap-select:nth-child(2) {
    margin-right: 0;
    width: 50%;
  }
  .searchAndFilter .bootstrap-select:nth-child(3) {
    margin-right: 25px;
  }
  .searchAndFilter .bootstrap-select:nth-child(3):not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
    width: 50%;
  }
  .searchAndFilter-3 .search-form .keyword {
    max-width: 180px;
  }
  .searchAndFilter-3 .search-form .location-input,
  .searchAndFilter-3 .search-form .category-input {
    padding: 0 15px;
  }
  .filter-categories ul li {
    width: 50%;
  }
}

@media all and (max-width: 767px) {
  .searchAndFilter-3 .search-form {
    display: block;
  }
  .searchAndFilter-3 .search-form .keyword {
    max-width: 100%;
    border-right: 0;
    margin-bottom: 20px;
  }
  .searchAndFilter-3 .search-form .location-input,
  .searchAndFilter-3 .search-form .category-input {
    padding: 0;
    width: 100%;
    margin-bottom: 20px;
    border-right: 0;
  }
  .searchAndFilter-3 .search-form .button {
    padding: 11px 30px;
  }
  .searchAndFilter-3 .filter-categories,
  .searchAndFilter-3 .filter-location {
    padding: 30px 15px 30px 45px;
  }
  .searchAndFilter-3 .filter-location ul li {
    width: 50%;
  }
}

@media all and (max-width: 539px) {
  .searchAndFilter input {
    width: 100%;
  }
  .searchAndFilter .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
    width: 100%;
  }
  .searchAndFilter .bootstrap-select:nth-child(2) {
    width: 100%;
  }
  .searchAndFilter .bootstrap-select:nth-child(3) {
    margin-right: 0;
  }
  .searchAndFilter .bootstrap-select:nth-child(3):not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
    width: 100%;
  }
  .searchAndFilter .button {
    margin-bottom: 25px;
  }
  .filter-categories ul li {
    width: 100%;
  }
  .searchAndFilter-3 .filter-location ul li {
    width: 100%;
  }
}

/*---------------------------------------------
  04.0 Breadcrumb Style
---------------------------------------------*/
.breadcrumb-area h1 {
  font-size: 2.6rem;
  font-weight: 500;
}

.breadcrumb-area .breadcrumb {
  padding: 0;
  padding-top: 12px;
  margin: 0;
  background: transparent;
}

.breadcrumb-area .breadcrumb li {
  color: #6f7484;
}

.breadcrumb-area .breadcrumb li a {
  color: #aaaaaa;
}

.breadcrumb-area .breadcrumb li a:hover {
  color: #246df8;
}

.breadcrumb-form {
  text-align: right;
}

.breadcrumb-form form {
  max-width: 350px;
  position: relative;
  margin-left: auto;
}

.breadcrumb-form form input {
  width: 100%;
  border: 0;
  border-radius: 0;
  border-bottom: 2px solid rgba(36, 109, 248, 0.2);
  background: transparent;
  outline: none;
  height: 50px;
  padding: 0;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.breadcrumb-form form input:focus {
  border-color: #246df8;
}

.breadcrumb-form form input:focus + button {
  color: #246df8;
}

.breadcrumb-form form button {
  background: transparent;
  position: absolute;
  top: 10px;
  right: 0;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  color: rgba(36, 109, 248, 0.3);
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  cursor: pointer;
}

.breadcrumb-form form button:hover {
  color: #246df8;
}

@media all and (max-width: 767px) {
  .breadcrumb-form form {
    margin-right: auto;
    margin-left: 0;
    margin-top: 15px;
  }
}

/*---------------------------------------------
  05.0 Category Style
---------------------------------------------*/
.categories {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
}

.categories:before {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 1px;
  background: rgba(0, 0, 0, 0.08);
  content: '';
}

.categories .category {
  text-align: center;
  padding: 40px 10px;
  width: 20%;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.categories .category .icon {
  margin-bottom: 15px;
}

.categories .category .icon svg {
  color: #246df8;
}

.categories .category h5 {
  font-size: 1.4rem;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.categories .category h5 span {
  color: #aaa;
}

.categories .category span {
  font-family: "Roboto", sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  display: block;
  margin-top: 5px;
}

.categories .category:hover {
  border-radius: 3px;
  -webkit-box-shadow: 0px 15px 40px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 15px 40px 0px rgba(0, 0, 0, 0.05);
}

.categories .category:hover h5 {
  color: #246df8;
}

.categories > .vr {
  position: absolute;
  top: 40px;
  bottom: 40px;
  width: 1px;
  background: rgba(0, 0, 0, 0.08);
}

.categories .vr-1 {
  left: 20%;
}

.categories .vr-2 {
  left: 40%;
}

.categories .vr-3 {
  left: 60%;
}

.categories .vr-4 {
  left: 80%;
}

@media all and (max-width: 991px) {
  .categories:before {
    top: 33.33%;
  }
  .categories:after {
    position: absolute;
    top: 66.66%;
    left: 0;
    right: 0;
    height: 1px;
    background: rgba(0, 0, 0, 0.08);
    content: '';
  }
  .categories .category {
    width: 25%;
  }
  .categories .vr-1 {
    left: 25%;
  }
  .categories .vr-2 {
    left: 50%;
  }
  .categories .vr-3 {
    left: 75%;
  }
  .categories .vr-4 {
    left: 0%;
    width: 0;
    height: 0;
  }
}

@media all and (max-width: 767px) {
  .categories:before {
    top: 25%;
  }
  .categories:after {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    background: rgba(0, 0, 0, 0.08);
    content: '';
  }
  .categories .category {
    width: 33.33%;
  }
  .categories .vr-1 {
    left: 33.33%;
  }
  .categories .vr-2 {
    left: 66.66%;
  }
  .categories .vr-3 {
    height: 1px;
    width: 100%;
    left: 0;
    right: 0;
    top: 75%;
  }
}

@media all and (max-width: 575px) {
  .categories:before {
    top: 0;
    left: 50%;
    height: 100%;
    width: 1px;
  }
  .categories:after {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 0;
  }
  .categories .category {
    width: 50%;
  }
  .categories .vr-1 {
    height: 1px;
    width: 100%;
    left: 0;
    right: 0;
    top: 20%;
  }
  .categories .vr-2 {
    height: 1px;
    width: 100%;
    left: 0;
    right: 0;
    top: 40%;
  }
  .categories .vr-3 {
    height: 1px;
    width: 100%;
    left: 0;
    right: 0;
    top: 60%;
  }
  .categories .vr-4 {
    height: 1px;
    width: 100%;
    left: 0;
    right: 0;
    top: 80%;
  }
}

@media all and (max-width: 379px) {
  .categories:before {
    height: 0;
    width: 0;
  }
  .categories .category {
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  }
  .categories .vr-1 {
    height: 0;
  }
  .categories .vr-2 {
    height: 0;
  }
  .categories .vr-3 {
    height: 0;
  }
  .categories .vr-4 {
    height: 0;
  }
}

/*---------------------------------------------
  06.0 Job Style
---------------------------------------------*/
#searchmap {
  height: 100%;
  width: 100%;
}

.listing-with-map:after {
  clear: both;
  display: block;
  content: '';
}

.listing-with-map .job-listing-container, .listing-with-map .candidate-container, .listing-with-map .employer-container {
  float: left;
}

.listing-with-map .listing-side-map {
  width: calc(100% - 1040px);
  float: right;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
}

.job-listing-container, .candidate-container, .employer-container {
  -webkit-box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.03);
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.03);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.job-listing-container .job-filter-wrapper, .candidate-container .job-filter-wrapper, .employer-container .job-filter-wrapper, .job-listing-container .candidate-filter-wrapper, .candidate-container .candidate-filter-wrapper, .employer-container .candidate-filter-wrapper, .job-listing-container .employer-filter-wrapper, .candidate-container .employer-filter-wrapper, .employer-container .employer-filter-wrapper {
  min-width: 240px;
}

.job-listing-container .filtered-job-listing-wrapper, .candidate-container .filtered-job-listing-wrapper, .employer-container .filtered-job-listing-wrapper, .job-listing-container .filtered-candidate-wrapper, .candidate-container .filtered-candidate-wrapper, .employer-container .filtered-candidate-wrapper, .job-listing-container .filtered-employer-wrapper, .candidate-container .filtered-employer-wrapper, .employer-container .filtered-employer-wrapper {
  width: calc(100% - 240px);
}

.job-listing-container .job-search-wrapper, .candidate-container .job-search-wrapper, .employer-container .job-search-wrapper {
  width: 100%;
  flex-break: after;
}

.job-search-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 30px 50px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.sidebar-controller {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.sidebar-controller .switch {
  width: 60px;
  background: #e1e1e1;
  border-radius: 30px;
  height: 30px;
  cursor: pointer;
  position: relative;
  margin-right: 15px;
}

.sidebar-controller .switch.on {
  background: #246df8;
}

.sidebar-controller .switch.on span {
  left: 35px;
}

.sidebar-controller .switch span {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #ffffff;
  position: absolute;
  top: 5px;
  left: 5px;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.sidebar-controller label {
  margin-bottom: 0;
  font-size: 1.4rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.job-search {
  max-width: 300px;
  width: 100%;
}

.job-search form {
  position: relative;
}

.job-search form input {
  width: 100%;
  border: 0;
  height: 40px;
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  border-bottom: 2px solid rgba(36, 109, 248, 0.2);
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  border-radius: 0;
}

.job-search form input:focus {
  border-color: #246df8;
}

.job-search form input:focus + button {
  color: #246df8;
}

.job-search form button {
  position: absolute;
  top: 5px;
  right: 0;
  color: rgba(36, 109, 248, 0.2);
  background: transparent;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: pointer;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  outline: none;
}

.job-search form button:hover {
  color: #246df8;
}

.job-tab {
  border-bottom: 0;
  margin-bottom: 60px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.job-tab .nav-item {
  margin-bottom: 0;
  margin: 0 15px;
}

.job-tab .nav-item a {
  border: 2px solid rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
  text-transform: uppercase;
  padding: 10px 25px;
  color: #515666;
}

.job-tab .nav-item a.active, .job-tab .nav-item a:hover {
  background: transparent;
  border: 2px solid rgba(36, 109, 248, 0.15);
  border-bottom: 2px solid rgba(36, 109, 248, 0.15);
  color: #246df8;
}

.job-filter-result .job-list, .candidate-filter-result .job-list, .employer-filter-result .job-list {
  padding: 30px 20px;
}

.job-filter-result.grid, .grid.candidate-filter-result, .grid.employer-filter-result {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.job-filter-result.grid .job-list, .grid.candidate-filter-result .job-list, .grid.employer-filter-result .job-list {
  width: calc(50% - 10px);
}

.listing-with-map .job-filter-result.grid, .listing-with-map .grid.candidate-filter-result, .listing-with-map .grid.employer-filter-result {
  display: block;
}

.listing-with-map .job-filter-result.grid .job-list, .listing-with-map .grid.candidate-filter-result .job-list, .listing-with-map .grid.employer-filter-result .job-list {
  width: 100%;
}

.listing-with-map .job-filter-result.grid .job-list.half-grid .body, .listing-with-map .grid.candidate-filter-result .job-list.half-grid .body, .listing-with-map .grid.employer-filter-result .job-list.half-grid .body {
  display: block;
}

.listing-with-map .job-filter-result.grid .job-list.half-grid .body .more, .listing-with-map .grid.candidate-filter-result .job-list.half-grid .body .more, .listing-with-map .grid.employer-filter-result .job-list.half-grid .body .more {
  display: block;
  visibility: visible;
  margin-top: 15px;
}

.listing-with-map .job-filter-result.grid .job-list.half-grid .body .more .buttons, .listing-with-map .grid.candidate-filter-result .job-list.half-grid .body .more .buttons, .listing-with-map .grid.employer-filter-result .job-list.half-grid .body .more .buttons {
  display: block;
}

.listing-with-map .job-filter-result.grid .job-list.half-grid .body .more .buttons .button, .listing-with-map .grid.candidate-filter-result .job-list.half-grid .body .more .buttons .button, .listing-with-map .grid.employer-filter-result .job-list.half-grid .body .more .buttons .button {
  margin-left: 0;
  margin-right: 10px;
}

.listing-with-map .job-filter-result.grid .job-list.half-grid .body .more .deadline, .listing-with-map .grid.candidate-filter-result .job-list.half-grid .body .more .deadline, .listing-with-map .grid.employer-filter-result .job-list.half-grid .body .more .deadline {
  text-align: left;
}

.job-list.half-grid {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.job-list.half-grid .body .more {
  display: none;
  visibility: hidden;
}

.job-list {
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  width: 100%;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 30px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.job-list .thumb {
  width: 80px;
  height: 80px;
  border-radius: 3px;
  background: #f9f9f9;
  border: 1px solid rgba(0, 0, 0, 0.05);
  position: relative;
}

.job-list .thumb img {
  max-width: 35px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.job-list .body {
  padding-left: 30px;
  width: calc(100% - 82px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.job-list .body .content h4 {
  font-size: 1.8rem;
  font-weight: 500;
  color: #232630;
  margin-bottom: 15px;
}

.job-list .body .content .info span {
  margin-right: 15px;
  font-size: 1.4rem;
  color: #6f7484;
}

.job-list .body .content .info span svg {
  height: 15px;
  color: #aaa;
}

.job-list .body .content .info .full-time svg circle,
.job-list .body .content .info .full-time svg polyline {
  stroke: #ff9247;
}

.job-list .body .content .info .part-time svg circle,
.job-list .body .content .info .part-time svg polyline {
  stroke: #6e75d0;
}

.job-list .body .content .info .freelance svg circle,
.job-list .body .content .info .freelance svg polyline {
  stroke: #82d219;
}

.job-list .body .content .info .temporary svg circle,
.job-list .body .content .info .temporary svg polyline {
  stroke: #ff5377;
}

.job-list .body .more .buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.job-list .body .more .buttons .button {
  padding: 6px 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1.4rem;
  margin-left: 20px;
  background: #f9f9f9;
  border: 1px solid rgba(0, 0, 0, 0.08);
}

.job-list .body .more .buttons .button:hover {
  color: #ffffff;
  background: #246df8;
  border-color: #246df8;
}

.job-list .body .more .buttons .favourite {
  height: 38px;
  width: 38px;
  background: #ffffff;
  text-align: center;
  line-height: 38px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 50%;
}

.job-list .body .more .buttons .favourite:hover, .job-list .body .more .buttons .favourite.active {
  color: #ff8fa6;
  border-color: #ff8fa6;
}

.job-list .body .more .buttons .favourite:hover svg, .job-list .body .more .buttons .favourite.active svg {
  color: #ff8fa6;
}

.job-list .body .more .buttons .favourite svg {
  height: 15px;
  color: #c3c3c3;
}

.job-list .body .more .deadline {
  font-size: 1.3rem;
  margin-top: 10px;
  font-style: italic;
  font-weight: 300;
  display: block;
  text-align: right;
}

.job-list:last-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.job-list:hover {
  background: #ffffff;
  border-top-color: transparent;
  -webkit-box-shadow: 0px 15px 40px 0px rgba(237, 237, 237, 0.4);
  box-shadow: 0px 15px 40px 0px rgba(237, 237, 237, 0.4);
}

.job-list:hover + .job-list {
  border-top-color: transparent;
}

.job-list:hover .body .content h4 {
  color: #246df8;
}

.explore-job {
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  margin-bottom: 30px;
}

.explore-job .explore-job-header {
  padding: 20px 20px 20px 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.explore-job .explore-job-header h5 {
  color: #101725;
  font-size: 1.4rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  text-transform: uppercase;
}

.explore-job .explore-job-header svg {
  height: 14px;
  color: #246df8;
}

.explore-job .key-info {
  padding-right: 25px;
}

.explore-job .key-info ul {
  padding: 30px;
  padding-right: 0;
  padding-bottom: 0;
  padding-top: 0;
  margin: 0;
  margin-top: 30px;
  list-style: none;
  height: 270px;
  overflow-x: hidden;
  overflow-y: scroll;
  margin-bottom: 30px;
  /* Set Scrollbar Width */
  /* Set Scrollbar Buttons */
  /* Set Scrollbar Track */
  /* Set Scrollbar Handle */
}

.explore-job .key-info ul::-webkit-scrollbar {
  width: 10px;
  background: #f3f3f3;
  right: 20px;
  border-radius: 5px;
}

.explore-job .key-info ul::-webkit-scrollbar-track {
  border-radius: 5px;
}

.explore-job .key-info ul::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #dbe0e9;
}

.explore-job .key-info ul::-webkit-scrollbar-thumb:window-inactive {
  background: #dbe0e9;
}

.explore-job .key-info ul li a {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #6f7484;
}

.explore-job .key-info ul li a span {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #aaaaaa;
  margin-left: 5px;
}

.explore-job .key-info ul li a:hover {
  color: #246df8;
}

.explore-job .key-info ul li + li {
  margin-top: 8px;
}

.apply-popup .modal-dialog {
  margin: 20px auto;
}

.apply-popup .modal-dialog .modal-content .modal-header {
  padding: 15px 30px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.apply-popup .modal-dialog .modal-content .modal-header h5 {
  font-size: 1.4rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  color: #232630;
}

.apply-popup .modal-dialog .modal-content .modal-header h5 svg {
  height: 15px;
  width: 15px;
  margin-right: 7px;
  color: #bebebe;
}

.apply-popup .modal-dialog .modal-content .modal-header .close {
  font-size: 2rem;
  padding: 0;
  margin: 0;
  height: 30px;
  width: 30px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 50%;
  color: #6f7484;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  outline: none;
}

.apply-popup .modal-dialog .modal-content .modal-body {
  padding: 30px;
}

.apply-popup .modal-dialog .modal-content .modal-body .form-group {
  margin-bottom: 30px;
}

.apply-popup .modal-dialog .modal-content .modal-body .form-group .form-control {
  height: 50px;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 0;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 1.4rem;
}

.apply-popup .modal-dialog .modal-content .modal-body .form-group textarea.form-control {
  height: 100px;
}

.apply-popup .modal-dialog .modal-content .modal-body .file-input-wrap label {
  background: #f9f9f9;
  border-radius: 3px;
  border: 1px dashed #d5d5d5;
  width: 100%;
  display: block;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px;
  cursor: pointer;
}

.apply-popup .modal-dialog .modal-content .modal-body .file-input-wrap label input {
  display: none;
}

.apply-popup .modal-dialog .modal-content .modal-body .file-input-wrap label svg {
  display: block;
  height: 20px;
  width: 20px;
  color: #aaaaaa;
  margin: 0 auto;
}

.apply-popup .modal-dialog .modal-content .modal-body .file-input-wrap label span {
  color: #6f7484;
  font-size: 1.4rem;
}

.apply-popup .modal-dialog .modal-content .modal-body .file-input-wrap label span span {
  font-size: 1.2rem;
  color: #aaaaaa;
}

.apply-popup .modal-dialog .modal-content .modal-body .more-option {
  margin-bottom: 30px;
}

.apply-popup .modal-dialog .modal-content .modal-body .more-option label {
  color: #6f7484;
}

.apply-popup .modal-dialog .modal-content .modal-body .more-option label .dot {
  border: 1px solid #dcdcdc;
  border-radius: 5px;
}

.apply-popup .modal-dialog .modal-content .modal-body .more-option label .dot:before {
  border-radius: 2px;
}

.apply-popup .modal-dialog .modal-content .modal-body .more-option label a {
  color: #246df8;
  text-decoration: underline;
}

.apply-popup .modal-dialog .modal-content .modal-body .button {
  margin-left: 0;
  padding: 15px;
  border: 0;
  border-radius: 5px;
  background: #246df8;
  color: #ffffff;
  font-weight: 500;
  font-size: 1.4rem;
  font-family: "Poppins", sans-serif;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

/*---------------------------------------------
  Job Details
---------------------------------------------*/
.job-listing-details {
  background: #ffffff;
  padding: 30px;
  -webkit-box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.03);
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.03);
}

.job-title-and-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.job-title-and-info .title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.job-title-and-info .title .thumb {
  width: 100px;
  height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  background: #f9f9f9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 20px;
}

.job-title-and-info .title .title-body h4 {
  font-size: 2.6rem;
  font-weight: 500;
  padding-bottom: 15px;
}

.job-title-and-info .title .title-body .info span {
  font-size: 1.4rem;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  margin-right: 15px;
  color: #6f7484;
}

.job-title-and-info .title .title-body .info span svg {
  height: 15px;
  margin-right: 5px;
  color: #aaa;
}

.job-title-and-info .title .title-body .info span.full-time svg {
  color: #ff9247;
}

.job-title-and-info .title .title-body .info span.temporary svg {
  color: #ff5377;
}

.job-title-and-info .title .title-body .info span.part-time svg {
  color: #6e75d0;
}

.job-title-and-info .title .title-body .info span.freelance svg {
  color: #82d219;
}

.job-title-and-info .buttons .apply {
  padding: 15px 30px;
  border-radius: 3px;
  background: #023b92;
  color: #ffffff;
  font-size: 1.4rem;
  border: 1px solid rgba(36, 109, 248, 0.15);
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.job-title-and-info .buttons .apply svg {
  height: 18px;
  margin-left: 10px;
}

.job-title-and-info .buttons .apply:hover {
  color: #ffffff;
  background: #246df8;
}

.job-title-and-info .buttons .save {
  padding: 15px 30px;
  border-radius: 3px;
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(247, 249, 252, 0)), to(#f7f9fc));
  background-image: -webkit-linear-gradient(right, rgba(247, 249, 252, 0), #f7f9fc);
  background-image: -o-linear-gradient(right, rgba(247, 249, 252, 0), #f7f9fc);
  background-image: linear-gradient(to left, rgba(247, 249, 252, 0), #f7f9fc);
  color: #6f7484;
  font-size: 1.4rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  border: 1px solid rgba(0, 0, 0, 0.05);
  margin-right: 10px;
}

.job-title-and-info .buttons .save svg {
  height: 18px;
  margin-right: 7px;
}

.job-title-and-info .buttons .save:hover {
  color: #ff8fa6;
  border-color: #ff8fa6;
}

.responsibilities ul,
.edication-and-experience ul,
.other-benifit ul {
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 0;
}

.responsibilities ul li,
.edication-and-experience ul li,
.other-benifit ul li {
  font-size: 1.4rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #6f7484;
  position: relative;
  padding-left: 20px;
}

.responsibilities ul li:before,
.edication-and-experience ul li:before,
.other-benifit ul li:before {
  position: absolute;
  content: '\f058';
  font-family: 'Font Awesome\ 5 Free';
  font-weight: 900;
  color: #aaa;
  top: 2px;
  left: 0;
  font-size: 1.2rem;
}

.responsibilities ul li + li,
.edication-and-experience ul li + li,
.other-benifit ul li + li {
  margin-top: 8px;
}

.job-apply-buttons a {
  padding: 14px 30px;
  border-radius: 3px;
  border: 1px solid;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
}

.job-apply-buttons .apply {
  background: #023b92;
  color: #ffffff;
  border-color: #023b92;
  margin-right: 10px;
}

.job-apply-buttons .email {
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(247, 249, 252, 0)), to(#f7f9fc));
  background-image: -webkit-linear-gradient(right, rgba(247, 249, 252, 0), #f7f9fc);
  background-image: -o-linear-gradient(right, rgba(247, 249, 252, 0), #f7f9fc);
  background-image: linear-gradient(to left, rgba(247, 249, 252, 0), #f7f9fc);
  color: #6f7484;
  border-color: rgba(0, 0, 0, 0.05);
}

.job-apply-buttons .email:hover {
  color: #ffffff;
  background: #246df8;
  border-color: #246df8;
}

.job-apply-buttons .email svg {
  height: 15px;
}

.company-information {
  margin-top: 60px;
}

.company-information ul {
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 0;
}

.company-information ul li {
  font-size: 1.4rem;
  color: #6f7484;
}

.company-information ul li a {
  color: #246df8;
  text-decoration: underline;
}

.company-information ul li span {
  font-size: "Poppins", sans-serif;
  font-weight: 500;
  margin-right: 5px;
  color: #232630;
}

.company-information ul li + li {
  margin-top: 8px;
}

.job-summary {
  padding: 30px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(247, 249, 252, 0)), to(#f7f9fc));
  background-image: -webkit-linear-gradient(right, rgba(247, 249, 252, 0), #f7f9fc);
  background-image: -o-linear-gradient(right, rgba(247, 249, 252, 0), #f7f9fc);
  background-image: linear-gradient(to left, rgba(247, 249, 252, 0), #f7f9fc);
}

.job-summary h4 {
  font-size: 1.5rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  padding-bottom: 20px;
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.job-summary ul {
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 0;
}

.job-summary ul li {
  font-size: 1.4rem;
  color: #6f7484;
}

.job-summary ul li span {
  font-size: "Poppins", sans-serif;
  font-weight: 500;
  margin-right: 5px;
  color: #232630;
}

.job-summary ul li + li {
  margin-top: 8px;
}

.share-job-post {
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(247, 249, 252, 0)), to(#f7f9fc));
  background-image: -webkit-linear-gradient(right, rgba(247, 249, 252, 0), #f7f9fc);
  background-image: -o-linear-gradient(right, rgba(247, 249, 252, 0), #f7f9fc);
  background-image: linear-gradient(to left, rgba(247, 249, 252, 0), #f7f9fc);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  margin-top: 50px;
  padding: 12px 30px;
}

.share-job-post .share {
  font-size: 1.4rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  margin-right: 10px;
}

.share-job-post .share i {
  margin-right: 10px;
}

.share-job-post a {
  margin-right: 12px;
  color: #6f7484;
}

.share-job-post a:hover {
  color: #246df8;
}

.share-job-post .add-more {
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: #f7f9fc;
  border-radius: 3px;
  height: 30px;
  width: 30px;
  line-height: 31px;
  text-align: center;
}

.information-and-share .buttons {
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.information-and-share .buttons .button {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1.4rem;
}

.information-and-share .buttons .print,
.information-and-share .buttons .report {
  padding: 12px 5px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  width: calc(50% - 10px);
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(247, 249, 252, 0)), to(#f7f9fc));
  background-image: -webkit-linear-gradient(right, rgba(247, 249, 252, 0), #f7f9fc);
  background-image: -o-linear-gradient(right, rgba(247, 249, 252, 0), #f7f9fc);
  background-image: linear-gradient(to left, rgba(247, 249, 252, 0), #f7f9fc);
}

.information-and-share .buttons .print svg,
.information-and-share .buttons .report svg {
  height: 15px;
}

.information-and-share .buttons .print:hover,
.information-and-share .buttons .report:hover {
  border-color: rgba(36, 109, 248, 0.2);
  color: #246df8;
}

.information-and-share .job-location {
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(247, 249, 252, 0)), to(#f7f9fc));
  background-image: -webkit-linear-gradient(right, rgba(247, 249, 252, 0), #f7f9fc);
  background-image: -o-linear-gradient(right, rgba(247, 249, 252, 0), #f7f9fc);
  background-image: linear-gradient(to left, rgba(247, 249, 252, 0), #f7f9fc);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  margin-top: 50px;
  padding: 30px;
}

.information-and-share .job-location h4 {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 20px;
}

.information-and-share .job-location .cp-map {
  height: 250px;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.job-apply-buttons {
  margin-top: 45px;
}

.job-apply-buttons a {
  margin-top: 15px;
}

/*---------------------------------------------
  Job Post
---------------------------------------------*/
.post-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.03);
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.03);
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.post-container .post-content-wrapper {
  width: 75%;
}

.post-container .post-sidebar {
  width: 25%;
}

.post-content-wrapper {
  padding: 20px;
  background: #ffffff;
}

.basic-info-input h4 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
  margin-bottom: 30px;
  text-transform: uppercase;
}

.basic-info-input h4 svg {
  height: 15px;
  margin-right: 7px;
  color: #aaa;
}

.job-post-form .form-group {
  margin-bottom: 30px;
}

.job-post-form .form-group label {
  font-size: 1.4rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #6f7484;
}

.job-post-form .form-group .form-control {
  height: 40px;
  padding: 0;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 0;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  outline: none;
  font-size: 1.4rem;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.job-post-form .form-group .form-control:focus {
  border-bottom-color: #246df8;
}

.job-post-form .form-group textarea.form-control {
  height: 100px;
}

.job-post-form .button {
  padding: 12px 30px;
  background: #023b92;
  color: #ffffff;
}

.job-post-form .col-form-label {
  font-size: 1.4rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #6f7484;
}

.job-post-form .form-group.terms {
  margin-top: 30px;
}

.job-post-form .basic-info-input .form-group {
  position: relative;
}

.job-post-form .basic-info-input .form-group select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: transparent;
  width: 100%;
  border: 0;
  border-radius: 0;
  padding-left: 0.5em;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  z-index: 10;
}

@-moz-document url-prefix() {
  .job-post-form .basic-info-input .form-group select {
    width: 110%;
  }
}

.job-post-form .basic-info-input .form-group select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 black;
}

.job-post-form .basic-info-input .form-group select::-ms-expand {
  display: none;
}

.job-post-form .basic-info-input .form-group i {
  position: absolute;
  right: 0;
  top: 13px;
  z-index: 5;
  color: #aaa;
}

.set-location {
  border: 1px solid rgba(0, 0, 0, 0.08);
  background-image: -webkit-gradient(linear, right top, left top, from(#ffffff), to(#f7f9fc));
  background-image: -webkit-linear-gradient(right, #ffffff, #f7f9fc);
  background-image: -o-linear-gradient(right, #ffffff, #f7f9fc);
  background-image: linear-gradient(to left, #ffffff, #f7f9fc);
  padding: 20px;
  border-radius: 3px;
}

.set-location h5 {
  font-size: 1.4rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-bottom: 10px;
}

.set-location .cp-map {
  height: 180px;
}

.job-post-form .basic-info-input .mce-tinymce {
  margin-bottom: 30px;
}

.package-select {
  padding-bottom: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.package-select .package-select-inputs {
  width: 210px;
}

.package-select .payment-method a {
  padding: 8px 20px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: #f7f9fc;
  border-radius: 3px;
  font-size: 1.2rem;
  text-transform: uppercase;
  margin-right: 15px;
}

.package-select .payment-method a i {
  margin-right: 7px;
}

.package-select .payment-method .active {
  border: #023b92;
  background: #023b92;
  color: #ffffff;
}

.package-select .form-group {
  margin-bottom: 15px;
}

.package-select .form-group:last-child {
  margin-bottom: 0;
}

.custom-radio {
  display: none;
}

.custom-radio + label {
  font-weight: normal;
  cursor: pointer;
  margin-bottom: 0;
}

.custom-radio + label .dot {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  width: 18px;
  height: 18px;
  cursor: pointer;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  position: relative;
  border: 2px solid rgba(0, 0, 0, 0.1);
}

.custom-radio + label .dot::before {
  content: "";
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  width: 9px;
  height: 9px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-color: #246df8;
  position: absolute;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -moz-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.custom-radio:checked + label .dot::before {
  filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
  opacity: 1;
}

.custom-radio + label .package-type {
  display: inline-block;
  width: 100px;
}

.job-post-form .form-group.terms {
  margin-top: 30px;
}

.job-post-form .form-group.terms label {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
}

.job-post-form .form-group.terms label a {
  color: #246df8;
  text-decoration: underline;
}

/*------------------------------------------------------
  TinyMCE Editor
------------------------------------------------------*/
.mce-statusbar.mce-container.mce-panel.mce-stack-layout-item.mce-last {
  display: none;
}

.mce-tinymce.mce-container.mce-panel {
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.mce-container-body.mce-flow-layout {
  background: #f7f9fc;
  display: inline-block;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.08);
}

.mce-container.mce-flow-layout-item.mce-btn-group .mce-widget.mce-btn {
  background: transparent;
}

.mce-btn-group .mce-btn:hover {
  border-color: transparent;
}

.mce-edit-area.mce-container.mce-panel.mce-stack-layout-item {
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.mce-top-part::before {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.mce-btn.mce-active button, .mce-btn.mce-active:hover button, .mce-btn.mce-active i, .mce-btn.mce-active:hover i {
  color: #246df8 !important;
}

.post-sidebar {
  background-image: -webkit-gradient(linear, left top, right top, from(#ffffff), to(#f7f9fc));
  background-image: -webkit-linear-gradient(left, #ffffff, #f7f9fc);
  background-image: -o-linear-gradient(left, #ffffff, #f7f9fc);
  background-image: linear-gradient(to right, #ffffff, #f7f9fc);
  padding: 30px;
}

.post-sidebar h5 {
  font-size: 1.5rem;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.post-sidebar h5 svg {
  height: 15px;
  width: 15px;
  margin-right: 7px;
  color: #aaa;
}

.post-sidebar .sidebar-menu {
  padding: 0;
  padding-bottom: 10px;
  margin: 0;
  list-style: none;
}

.post-sidebar .sidebar-menu li {
  padding: 5px 0;
  position: relative;
}

.post-sidebar .sidebar-menu li:before {
  position: absolute;
  content: '';
  bottom: 0;
  left: 0;
  height: 1px;
  width: 0;
  background: rgba(0, 0, 0, 0.08);
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.post-sidebar .sidebar-menu li i {
  color: #6f7484;
  width: 25px;
}

.post-sidebar .sidebar-menu li a {
  color: #6f7484;
  font-weight: 400;
  font-size: 1.4rem;
  padding: 5px 0;
}

.post-sidebar .sidebar-menu li:hover:before {
  width: 100%;
}

.post-sidebar .sidebar-menu li:hover a {
  color: #246df8;
}

.post-sidebar .signin-option {
  margin-top: 25px;
  background: rgba(36, 109, 248, 0.15);
  border: 1px solid rgba(36, 109, 248, 0.4);
  border-radius: 3px;
  padding: 20px;
  margin-bottom: 0;
}

.post-sidebar .signin-option .buttons a {
  font-size: 1.4rem;
  font-weight: 500;
  margin-right: 10px;
  margin-top: 10px;
}

.post-sidebar .signin-option .buttons a:last-child {
  margin-right: 0;
}

.post-sidebar .signin-option .buttons .signin {
  background: #246df8;
  border-radius: 3px;
  color: #ffffff;
  padding: 8px 20px;
}

.post-sidebar .signin-option .buttons .register {
  color: #246df8;
  text-decoration: underline;
}

.leaflet-popup-content-wrapper {
  border-radius: 3px !important;
}

.leaflet-popup {
  margin-left: -140px !important;
}

.leaflet-container h5 a {
  color: #101725;
}

.leaflet-container a {
  color: #6f7484;
}

.leaflet-marker-icon.marker-cluster {
  height: 46px !important;
  width: 46px !important;
}

.job-search-result-listing {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px;
  border-radius: 3px;
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
}

.job-search-result-listing .thumb {
  width: 50px;
  height: 50px;
  border-radius: 3px;
  background: #f9f9f9;
  position: relative;
}

.job-search-result-listing .thumb img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: 35px;
}

.job-search-result-listing .body {
  padding-left: 20px;
}

.job-search-result-listing .body h5 {
  font-size: 1.5rem;
}

.job-search-result-listing .body .info {
  color: #6f7484;
}

.job-search-result-listing .body .info span svg {
  height: 14px;
  width: 14px;
  margin-right: 7px;
}

.job-search-result-listing .body .info span + span {
  margin-left: 20px;
}

.leaflet-popup-tip-container {
  top: calc(100% - 1px);
}

.leaflet-marker-icon.marker-cluster div {
  left: 0;
  bottom: 0;
  margin-top: 0;
  margin-left: 0;
  height: 46px;
  width: 46px;
}

.leaflet-marker-icon.marker-cluster div span {
  line-height: 46px;
}

@media all and (max-width: 1199px) {
  .job-list.half-grid {
    padding: 20px 15px;
  }
  .listing-with-map {
    display: block;
  }
  .listing-with-map .listing-side-map {
    width: 100%;
    height: 300px;
    overflow: hidden;
    position: static;
  }
  .listing-with-map .job-listing-container, .listing-with-map .candidate-container, .listing-with-map .employer-container {
    width: 100%;
  }
  .sidebar-controller {
    display: none;
    visibility: hidden;
  }
}

@media all and (max-width: 991px) {
  .job-list {
    padding: 30px 20px;
  }
  .job-listing-container, .candidate-container, .employer-container {
    display: block;
  }
  .job-listing-container .filtered-job-listing-wrapper, .candidate-container .filtered-job-listing-wrapper, .employer-container .filtered-job-listing-wrapper, .job-listing-container .filtered-candidate-wrapper, .candidate-container .filtered-candidate-wrapper, .employer-container .filtered-candidate-wrapper, .job-listing-container .filtered-employer-wrapper, .candidate-container .filtered-employer-wrapper, .employer-container .filtered-employer-wrapper {
    width: 100%;
  }
  .job-listing-container .job-filter-wrapper, .candidate-container .job-filter-wrapper, .employer-container .job-filter-wrapper, .job-listing-container .candidate-filter-wrapper, .candidate-container .candidate-filter-wrapper, .employer-container .candidate-filter-wrapper, .job-listing-container .employer-filter-wrapper, .candidate-container .employer-filter-wrapper, .employer-container .employer-filter-wrapper {
    width: 100%;
  }
  .listing-with-map .job-listing-container, .listing-with-map .candidate-container, .listing-with-map .employer-container {
    display: block;
  }
  .listing-with-map .job-listing-container .job-filter-wrapper, .listing-with-map .candidate-container .job-filter-wrapper, .listing-with-map .employer-container .job-filter-wrapper, .listing-with-map .job-listing-container .candidate-filter-wrapper, .listing-with-map .candidate-container .candidate-filter-wrapper, .listing-with-map .employer-container .candidate-filter-wrapper, .listing-with-map .job-listing-container .employer-filter-wrapper, .listing-with-map .candidate-container .employer-filter-wrapper, .listing-with-map .employer-container .employer-filter-wrapper {
    width: 100%;
  }
  .information-and-share {
    margin-top: 60px;
  }
  .job-title-and-info {
    display: block;
  }
  .job-title-and-info .buttons {
    margin-top: 30px;
  }
  .post-container {
    display: block;
  }
  .post-container .post-content-wrapper,
  .post-container .post-sidebar {
    width: 100%;
  }
  .package-select .payment-method a {
    margin-top: 15px;
  }
}

@media all and (max-width: 767px) {
  .job-list {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .job-list .body {
    display: block;
  }
  .job-list .body .more .buttons {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    padding-top: 20px;
  }
  .job-list .body .more .buttons .button {
    margin-left: 0;
    margin-right: 20px;
  }
  .job-list .body .more .deadline {
    text-align: left;
  }
  .job-view-controller-wrapper .job-view-controller .controller, .candidate-view-controller-wrapper .job-view-controller .controller, .employer-view-controller-wrapper .job-view-controller .controller, .job-view-controller-wrapper .candidate-view-controller .controller, .candidate-view-controller-wrapper .candidate-view-controller .controller, .employer-view-controller-wrapper .candidate-view-controller .controller, .job-view-controller-wrapper .employer-view-controller .controller, .candidate-view-controller-wrapper .employer-view-controller .controller, .employer-view-controller-wrapper .employer-view-controller .controller {
    display: none;
    visibility: hidden;
  }
  .job-title-and-info .title {
    display: block;
  }
  .job-title-and-info .title .thumb {
    margin-bottom: 20px;
  }
}

@media all and (max-width: 575px) {
  .job-listing-details {
    padding: 20px;
  }
  .company-information {
    margin-bottom: 30px;
  }
  .apply-popup .modal-dialog {
    margin: 15px;
  }
}

@media all and (max-width: 479px) {
  .job-list {
    display: block;
  }
  .job-list .thumb {
    margin-bottom: 20px;
  }
  .job-list .thumb:before {
    height: 0;
    width: 0;
  }
  .job-list .body {
    padding-left: 0;
    width: 100%;
  }
  .job-list .body .content .info span {
    margin-right: 10px;
  }
  .job-view-controller-wrapper, .candidate-view-controller-wrapper, .employer-view-controller-wrapper {
    display: block;
  }
  .job-view-controller-wrapper .showing-number, .candidate-view-controller-wrapper .showing-number, .employer-view-controller-wrapper .showing-number {
    margin-top: 20px;
  }
}

@media all and (max-width: 439px) {
  .job-tab {
    display: block;
  }
  .job-tab .nav-item {
    margin-bottom: 0;
    margin: 0;
    display: block;
    text-align: center;
  }
  .job-tab .nav-item a:focus,
  .job-tab .nav-item a:hover {
    display: block;
  }
  .job-tab .nav-item:focus {
    outline: none;
  }
  .job-tab .nav-item + .nav-item {
    margin-top: 15px;
  }
  .job-title-and-info .buttons a {
    display: block;
    width: 180px;
    text-align: center;
  }
  .job-title-and-info .buttons .save {
    margin-bottom: 15px;
  }
  .job-title-and-info .buttons .save svg {
    margin-left: 0;
  }
}

/*---------------------------------------------
  07.0 Candidate Style
---------------------------------------------*/
.candidate-filter-result.grid {
  position: relative;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
}

.candidate-filter-result.grid:before {
  position: absolute;
  top: 30px;
  left: 50%;
  bottom: 0;
  width: 1px;
  background: rgba(0, 0, 0, 0.08);
  content: '';
}

.candidate {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 30px;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.candidate .thumb {
  width: 80px;
  border-radius: 3px;
  overflow: hidden;
}

.candidate .thumb + .body {
  padding-left: 20px;
}

.candidate .body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.candidate .body .content h4 {
  font-size: 1.8rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  margin-bottom: 12px;
}

.candidate .body .content h4:hover {
  color: #246df8;
}

.candidate .body .content .info span {
  color: #6f7484;
  margin-right: 15px;
}

.candidate .body .content .info span.work-post svg {
  color: rgba(36, 109, 248, 0.4);
}

.candidate .body .content .info span svg {
  height: 14px;
  width: 14px;
  margin-right: 7px;
  color: #aaa;
}

.candidate .body .button-area a {
  font-size: 1.4rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  padding: 8px 15px;
  color: #232630;
  border-radius: 3px;
  background: #f9f9f9;
  border: 1px solid rgba(0, 0, 0, 0.08);
}

.candidate:hover {
  border-bottom-color: transparent;
  -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);
}

.candidate:hover .body .button-area a {
  background: #246df8;
  border-color: #246df8;
  color: #ffffff;
}

.candidate.half-grid {
  width: calc(50% - 30px);
  display: block;
  text-align: center;
}

.candidate.half-grid .thumb {
  margin: 0 auto;
}

.candidate.half-grid .thumb + .body {
  padding-left: 0;
}

.candidate.half-grid .body {
  display: block;
  margin-top: 15px;
}

.candidate.half-grid .body .content span {
  display: inline-block;
  margin-bottom: 8px;
}

.candidate.half-grid .body .button-area {
  margin-top: 10px;
}

/*---------------------------------------------
  Candidate Details
---------------------------------------------*/
.candidate-details {
  background: #ffffff;
  padding: 30px;
  -webkit-box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.03);
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.03);
}

.title-and-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.title-and-info .title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.title-and-info .title .thumb {
  width: 100px;
  border-radius: 3px;
  overflow: hidden;
}

.title-and-info .title .thumb + .title-body {
  padding-left: 20px;
}

.title-and-info .title .title-body h4 {
  font-size: 2.6rem;
  font-weight: 600;
  padding-bottom: 15px;
}

.title-and-info .title .title-body .info span {
  font-size: 1.4rem;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  margin-right: 15px;
  color: #6f7484;
}

.title-and-info .title .title-body .info span svg {
  height: 15px;
  margin-right: 5px;
}

.title-and-info .title .title-body .info .candidate-designation svg {
  color: rgba(36, 109, 248, 0.4);
}

.title-and-info .download-resume a {
  padding: 15px 30px;
  border-radius: 3px;
  background: #023b92;
  color: #ffffff;
  font-size: 1.4rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.title-and-info .download-resume a svg {
  height: 18px;
  height: 18px;
  margin-left: 10px;
}

.title-and-info .download-resume a:hover {
  color: #ffffff;
  background: #246df8;
}

.skill-and-profile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.skill-and-profile label {
  font-size: 1.4rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-right: 10px;
}

.skill-and-profile .skill a {
  border: 1px solid rgba(0, 0, 0, 0.05);
  padding: 3px 15px;
  margin-right: 5px;
  border-radius: 3px;
  background-image: -webkit-gradient(linear, left top, right top, from(#f9fbfe), to(#ffffff));
  background-image: -webkit-linear-gradient(left, #f9fbfe, #ffffff);
  background-image: -o-linear-gradient(left, #f9fbfe, #ffffff);
  background-image: linear-gradient(to right, #f9fbfe, #ffffff);
}

.skill-and-profile .skill a:hover {
  background: #246df8;
  border-color: #246df8;
  color: #ffffff;
}

.skill-and-profile .social-profile a {
  font-size: 1.4rem;
  margin-right: 15px;
  color: #828794;
}

.skill-and-profile .social-profile a:hover {
  color: #246df8;
}

.details-information {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.details-section {
  padding-left: 30px;
}

.details-section h4 {
  font-size: 1.8rem;
  position: relative;
  padding-bottom: 20px;
}

.details-section h4 svg {
  height: 22px;
  position: absolute;
  top: 0;
  left: -30px;
  color: #aaa;
}

.details-section p {
  color: #6f7484;
}

.details-section p + p {
  margin-top: 20px;
}

.details-section + .details-section {
  margin-top: 60px;
}

.edication-background .education-label {
  position: relative;
  padding-left: 25px;
}

.edication-background .education-label:before {
  position: absolute;
  content: '';
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #246df8;
  top: 7px;
  left: 0;
}

.edication-background .education-label .study-year {
  font-size: 1.4rem;
  color: #6f7484;
  margin-bottom: 5px;
  display: inline-block;
}

.edication-background .education-label h5 {
  color: #232630;
  font-family: "Poppins", sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  margin-bottom: 10px;
}

.edication-background .education-label h5 span {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #6f7484;
  margin-left: 10px;
}

.edication-background .education-label + .education-label {
  margin-top: 20px;
}

.experience .experience-section {
  position: relative;
  padding-left: 25px;
}

.experience .experience-section:before {
  position: absolute;
  content: '';
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #246df8;
  top: 7px;
  left: 0;
}

.experience .experience-section .service-year {
  font-size: 1.4rem;
  color: #6f7484;
  margin-bottom: 5px;
  display: inline-block;
}

.experience .experience-section h5 {
  color: #232630;
  font-family: "Poppins", sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  margin-bottom: 10px;
}

.experience .experience-section h5 span {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #6f7484;
  margin-left: 10px;
}

.experience .experience-section + .experience-section {
  margin-top: 20px;
}

.special-qualification ul {
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 0;
}

.special-qualification ul li {
  font-size: 1.4rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #6f7484;
  position: relative;
  padding-left: 20px;
}

.special-qualification ul li:before {
  position: absolute;
  content: '\f058';
  font-family: 'Font Awesome\ 5 Free';
  font-weight: 900;
  top: 3px;
  left: 0;
  font-size: 1.2rem;
  color: #aaa;
}

.special-qualification ul li + li {
  margin-top: 8px;
}

.portfolio .portfolio-slider .portfolio-item {
  position: relative;
}

.portfolio .portfolio-slider .portfolio-item:hover .overlay a {
  margin: 0 5px;
  opacity: 1;
}

.portfolio .portfolio-slider .portfolio-item .overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 999;
  width: 100%;
  text-align: center;
}

.portfolio .portfolio-slider .portfolio-item .overlay a {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.8);
  text-align: center;
  line-height: 40px;
  margin: 0 23px;
  opacity: 0;
  color: #246df8;
}

.portfolio .portfolio-slider .portfolio-item .overlay a svg {
  height: 20px;
}

.portfolio-slider {
  position: relative;
}

.portfolio-slider .owl-item.active .portfolio-item:before {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  width: 100%;
  content: '';
  background-image: -webkit-gradient(linear, left top, right top, from(white), to(rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(left, white, rgba(255, 255, 255, 0));
  background-image: -o-linear-gradient(left, white, rgba(255, 255, 255, 0));
  background-image: linear-gradient(to right, white, rgba(255, 255, 255, 0));
}

.portfolio-slider .owl-item.active + .owl-item.active .portfolio-item:before {
  height: 0;
  width: 0;
  background-image: transparent;
}

.portfolio-slider .owl-item.active + .owl-item.active + .owl-item.active .portfolio-item:before {
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  width: 100%;
  content: '';
  background-image: -webkit-gradient(linear, right top, left top, from(white), to(rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(right, white, rgba(255, 255, 255, 0));
  background-image: -o-linear-gradient(right, white, rgba(255, 255, 255, 0));
  background-image: linear-gradient(to left, white, rgba(255, 255, 255, 0));
}

.portfolio-slider .owl-nav .owl-next,
.portfolio-slider .owl-nav .owl-prev {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 1.8rem;
  outline: none;
}

.portfolio-slider .owl-nav .owl-next {
  right: 0;
}

.portfolio-slider .owl-nav .owl-prev {
  left: 0;
}

.personal-information {
  margin-top: 60px;
}

.personal-information ul {
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 0;
}

.personal-information ul li {
  font-size: 1.4rem;
  color: #6f7484;
}

.personal-information ul li span {
  font-size: "Poppins", sans-serif;
  font-weight: 500;
  margin-right: 5px;
  color: #232630;
}

.personal-information ul li + li {
  margin-top: 8px;
}

.information {
  padding: 30px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(247, 249, 252, 0)), to(#f7f9fc));
  background-image: -webkit-linear-gradient(right, rgba(247, 249, 252, 0), #f7f9fc);
  background-image: -o-linear-gradient(right, rgba(247, 249, 252, 0), #f7f9fc);
  background-image: linear-gradient(to left, rgba(247, 249, 252, 0), #f7f9fc);
}

.information h4 {
  font-size: 1.5rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  padding-bottom: 20px;
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.information ul {
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 0;
}

.information ul li {
  font-size: 1.4rem;
  color: #6f7484;
}

.information ul li span {
  font-size: "Poppins", sans-serif;
  font-weight: 500;
  margin-right: 5px;
  color: #232630;
}

.information ul li + li {
  margin-top: 8px;
}

.information-and-contact .buttons {
  margin-top: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.information-and-contact .buttons .button {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1.4rem;
}

.information-and-contact .buttons .button + .button {
  margin-top: 20px;
}

.information-and-contact .buttons .contact-button {
  flex-break: after;
  width: 100%;
  background: #023b92;
  padding: 14px;
  border-radius: 3px;
  border: 1px solid #023b92;
  text-align: center;
  color: #ffffff;
}

.information-and-contact .buttons .cover-download,
.information-and-contact .buttons .contact-download {
  padding: 14px 5px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  width: calc(50% - 10px);
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(247, 249, 252, 0)), to(#f7f9fc));
  background-image: -webkit-linear-gradient(right, rgba(247, 249, 252, 0), #f7f9fc);
  background-image: -o-linear-gradient(right, rgba(247, 249, 252, 0), #f7f9fc);
  background-image: linear-gradient(to left, rgba(247, 249, 252, 0), #f7f9fc);
}

.information-and-contact .buttons .cover-download svg,
.information-and-contact .buttons .contact-download svg {
  height: 15px;
}

.information-and-contact .buttons .cover-download:hover,
.information-and-contact .buttons .contact-download:hover {
  border-color: rgba(36, 109, 248, 0.2);
  color: #246df8;
}

.contact-form-modal.show {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.contact-form-modal.show .modal-dialog {
  max-width: 500px;
  width: 100%;
}

.contact-form-modal .modal-body {
  padding: 40px 30px;
}

.contact-form-modal .modal-body h4 {
  font-size: 1.5rem;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-weight: 500;
}

.contact-form-modal .modal-body h4 svg {
  height: 18px;
  margin-right: 10px;
  color: #6f7484;
}

.contact-form-modal .modal-body form .form-group {
  margin-bottom: 30px;
}

.contact-form-modal .modal-body form .form-control {
  height: 40px;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  border-radius: 0;
  font-size: 1.4rem;
}

.contact-form-modal .modal-body form .form-control:focus {
  border-color: #246df8;
}

.contact-form-modal .modal-body form textarea.form-control {
  height: 100px;
}

.contact-form-modal .modal-body form .button {
  background: #246df8;
  padding: 11px 30px;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.basic-info-input .add-new-field {
  color: #246df8;
  background: rgba(36, 109, 248, 0.15);
  border-radius: 3px;
  border: 0;
  font-size: 1.3rem;
  font-weight: 500;
  padding: 5px 20px;
  margin-bottom: 30px;
}

.post-content-wrapper .input-group .form-control {
  padding-left: 15px;
}

.post-content-wrapper .input-group-prepend {
  margin-right: -1px;
  position: relative;
}

.post-content-wrapper .input-group-prepend .input-group-text {
  width: 80px;
  background: transparent;
  margin-right: 1px;
  border: 0;
  font-size: 1.4rem;
  padding-left: 0;
  border-radius: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.post-content-wrapper .input-group-prepend .dropdown-label {
  overflow: hidden;
  position: relative;
  background: white;
  width: 70px;
  padding: 0;
  border-bottom: 0;
}

.post-content-wrapper .input-group-prepend .dropdown-label:before {
  position: absolute;
  content: '';
  top: 10px;
  bottom: 10px;
  right: 0;
  width: 1px;
  background: rgba(0, 0, 0, 0.08);
}

.post-content-wrapper .input-group-prepend .dropdown-label select {
  background: transparent;
  width: 100%;
  border: 0;
  border-radius: 0;
  padding-left: 0.5em;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  z-index: 10;
}

.post-content-wrapper .input-group-prepend .dropdown-label i {
  position: absolute;
  right: 0.5em;
  top: 13px;
  z-index: 5;
  color: black;
}

.post-content-wrapper .upload-portfolio-image {
  position: relative;
}

.post-content-wrapper .update-photo {
  height: 100px;
  position: relative;
  width: 100px;
  margin: 0;
  overflow: hidden;
  border-radius: 3px;
}

.post-content-wrapper .update-photo img {
  height: 105%;
  width: initial;
  max-height: 100%;
  max-width: initial;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.post-content-wrapper .upload-portfolio-image .file-upload {
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 100px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  text-align: center;
  color: #fff;
  font-size: 1.3rem;
  border: 0;
  padding: 5px 0;
  background: rgba(36, 109, 248, 0.7);
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
}

.post-content-wrapper .upload-portfolio-image .file-upload svg {
  height: 15px;
  width: 15px;
}

.post-content-wrapper .upload-portfolio-image .file-upload:hover {
  background: rgba(36, 109, 248, 0.9);
}

.post-content-wrapper .file-upload input.file-input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
  height: 100%;
}

.post-content-wrapper .add-cv {
  position: relative;
  overflow: hidden;
  text-align: center;
  display: inline-block;
  color: #6f7484;
  font-size: 1.4rem;
  font-weight: 500;
  padding: 5px 15px;
  margin-right: 10px;
  background: #f7f9fc;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 3px;
}

.post-content-wrapper .add-cv input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
  height: 100%;
}

.post-content-wrapper .add-cv svg {
  height: 15px;
  width: 15px;
  margin-left: 7px;
}

@media all and (max-width: 991px) {
  .information-and-contact {
    margin-top: 60px;
  }
}

@media all and (max-width: 767px) {
  .candidate .body {
    display: block;
  }
  .candidate .body .button-area {
    margin-top: 20px;
  }
  .title-and-info {
    display: block;
  }
  .title-and-info .download-resume {
    margin-top: 30px;
  }
  .skill-and-profile {
    display: block;
  }
  .skill-and-profile .social-profile {
    margin-top: 20px;
  }
  .portfolio-slider .owl-item.active .portfolio-item:before {
    height: 0;
    width: 0;
  }
  .candidate-details {
    padding: 20px;
  }
  .contact-form-modal.show {
    display: block !important;
  }
  .contact-form-modal.show .modal-dialog {
    padding: 50px 15px;
    margin: 0 auto;
  }
  .contact-form-modal .modal-body form .form-group {
    margin-bottom: 20px;
  }
}

@media all and (max-width: 575px) {
  .candidate-filter-result.grid {
    display: block;
  }
  .candidate-filter-result.grid:before {
    height: 0;
    width: 0;
  }
  .candidate.half-grid {
    width: 100%;
  }
}

@media all and (max-width: 439px) {
  .candidate {
    display: block;
  }
  .candidate .thumb + .body {
    padding-left: 0;
    margin-top: 15px;
  }
  .title-and-info .title {
    display: block;
  }
  .title-and-info .title .thumb + .title-body {
    padding-left: 0;
    margin-top: 20px;
  }
  .skill-and-profile .skill a {
    margin-bottom: 10px;
  }
  .information-and-contact .buttons {
    display: block;
  }
  .information-and-contact .buttons .cover-download,
  .information-and-contact .buttons .contact-download {
    width: 100%;
  }
  .information-and-contact .buttons .cover-download svg,
  .information-and-contact .buttons .contact-download svg {
    height: 15px;
  }
  .information-and-contact .buttons .cover-download:hover,
  .information-and-contact .buttons .contact-download:hover {
    border-color: rgba(36, 109, 248, 0.2);
    color: #246df8;
  }
}

/*---------------------------------------------
  08.0 Company Style
---------------------------------------------*/
.company-wrap {
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background-image: -webkit-gradient(linear, left bottom, left top, from(#ffffff), to(rgba(36, 109, 248, 0.03)));
  background-image: -webkit-linear-gradient(bottom, #ffffff, rgba(36, 109, 248, 0.03));
  background-image: -o-linear-gradient(bottom, #ffffff, rgba(36, 109, 248, 0.03));
  background-image: linear-gradient(to top, #ffffff, rgba(36, 109, 248, 0.03));
  padding: 30px 10px;
  margin-bottom: 30px;
  text-align: center;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.company-wrap .thumb {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  padding-bottom: 30px;
  max-width: 120px;
  margin: 0 auto 20px;
}

.company-wrap .body h4 {
  font-size: 1.8rem;
  margin-bottom: 5px;
  font-weight: 500;
}

.company-wrap .body span {
  display: block;
  font-size: 1.4rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #6f7484;
}

.company-wrap .body .button {
  background: rgba(36, 109, 248, 0.1);
  color: #246df8;
  border-radius: 3px;
  padding: 8px 20px;
  font-size: 1.4rem;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  margin-top: 20px;
}

.company-wrap:hover {
  -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);
  background: #ffffff;
}

.company-wrap:hover .body h4 {
  color: #246df8;
}

.company-wrap:hover .body .button {
  background: #246df8;
  color: #ffffff;
}

.company-carousel {
  position: relative;
}

.company-carousel .owl-nav button.owl-next,
.company-carousel .owl-nav button.owl-prev {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 1.8rem;
  outline: none;
  color: rgba(36, 109, 248, 0.4);
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.company-carousel .owl-nav button.owl-next:hover,
.company-carousel .owl-nav button.owl-prev:hover {
  color: #246df8;
}

.company-carousel .owl-nav .owl-prev {
  left: -30px;
}

.company-carousel .owl-nav .owl-next {
  right: -30px;
}

@media all and (max-width: 575px) {
  .company-carousel {
    padding-bottom: 40px;
  }
  .company-carousel .owl-nav button.owl-next,
  .company-carousel .owl-nav button.owl-prev {
    top: auto;
    bottom: -25px;
    left: 50%;
  }
  .company-carousel .owl-nav button.owl-prev {
    margin-left: -23px;
  }
  .company-carousel .owl-nav button.owl-next {
    margin-right: -15px;
  }
}

/*---------------------------------------------
  09.0 App Style
---------------------------------------------*/
.app-download-bg {
  background: url(../images/bg/app-bg.jpg) no-repeat left;
  background-size: cover;
}

.app-download h2 {
  font-size: 2.6rem;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 20px;
}

.app-download p {
  max-width: 380px;
  color: #ffffff;
}

.app-download .app-download-button a {
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 3px;
  padding: 12px 15px 12px 45px;
  margin: 0 5px;
  font-size: 1.5rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #ffffff;
  position: relative;
  margin-top: 20px;
}

.app-download .app-download-button a:before {
  position: absolute;
  top: 11px;
  left: 15px;
  content: '';
}

.app-download .app-download-button a:hover {
  border-color: #ffffff;
}

.app-download .app-download-button .apple-app:before {
  content: url(../images/apple-white.png);
}

.app-download .app-download-button .android-app:before {
  content: url(../images/google-white.png);
}

/*---------------------------------------------
    10.0 Form Style
---------------------------------------------*/
form {
  position: relative;
}

.newsletter-form .newsletter-error,
.newsletter-form .newsletter-success {
  position: absolute;
  top: 100%;
  left: 0;
  color: #ffffff !important;
  margin-top: 20px;
  display: none;
}

.newsletter-error {
  background: #e74c3c;
  text-align: left;
  padding: 0 5px;
  color: #ffffff;
}

.newsletter-success {
  background: #16a085;
  padding: 0 5px;
  text-align: left;
  color: #ffffff;
}

/*---------------------------------------------
  11.0 Header Style
---------------------------------------------*/
.section-header {
  text-align: center;
  margin-bottom: 50px;
}

.section-header h2 {
  font-family: #headerFont;
  font-weight: 600;
  font-size: 2.8rem;
}

.section-header p {
  font-size: 1.6rem;
  margin-top: 10px;
}

.section-header.section-header-center h6 {
  font-size: 1.8rem;
  font-family: "Poppins", sans-serif;
  color: #6f7484;
  font-weight: 600;
  margin-bottom: 10px;
}

.section-header.section-header-center h2 {
  font-size: 2.6rem;
  color: #101725;
  font-weight: 600;
}

.section-header.section-header-2 {
  text-align: left;
}

.section-header-with-right-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.section-header-with-right-content .header-right {
  font-size: 1.5;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #246df8;
}

@media all and (max-width: 479px) {
  .section-header-with-right-content {
    display: block;
  }
  .section-header-with-right-content .header-right {
    margin-top: 10px;
  }
}

/*---------------------------------------------
  12.0 Nav Style
---------------------------------------------*/
.header-2 {
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.05);
  z-index: 999;
  background: #ffffff;
}

.header-2 .fluid-nav {
  padding: 0 50px;
}

.cp-nav.fluid-nav {
  padding: 0 15px;
}

header {
  position: relative;
}

header .absolute-nav {
  position: absolute;
  top: 25px;
  left: 0;
  right: 0;
  z-index: 999;
}

.transparent-nav.bg-light {
  background: transparent !important;
}

.navbar {
  padding: 0;
}

.transparent-nav .navbar-collapse .navbar-nav > li > a {
  color: #ffffff;
}

.cp-nav .navbar-brand {
  padding: 0;
}

.cp-nav .navbar-collapse .navbar-nav {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cp-nav .navbar-collapse .navbar-nav li {
  margin-left: 30px;
}

.cp-nav .navbar-collapse .navbar-nav li:first-child {
  margin-left: 0;
}

.cp-nav .navbar-collapse .navbar-nav li a {
  font-size: 1.4rem;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  padding: 12px 0;
}

.cp-nav .navbar-collapse .navbar-nav.job-browse li a i {
  margin-right: 8px;
}

.cp-nav .navbar-collapse .navbar-nav .post-job a {
  background: #ffffff;
  color: #246df8;
  padding: 12px 30px;
  border-radius: 3px;
  font-weight: 500;
}

.cp-nav .navbar-collapse .navbar-nav .post-job a i {
  margin-right: 10px;
  font-size: 1.2rem;
}

.header-top {
  padding: 20px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-top .header-top-toggler {
  display: none;
}

.top-nav .bootstrap-select {
  outline: none !important;
}

.top-nav .bootstrap-select .btn {
  outline: none !important;
}

.cp-nav .navbar-collapse .navbar-nav.account-nav .header-top-notification .notification-button {
  font-size: 1.3rem;
  position: relative;
  padding-right: 8px;
}

.cp-nav .navbar-collapse .navbar-nav.account-nav .header-top-notification .notification-button:after {
  position: absolute;
  top: -14px;
  right: -7px;
  height: 4px;
  width: 4px;
  border-radius: 5px;
  background: #ffffff;
  content: '';
}

.cp-nav .navbar-collapse .navbar-nav.account-nav .header-top-notification .notification-button:before {
  position: absolute;
  top: -15px;
  left: -2px;
  content: "\e6b8";
  font-family: 'themify';
  font-size: 1.4rem;
  color: #ffffff;
}

.cp-nav .navbar-collapse .navbar-nav.account-nav .header-top-notification .notification-card {
  position: absolute;
  top: 30px;
  right: 0;
  display: none;
  background: #ffffff;
  width: 315px;
  -webkit-box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
}

.cp-nav .navbar-collapse .navbar-nav.account-nav .header-top-notification .notification-card .notification-head {
  padding: 10px 20px;
  font-size: 1.4rem;
  font-family: "Roboto", sans-serif;
  color: #6f7484;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.cp-nav .navbar-collapse .navbar-nav.account-nav .header-top-notification .notification-card .notification-head a {
  padding: 0;
  color: #aaa;
}

.cp-nav .navbar-collapse .navbar-nav.account-nav .header-top-notification .notification-card .notification-head a:hover {
  color: #246df8;
}

.cp-nav .navbar-collapse .navbar-nav.account-nav .header-top-notification .notification-card .notification-body {
  padding: 20px;
  max-height: 320px;
  overflow-x: hidden;
  overflow-y: scroll;
  /* Set Scrollbar Width */
  /* Set Scrollbar Buttons */
  /* Set Scrollbar Track */
  /* Set Scrollbar Handle */
}

.cp-nav .navbar-collapse .navbar-nav.account-nav .header-top-notification .notification-card .notification-body::-webkit-scrollbar {
  width: 8px;
  background: #f3f3f3;
  right: 20px;
  border-radius: 5px;
}

.cp-nav .navbar-collapse .navbar-nav.account-nav .header-top-notification .notification-card .notification-body::-webkit-scrollbar-track {
  border-radius: 5px;
}

.cp-nav .navbar-collapse .navbar-nav.account-nav .header-top-notification .notification-card .notification-body::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #dbe0e9;
}

.cp-nav .navbar-collapse .navbar-nav.account-nav .header-top-notification .notification-card .notification-body .notification-list {
  padding: 10px 0;
  padding-left: 30px;
  position: relative;
  display: block;
}

.cp-nav .navbar-collapse .navbar-nav.account-nav .header-top-notification .notification-card .notification-body .notification-list i {
  position: absolute;
  top: 14px;
  left: 0;
  color: rgba(1, 209, 61, 0.5);
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.cp-nav .navbar-collapse .navbar-nav.account-nav .header-top-notification .notification-card .notification-body .notification-list p {
  margin-bottom: 0;
  color: #6f7484;
  line-height: 1.3;
}

.cp-nav .navbar-collapse .navbar-nav.account-nav .header-top-notification .notification-card .notification-body .notification-list p span {
  font-size: 1.4rem;
  font-style: normal;
  color: #246df8;
}

.cp-nav .navbar-collapse .navbar-nav.account-nav .header-top-notification .notification-card .notification-body .notification-list span {
  color: #aaafcb;
  font-size: 1.3rem;
  font-style: italic;
}

.cp-nav .navbar-collapse .navbar-nav.account-nav .header-top-notification .notification-card .notification-body .notification-list:hover i {
  color: #01d13d;
}

.cp-nav .navbar-collapse .navbar-nav.account-nav .header-top-notification .notification-card .notification-footer {
  text-align: center;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
}

.cp-nav .navbar-collapse .navbar-nav.account-nav .header-top-notification .notification-card .notification-footer a {
  padding: 10px 20px;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-size: 1.3rem;
  color: #aaa;
}

.cp-nav .navbar-collapse .navbar-nav.account-nav .header-top-notification .notification-card .notification-footer a:hover {
  color: #246df8;
}

.cp-nav .navbar-collapse .navbar-nav.account-nav > li.dropdown a:after {
  top: 10px;
}

.header-top-account .account-button,
.header-top-account > button {
  font-size: 1.3rem;
  color: #6f7484;
  position: relative;
  padding-right: 8px;
  margin-right: 10px;
}

.header-top-account .account-button:before,
.header-top-account > button:before {
  content: "\e602";
  font-family: 'themify';
  margin-right: 6px;
}

.header-top-account > button {
  background: transparent;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  padding: 0;
  cursor: pointer;
}

.header-top-account .account-card {
  padding: 20px 0;
  display: none;
  background: #ffffff;
  z-index: 99999;
  width: 290px;
  position: absolute;
  top: 100%;
  right: 0;
  border-radius: 3px;
  overflow: hidden;
  -webkit-box-shadow: 0px 15px 40px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 15px 40px 0px rgba(0, 0, 0, 0.05);
}

.header-top-account .account-card .header-top-account-info {
  padding: 0 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.header-top-account .account-card .header-top-account-info .account-thumb {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 15px;
}

.header-top-account .account-card .header-top-account-info .account-body h5 {
  font-size: 1.6rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.header-top-account .account-card .header-top-account-info .account-body .mail {
  font-size: 1.3rem;
  color: #6f7484;
}

.header-top-account .account-card .account-item-list {
  padding: 0;
  margin: 0;
  margin-top: 15px;
  list-style: none;
  font-size: 0;
}

.header-top-account .account-card .account-item-list li a {
  padding: 5px 20px;
  display: block;
  color: #6f7484;
  font-size: 1.4rem;
  font-weight: 400;
}

.header-top-account .account-card .account-item-list li a span {
  margin-right: 10px;
  color: #aaa;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.header-top-account .account-card .account-item-list li a:hover {
  color: #246df8;
}

.header-top-account .account-card .account-item-list li a:hover span {
  color: #246df8;
}

.header-top-account.login-modals a:before,
.header-top-account.login-modals button:before {
  content: '';
  height: 0;
  width: 0;
}

.select-language .btn-light {
  background: transparent;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.select-language .btn-light .filter-option-inner-inner {
  font-size: 1.3rem;
  color: #6f7484;
}

.select-language .btn {
  padding-left: 0;
}

.select-language .dropdown-menu,
.select-language .bootstrap-select .dropdown-menu li a .text {
  font-size: 1.3rem;
}

.cp-nav-2 {
  padding: 0 0 20px 0;
}

.cp-nav-2 .navbar-collapse .navbar-nav {
  width: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cp-nav-2 .navbar-collapse .navbar-nav .menu-item {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  margin-right: 30px;
  color: #6f7484;
}

.cp-nav-2 .navbar-collapse .navbar-nav .menu-item a:hover {
  color: #246df8;
}

.cp-nav-2 .navbar-collapse .navbar-nav .menu-item.post-job {
  margin-left: auto;
  margin-right: 0;
}

.cp-nav-2 .navbar-collapse .navbar-nav .menu-item.post-job a {
  background: #023b92;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  border-radius: 3px;
  padding: 11px 20px;
}

.cp-nav-2 .navbar-collapse .navbar-nav .menu-item.post-job a i {
  font-size: 1.2rem;
  margin-right: 10px;
}

.account-entry .modal-header {
  padding: 15px 30px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.account-entry .modal-header h5 {
  font-size: 1.4rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
}

.account-entry .modal-header h5 svg {
  height: 15px;
  width: 15px;
  margin-right: 7px;
  color: #6f7484;
}

.account-entry .modal-header .close {
  font-size: 2rem;
  padding: 0;
  margin: 0;
  height: 30px;
  width: 30px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 50%;
  color: #6f7484;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  outline: none;
}

.account-entry .modal-header .close:focus, .account-entry .modal-header .close:hover {
  color: #246df8;
}

.account-entry .modal-body {
  padding: 30px;
}

.account-entry .modal-body .account-type {
  margin-bottom: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.account-entry .modal-body .account-type a {
  padding: 15px 20px;
  background: #f7f9fc;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1.4rem;
  color: #232630;
  width: 45%;
  text-align: center;
}

.account-entry .modal-body .account-type a.active {
  color: #246df8;
  background: rgba(36, 109, 248, 0.2);
  border-color: transparent;
}

.account-entry .modal-body .account-type a svg {
  height: 14px;
  width: 14px;
  margin-right: 7px;
}

.account-entry .modal-body .account-type label {
  cursor: pointer;
  width: 45%;
}

.account-entry .modal-body .account-type label span {
  padding: 15px 10px;
  background: #f7f9fc;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1.4rem;
  color: #232630;
  text-align: center;
  width: 100%;
  display: block;
}

.account-entry .modal-body .account-type label input[type="radio"]:checked + span {
  color: #246df8;
  background: rgba(36, 109, 248, 0.2);
  border-color: transparent;
}

.account-entry .modal-body .account-type label input {
  display: none;
}

.account-entry .modal-body .form-group {
  margin-bottom: 30px;
}

.account-entry .modal-body .form-group .form-control {
  height: 50px;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 0;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 1.4rem;
}

.account-entry .modal-body .form-group .form-control:focus {
  border-bottom-color: #246df8;
}

.account-entry .modal-body .more-option {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: #6f7484;
  margin-bottom: 30px;
}

.account-entry .modal-body .more-option .form-check .form-check-input {
  margin-top: 6px;
}

.account-entry .modal-body .more-option .form-check .form-check-label {
  margin-left: 7px;
}

.account-entry .modal-body .more-option.terms .form-check-label a {
  color: #246df8;
  text-decoration: underline;
}

.account-entry .modal-body .more-option a:hover {
  color: #246df8;
}

.account-entry .modal-body .button {
  color: #ffffff;
  padding: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.account-entry .modal-body .shortcut-login {
  margin-top: 30px;
}

.account-entry .modal-body .shortcut-login span {
  font-size: 1.4rem;
  font-family: "Roboto", sans-serif;
  color: #6f7484;
  margin-bottom: 10px;
  display: inline-block;
}

.account-entry .modal-body .shortcut-login .buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.account-entry .modal-body .shortcut-login .buttons a {
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  padding: 14px 30px;
  font-size: 1.4rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #101725;
  text-align: center;
  width: 45%;
}

.account-entry .modal-body .shortcut-login .buttons a i {
  margin-right: 7px;
}

.account-entry .modal-body .shortcut-login .buttons a.facebook i {
  color: #3b5998;
}

.account-entry .modal-body .shortcut-login .buttons a.google i {
  color: #ea4335;
}

.account-entry .modal-body .shortcut-login p {
  font-size: 1.4rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #6f7484;
  margin-top: 20px;
}

.account-entry .modal-body .shortcut-login p a {
  font-weight: 500;
}

.account-entry .modal-body .shortcut-login p a:hover {
  color: #246df8;
}

.btn-light:not(:disabled):not(.disabled):active {
  border-bottom: 0;
}

.show > .btn-light.dropdown-toggle {
  border-bottom: 0;
}

@media all and (min-width: 1440px) {
  .cp-nav.fluid-nav {
    padding: 0 50px;
  }
}

@media all and (min-width: 1200px) {
  .cp-nav .navbar-collapse {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .cp-nav .navbar-collapse .navbar-nav > li.dropdown a {
    padding-right: 13px;
  }
  .cp-nav .navbar-collapse .navbar-nav > li.dropdown a:after {
    position: absolute;
    top: 23px;
    right: 0;
    color: #a6c5ff;
  }
  .cp-nav .navbar-collapse .navbar-nav li.dropdown .dropdown-menu {
    top: 100%;
    left: 0;
  }
  .cp-nav .navbar-collapse .navbar-nav li.dropdown .dropdown-menu .dropdown-menu {
    top: 0;
    left: 100%;
    right: auto;
  }
  .cp-nav .navbar-collapse .navbar-nav li.dropdown .dropdown-menu .dropdown-menu .dropdown-menu .dropdown-menu {
    left: auto;
    right: 100%;
  }
  .cp-nav .navbar-collapse .navbar-nav li.dropdown .dropdown-menu .dropdown a:after {
    right: 20px;
    top: 20px;
  }
  .cp-nav .navbar-collapse .navbar-nav li.dropdown .dropdown-menu {
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    min-width: 210px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 3px;
  }
  .cp-nav .navbar-collapse .navbar-nav li.dropdown .dropdown-menu li {
    margin-left: 0;
  }
  .cp-nav .navbar-collapse .navbar-nav li.dropdown .dropdown-menu li:last-child {
    border-bottom: 0;
  }
  .cp-nav .navbar-collapse .navbar-nav li.dropdown .dropdown-menu li a {
    padding: 6px 20px;
    display: block;
    font-weight: 400;
    position: relative;
    color: #6f7484;
  }
  .cp-nav .navbar-collapse .navbar-nav li.dropdown .dropdown-menu li a:hover {
    color: #246df8;
  }
  .cp-nav .navbar-collapse .navbar-nav.main-nav li.dropdown .dropdown-menu {
    display: block;
    visibility: hidden;
    opacity: 0;
  }
  .cp-nav .navbar-collapse .navbar-nav.main-nav li.dropdown:hover > .dropdown-menu {
    opacity: 1;
    visibility: visible;
  }
  .cp-nav .navbar-collapse .navbar-nav.account-nav {
    margin-left: 30px !important;
  }
  .cp-nav .navbar-collapse .navbar-nav.account-nav li {
    margin-left: 30px;
  }
  .cp-nav .navbar-collapse .navbar-nav.account-nav li a {
    padding-top: 0;
    padding-bottom: 0;
  }
  .cp-nav .navbar-collapse .navbar-nav.account-nav li svg {
    height: 20px;
    width: 20px;
  }
  .cp-nav .navbar-collapse .navbar-nav.account-nav li:first-child {
    margin-left: 0;
    padding-right: 7px;
  }
  .cp-nav .navbar-collapse .navbar-nav.account-nav li.dropdown .dropdown-menu {
    left: auto;
    right: 0;
  }
  .cp-nav .navbar-collapse .navbar-nav.account-nav .login-popup button {
    background: transparent;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
    color: #ffffff;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 1.4rem;
    padding: 0;
    cursor: pointer;
  }
  .cp-nav .navbar-collapse .navbar-nav.job-browse {
    margin-left: 40px;
  }
  .cp-nav .navbar-collapse .navbar-nav.job-browse li a.dropdown-toggle:hover {
    color: #ffffff;
  }
  .cp-nav .navbar-collapse .navbar-nav.job-browse li .dropdown-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    visibility: hidden;
    opacity: 0;
    padding: 40px 30px;
    margin: 0;
    list-style: none;
  }
  .cp-nav .navbar-collapse .navbar-nav.job-browse li .dropdown-menu .search-by h5 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 1.4rem;
    text-transform: uppercase;
    color: #515666;
    margin-bottom: 20px;
  }
  .cp-nav .navbar-collapse .navbar-nav.job-browse li .dropdown-menu .search-by ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  .cp-nav .navbar-collapse .navbar-nav.job-browse li .dropdown-menu .search-by ul li {
    min-width: 220px;
  }
  .cp-nav .navbar-collapse .navbar-nav.job-browse li .dropdown-menu .search-by ul li a {
    padding: 0;
    color: #6f7484;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 1.4rem;
  }
  .cp-nav .navbar-collapse .navbar-nav.job-browse li .dropdown-menu .search-by ul li a:hover {
    color: #246df8;
  }
  .cp-nav .navbar-collapse .navbar-nav.job-browse li .dropdown-menu .search-by ul li a:hover span {
    color: #246df8;
  }
  .cp-nav .navbar-collapse .navbar-nav.job-browse li .dropdown-menu .search-by ul li a span {
    color: #aaaaaa;
  }
  .cp-nav .navbar-collapse .navbar-nav.job-browse li .dropdown-menu .search-by ul li + li {
    margin-top: 12px;
  }
  .cp-nav .navbar-collapse .navbar-nav.job-browse li .dropdown-menu .search-by:first-child {
    position: relative;
    margin-right: 30px;
  }
  .cp-nav .navbar-collapse .navbar-nav.job-browse li .dropdown-menu .search-by:first-child:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 1px;
    background: rgba(0, 0, 0, 0.08);
    content: '';
  }
  .cp-nav .navbar-collapse .navbar-nav.job-browse li .dropdown-menu.show {
    visibility: visible;
    opacity: 1;
  }
}

@media all and (min-width: 992px) {
  .top-nav {
    display: block;
  }
  .header-top {
    margin-bottom: 8px;
  }
  .header-top .logo-area a img {
    height: 68px;
  }
  .header-top .top-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .header-top .top-nav .header-top-notification {
    position: relative;
  }
  .header-top .top-nav .header-top-notification .notification-button {
    color: #6f7484;
    font-size: 1.3rem;
    margin-right: 23px;
  }
  .header-top .top-nav .header-top-notification .notification-button:before {
    content: "\e6b8";
    font-family: 'themify';
    margin-right: 5px;
  }
  .header-top .top-nav .header-top-notification .notification-card {
    display: none;
    position: absolute;
    top: 30px;
    left: 0;
    width: 315px;
    background: #ffffff;
    -webkit-box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.08);
    border-radius: 3px;
    z-index: 99;
  }
  .header-top .top-nav .header-top-notification .notification-card .notification-head {
    padding: 10px 20px;
    font-size: 1.4rem;
    font-family: "Roboto", sans-serif;
    color: #6f7484;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  }
  .header-top .top-nav .header-top-notification .notification-card .notification-head a {
    padding: 0;
    color: #aaa;
  }
  .header-top .top-nav .header-top-notification .notification-card .notification-head a:hover {
    color: #246df8;
  }
  .header-top .top-nav .header-top-notification .notification-card .notification-body {
    padding: 20px;
    max-height: 320px;
    overflow-x: hidden;
    overflow-y: scroll;
    /* Set Scrollbar Width */
    /* Set Scrollbar Buttons */
    /* Set Scrollbar Track */
    /* Set Scrollbar Handle */
  }
  .header-top .top-nav .header-top-notification .notification-card .notification-body::-webkit-scrollbar {
    width: 8px;
    background: #f3f3f3;
    right: 20px;
    border-radius: 5px;
  }
  .header-top .top-nav .header-top-notification .notification-card .notification-body::-webkit-scrollbar-track {
    border-radius: 5px;
  }
  .header-top .top-nav .header-top-notification .notification-card .notification-body::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #dbe0e9;
  }
  .header-top .top-nav .header-top-notification .notification-card .notification-body .notification-list {
    padding: 10px 0;
    padding-left: 30px;
    position: relative;
    display: block;
  }
  .header-top .top-nav .header-top-notification .notification-card .notification-body .notification-list i {
    position: absolute;
    top: 14px;
    left: 0;
    color: rgba(1, 209, 61, 0.5);
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
  }
  .header-top .top-nav .header-top-notification .notification-card .notification-body .notification-list p {
    margin-bottom: 0;
    color: #6f7484;
    line-height: 1.3;
  }
  .header-top .top-nav .header-top-notification .notification-card .notification-body .notification-list p span {
    font-size: 1.4rem;
    font-style: normal;
    color: #246df8;
  }
  .header-top .top-nav .header-top-notification .notification-card .notification-body .notification-list span {
    color: #aaafcb;
    font-size: 1.3rem;
    font-style: italic;
  }
  .header-top .top-nav .header-top-notification .notification-card .notification-body .notification-list:hover i {
    color: #01d13d;
  }
  .header-top .top-nav .header-top-notification .notification-card .notification-footer {
    text-align: center;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
  }
  .header-top .top-nav .header-top-notification .notification-card .notification-footer a {
    padding: 10px 20px;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    font-size: 1.3rem;
    color: #aaa;
  }
  .header-top .top-nav .header-top-notification .notification-card .notification-footer a:hover {
    color: #246df8;
  }
  .header-top .top-nav .login-modals {
    margin-right: 8px;
  }
  .header-top .top-nav .login-modals button {
    margin-right: 20px;
  }
  .header-top .header-top-account .account-button {
    padding-right: 20px;
    padding-left: 7px;
  }
  .cp-nav-2 .navbar-collapse .navbar-nav > li.dropdown a {
    padding-right: 13px;
  }
  .cp-nav-2 .navbar-collapse .navbar-nav > li.dropdown a:after {
    position: absolute;
    top: 12px;
    right: 0;
    color: #aaa;
  }
  .cp-nav-2 .navbar-collapse .navbar-nav li.dropdown .dropdown-menu {
    display: block;
    visibility: hidden;
    opacity: 0;
    top: 100%;
    left: 0;
  }
  .cp-nav-2 .navbar-collapse .navbar-nav li.dropdown .dropdown-menu .dropdown-menu {
    top: 0;
    left: 100%;
    right: auto;
  }
  .cp-nav-2 .navbar-collapse .navbar-nav li.dropdown .dropdown-menu .dropdown-menu .dropdown-menu .dropdown-menu {
    left: auto;
    right: 100%;
  }
  .cp-nav-2 .navbar-collapse .navbar-nav li.dropdown .dropdown-menu .dropdown a:after {
    right: 20px;
    top: 17px;
  }
  .cp-nav-2 .navbar-collapse .navbar-nav li.dropdown:hover > .dropdown-menu {
    visibility: visible;
    opacity: 1;
  }
  .cp-nav-2 .navbar-collapse .navbar-nav li.dropdown .dropdown-menu {
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    min-width: 210px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 3px;
  }
  .cp-nav-2 .navbar-collapse .navbar-nav li.dropdown .dropdown-menu li {
    margin-right: 0;
    margin-left: 0;
  }
  .cp-nav-2 .navbar-collapse .navbar-nav li.dropdown .dropdown-menu li:last-child {
    border-bottom: 0;
  }
  .cp-nav-2 .navbar-collapse .navbar-nav li.dropdown .dropdown-menu li a {
    padding: 6px 20px;
    display: block;
    font-weight: 400;
    position: relative;
    color: black;
  }
  .cp-nav-2 .navbar-collapse .navbar-nav li.dropdown .dropdown-menu li a:hover {
    color: #246df8;
  }
  .cp-nav-2 .navbar-collapse .navbar-nav.main-nav li.dropdown .dropdown-menu {
    display: block;
    visibility: hidden;
    opacity: 0;
  }
  .cp-nav-2 .navbar-collapse .navbar-nav.main-nav li.dropdown:hover > .dropdown-menu {
    opacity: 1;
    visibility: visible;
  }
  .cp-nav-2 .navbar-collapse .navbar-nav.account-nav {
    margin-left: 20px !important;
  }
  .cp-nav-2 .navbar-collapse .navbar-nav.account-nav li {
    margin-left: 20px;
  }
  .cp-nav-2 .navbar-collapse .navbar-nav.account-nav li svg {
    height: 20px;
    width: 20px;
  }
  .cp-nav-2 .navbar-collapse .navbar-nav.account-nav li:first-child {
    margin-left: 0;
  }
  .cp-nav-2 .navbar-collapse .navbar-nav.account-nav li.dropdown .dropdown-menu {
    left: auto;
    right: 0;
  }
  .cp-nav-2 .navbar-collapse .navbar-nav.account-nav li.nav-notification a {
    padding-right: 0;
  }
  .cp-nav-2 .navbar-collapse .navbar-nav.account-nav li.nav-notification a:after {
    content: '';
    height: 0;
    width: 0;
    border-top: 0;
    border-bottom: 0;
    border-radius: 0;
    border-left: 0;
  }
  .cp-nav-2 .navbar-collapse .navbar-nav.job-browse {
    margin-left: 20px;
  }
  .cp-nav-2 .navbar-collapse .navbar-nav.job-browse li a.dropdown-toggle:hover {
    color: #ffffff;
  }
}

@media all and (max-width: 1199px) {
  .header-2 .fluid-nav {
    padding: 0 15px;
  }
  .cp-nav .navbar-collapse .navbar-nav.account-nav .header-top-notification .notification-card {
    top: 40px;
  }
  .cp-nav .navbar-collapse .navbar-nav.account-nav .header-top-notification .notification-button {
    margin-top: 15px;
    margin-bottom: 5px;
  }
  .cp-nav .navbar-collapse .navbar-nav.account-nav .header-top-notification .notification-button:before {
    color: #6f7484;
    top: 0;
  }
  .cp-nav .navbar-collapse .navbar-nav.account-nav .header-top-notification .notification-button:after {
    background: #6f7484;
    top: 0;
  }
  .cp-nav .navbar-toggler {
    border: 1px solid #ffffff;
    border-radius: 0;
    font-size: 2.2rem;
    padding: 5px 10px;
    outline: none;
    background: #ffffff;
  }
  .cp-nav .navbar-toggler .navbar-toggler-icon {
    color: #246df8;
    background-image: url();
    height: auto;
    width: auto;
  }
  .cp-nav .navbar-toggler .navbar-toggler-icon:before {
    content: "\e68e";
    font-family: 'themify';
  }
  .cp-nav .navbar-toggler.collapsed {
    background: transparent;
  }
  .cp-nav .navbar-toggler.collapsed .navbar-toggler-icon {
    color: #ffffff;
  }
  .cp-nav .navbar-collapse {
    background: #ffffff;
  }
  .cp-nav .navbar-collapse .navbar-nav {
    padding: 0 20px;
    display: block;
  }
  .cp-nav .navbar-collapse .navbar-nav li {
    margin-left: 0;
    display: block;
  }
  .cp-nav .navbar-collapse .navbar-nav li a {
    color: #6f7484;
    padding: 7px 0;
    display: block;
  }
  .cp-nav .navbar-collapse .navbar-nav li.dropdown a {
    position: relative;
  }
  .cp-nav .navbar-collapse .navbar-nav li.dropdown a:after {
    position: absolute;
    top: 18px;
    right: 0;
  }
  .cp-nav .navbar-collapse .navbar-nav li.dropdown .dropdown-menu {
    padding: 0 20px;
    margin-bottom: 15px;
  }
  .cp-nav .navbar-collapse .navbar-nav li.post-job a {
    display: inline-block;
    background: #246df8;
    color: #ffffff;
    margin-top: 15px;
  }
  .cp-nav .navbar-collapse .navbar-nav.job-browse {
    padding-top: 10px;
  }
  .cp-nav .navbar-collapse .navbar-nav.job-browse .dropdown-menu .search-by {
    padding: 20px 0;
  }
  .cp-nav .navbar-collapse .navbar-nav.job-browse .dropdown-menu .search-by h5 {
    font-size: 1.4rem;
    margin-bottom: 20px;
    font-weight: 500;
  }
  .cp-nav .navbar-collapse .navbar-nav.job-browse .dropdown-menu .search-by ul {
    padding-left: 0;
  }
  .cp-nav .navbar-collapse .navbar-nav.account-nav {
    padding-bottom: 10px;
  }
  .cp-nav .navbar-collapse .navbar-nav.account-nav li.dropdown > a {
    display: inline-block;
    padding-right: 10px;
  }
  .cp-nav .navbar-collapse .navbar-nav.account-nav li.dropdown > a:after {
    top: 18px;
  }
  .cp-nav .navbar-collapse .navbar-nav.account-nav li.dropdown .notification-card {
    left: 0;
    right: auto;
  }
  .cp-nav .navbar-collapse .navbar-nav.account-nav .login-popup button {
    background: transparent;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
    padding: 7px 0;
    color: #6f7484;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    cursor: pointer;
  }
}

@media all and (max-width: 991px) {
  .header-top {
    padding: 10px 0;
    height: 60px;
    position: relative;
  }
  .header-top .logo-area a img {
    height: 40px;
  }
  .header-top .header-top-toggler {
    display: block;
  }
  .header-top .header-top-toggler .header-top-toggler-button {
    position: relative;
    font-size: 2.2rem;
    line-height: 33px;
  }
  .header-top .header-top-toggler .header-top-toggler-button:before {
    content: "\f142";
    font-family: 'Font Awesome\ 5 Free';
    color: #6f7484;
    font-weight: 900;
  }
  .header-top .top-nav {
    display: none;
    position: absolute;
    top: 60px;
    right: 0;
    background: #ffffff;
    width: 150px;
    z-index: 99;
    padding: 20px;
    border-radius: 3px;
    -webkit-box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.08);
  }
  .header-top .top-nav .dropdown {
    display: block;
  }
  .header-top .top-nav .notification-button {
    font-size: 1.3rem;
    color: #6f7484;
  }
  .header-top .top-nav .notification-card {
    display: none;
    position: absolute;
    top: 25px;
    right: calc(100% + 20px);
    width: 315px;
    background: #ffffff;
    border-radius: 3px;
    -webkit-box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.08);
    z-index: 999;
  }
  .header-top .top-nav .notification-card .notification-head {
    padding: 10px 20px;
    font-size: 1.4rem;
    font-family: "Roboto", sans-serif;
    color: #6f7484;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  }
  .header-top .top-nav .notification-card .notification-head a {
    padding: 0;
    color: #aaa;
  }
  .header-top .top-nav .notification-card .notification-head a:hover {
    color: #246df8;
  }
  .header-top .top-nav .notification-card .notification-body {
    padding: 20px;
    max-height: 320px;
    overflow-x: hidden;
    overflow-y: scroll;
    /* Set Scrollbar Width */
    /* Set Scrollbar Buttons */
    /* Set Scrollbar Track */
    /* Set Scrollbar Handle */
  }
  .header-top .top-nav .notification-card .notification-body::-webkit-scrollbar {
    width: 8px;
    background: #f3f3f3;
    right: 20px;
    border-radius: 5px;
  }
  .header-top .top-nav .notification-card .notification-body::-webkit-scrollbar-track {
    border-radius: 5px;
  }
  .header-top .top-nav .notification-card .notification-body::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #dbe0e9;
  }
  .header-top .top-nav .notification-card .notification-body .notification-list {
    padding: 10px 0;
    padding-left: 30px;
    position: relative;
    display: block;
  }
  .header-top .top-nav .notification-card .notification-body .notification-list i {
    position: absolute;
    top: 14px;
    left: 0;
    color: rgba(1, 209, 61, 0.5);
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
  }
  .header-top .top-nav .notification-card .notification-body .notification-list p {
    margin-bottom: 0;
    color: #6f7484;
    line-height: 1.3;
  }
  .header-top .top-nav .notification-card .notification-body .notification-list p span {
    font-size: 1.4rem;
    font-style: normal;
    color: #246df8;
  }
  .header-top .top-nav .notification-card .notification-body .notification-list span {
    color: #aaafcb;
    font-size: 1.3rem;
    font-style: italic;
  }
  .header-top .top-nav .notification-card .notification-body .notification-list:hover i {
    color: #01d13d;
  }
  .header-top .top-nav .notification-card .notification-footer {
    text-align: center;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
  }
  .header-top .top-nav .notification-card .notification-footer a {
    padding: 10px 20px;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    font-size: 1.3rem;
    color: #aaa;
  }
  .header-top .top-nav .notification-card .notification-footer a:hover {
    color: #246df8;
  }
  .header-top .top-nav .header-top-account button {
    margin-top: 5px;
  }
  .header-top .top-nav .header-top-account button:before {
    margin-right: 0;
  }
  .header-top .top-nav .select-language .dropdown-toggle {
    margin-top: 10px;
    padding: 0;
    text-align: left;
  }
  .header-top .top-nav .select-language .btn-light {
    color: #aaa;
  }
  .cp-nav-2 {
    position: relative;
    padding: 0;
  }
  .cp-nav-2 .navbar-toggler {
    border: 1px solid rgba(36, 109, 248, 0.4);
    border-radius: 0;
    font-size: 2.2rem;
    padding: 5px 10px;
    outline: none;
    background: #ffffff;
    position: absolute;
    top: -49px;
    right: 30px;
  }
  .cp-nav-2 .navbar-toggler .navbar-toggler-icon {
    color: #246df8;
    background-image: url();
    height: auto;
    width: auto;
  }
  .cp-nav-2 .navbar-toggler .navbar-toggler-icon:before {
    content: "\e68e";
    font-family: 'themify';
  }
  .cp-nav-2 .navbar-collapse {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background: #ffffff;
  }
  .cp-nav-2 .navbar-collapse .navbar-nav {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 20px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.05) 0px 15px 40px 0px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 15px 40px 0px;
  }
  .cp-nav-2 .navbar-collapse .navbar-nav li {
    display: block;
    width: 100%;
    margin-right: 0;
  }
  .cp-nav-2 .navbar-collapse .navbar-nav li a {
    padding: 5px 0;
    display: block;
  }
  .cp-nav-2 .navbar-collapse .navbar-nav li a.dropdown-toggle {
    position: relative;
  }
  .cp-nav-2 .navbar-collapse .navbar-nav li a.dropdown-toggle:after {
    position: absolute;
    top: 15px;
    right: 0;
  }
  .cp-nav-2 .navbar-collapse .navbar-nav li.post-job {
    margin-top: 10px;
    display: inline-block;
    width: auto;
    margin-right: 0;
    margin-left: 0;
  }
  .cp-nav-2 .navbar-collapse .navbar-nav li .dropdown-menu {
    padding: 15px;
  }
  .cp-nav-2 .navbar-collapse .navbar-nav .menu-item.post-job {
    margin-right: 0;
    margin-left: 0;
  }
}

@media all and (max-width: 499px) {
  .header-top .top-nav .notification-card {
    right: -20px;
  }
}

@media all and (max-width: 439px) {
  .cp-nav .navbar-collapse .navbar-nav {
    padding: 0 15px;
  }
  .cp-nav .navbar-collapse .navbar-nav li.dropdown .dropdown-menu {
    padding: 0 15px;
  }
  .cp-nav .navbar-collapse .navbar-nav.account-nav li.dropdown.header-top-notification .notification-card {
    top: 40px;
    left: -15px;
    width: 290px;
  }
  .header-top .top-nav .notification-card {
    width: 290px;
  }
  .account-entry .modal-body .shortcut-login .buttons {
    display: block;
  }
  .account-entry .modal-body .shortcut-login .buttons a {
    width: 100%;
  }
  .account-entry .modal-body .shortcut-login .buttons a.facebook {
    margin-right: 0;
  }
  .account-entry .modal-body .shortcut-login .buttons a + a {
    margin-top: 15px;
  }
}

/*---------------------------------------------
  13.0 Animated-number Style
---------------------------------------------*/
.fact-bg {
  background: url(../images/bg/fact-bg.jpg) no-repeat center center;
  background-size: cover;
}

.fact {
  text-align: center;
  color: #ffffff;
  margin-bottom: 30px;
}

.fact .fact-icon svg {
  height: 35px;
}

.fact .fact-number {
  font-size: 30px;
  margin-top: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  margin-bottom: 0;
  line-height: 1;
}

.fact .fact-name {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  margin-top: 0;
}

/*---------------------------------------------
  14.0 Call-to-action Style
---------------------------------------------*/
.call-to-action-box {
  padding: 40px 20px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(36, 109, 248, 0.1)), to(rgba(36, 109, 248, 0)));
  background-image: -webkit-linear-gradient(top, rgba(36, 109, 248, 0.1), rgba(36, 109, 248, 0));
  background-image: -o-linear-gradient(top, rgba(36, 109, 248, 0.1), rgba(36, 109, 248, 0));
  background-image: linear-gradient(to bottom, rgba(36, 109, 248, 0.1), rgba(36, 109, 248, 0));
  padding-left: 45%;
  position: relative;
}

.call-to-action-box .icon {
  position: absolute;
  bottom: 0px;
  left: 40px;
}

.call-to-action-box span {
  font-size: 1.5rem;
  font-weight: 400;
  color: #6e7383;
  text-transform: uppercase;
}

.call-to-action-box h3 {
  font-size: 2.6rem;
  font-weight: 500;
  color: #101725;
}

.call-to-action-box a {
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-top: 40px;
}

.call-to-action-box a i {
  opacity: 0;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  position: relative;
  -webkit-transform: translateX(-10px);
  -ms-transform: translateX(-10px);
  transform: translateX(-10px);
  margin-left: 5px;
}

.call-to-action-box a:hover i {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.call-to-action-box.candidate-box a {
  color: #35c38f;
}

.call-to-action-box.employer-register a {
  color: #246df8;
}

.call-to-action-bg {
  background: url(../images/bg/cta-2-bg.jpg) no-repeat center;
  background-size: cover;
}

.call-to-action-2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.call-to-action-2 .call-to-action-content {
  color: #ffffff;
}

.call-to-action-2 .call-to-action-content h2 {
  font-size: 2.6rem;
  margin-bottom: 20px;
  font-weight: 500;
}

.call-to-action-2 .call-to-action-content p {
  max-width: 450px;
}

.call-to-action-2 .call-to-action-button .button {
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 3px;
  background: #023b92;
  padding: 15px 30px;
  color: #ffffff;
  font-size: 1.4rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.call-to-action-2 .call-to-action-button .button:before {
  content: '\f067';
  font-family: 'Font Awesome\ 5 Free';
  font-weight: 900;
  margin-right: 10px;
}

.call-to-action-2 .call-to-action-button .button:hover {
  background: #ffffff;
  border-color: #ffffff;
  color: #246df8;
}

.call-to-action-2 .call-to-action-button span {
  margin: 0 10px;
  color: #ffffff;
}

.newsletter-bg {
  background: url(../images/bg/newsletter-bg.jpg) no-repeat center center;
  background-size: cover;
}

.newsletter-wrap {
  color: #ffffff;
}

.newsletter-wrap h3 {
  font-size: 2.6rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  margin-bottom: 10px;
}

.newsletter-wrap p {
  max-width: 350px;
}

.newsletter-form {
  margin-top: 30px;
  max-width: 450px;
}

.newsletter-form .form-group {
  width: calc(100% - 150px);
  margin-right: 30px;
}

.newsletter-form .form-group .form-control {
  height: 60px;
  border: 0;
  padding: 0;
  width: 100%;
  border-radius: 0;
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  background: transparent;
  outline: none;
  color: #ffffff;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 1.4rem;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.newsletter-form .form-group .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #ffffff;
}

.newsletter-form .form-group .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #ffffff;
}

.newsletter-form .form-group .form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: #ffffff;
}

.newsletter-form .form-group .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #ffffff;
}

.newsletter-form .form-group .form-control:focus {
  border-bottom-color: #ffffff;
}

.newsletter-form .button {
  padding: 17px 25px;
  background: rgba(255, 255, 255, 0.1);
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1.4rem;
}

.newsletter-form .button i {
  margin-left: 10px;
}

.newsletter-form .button:hover {
  background: #ffffff;
  border-color: #ffffff;
  color: #246df8;
}

@media all and (max-width: 991px) {
  .call-to-action-box.employer-register {
    margin-top: 30px;
  }
  .call-to-action-2 .call-to-action-content {
    margin-bottom: 30px;
  }
}

@media all and (max-width: 479px) {
  .call-to-action-box {
    padding-left: 20px;
  }
  .call-to-action-box .icon {
    position: static;
    margin-bottom: 30px;
  }
}

@media all and (max-width: 439px) {
  .newsletter-wrap .newsletter-form .form-group {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .call-to-action-2 .call-to-action-button span {
    display: block;
    margin: 10px 0;
  }
}

/*---------------------------------------------
  15.0 Testimonial Style
---------------------------------------------*/
.testimonial {
  text-align: center;
}

.testimonial .testimonial-quote {
  margin-bottom: 40px;
}

.testimonial .testimonial-for .testimonial-item {
  padding-bottom: 60px;
  outline: none;
}

.testimonial .testimonial-for .testimonial-item p {
  font-size: 2.0rem;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  max-width: 700px;
  margin: 0 auto;
}

.testimonial .testimonial-for .testimonial-item h5 {
  font-size: 1.4rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #246df8;
  text-transform: uppercase;
  margin-top: 30px;
}

.testimonial .testimonial-nav {
  max-width: 500px;
  margin: 0 auto;
}

.testimonial .testimonial-nav .commenter-thumb {
  text-align: center;
  outline: none;
}

.testimonial .testimonial-nav .commenter-thumb .commenter {
  padding: 0 20px;
  border-radius: 50%;
}

.testimonial .testimonial-nav .commenter-thumb .comapnyLogo {
  display: inline-block;
  margin-top: 15px;
}

.testimonial .testimonial-nav .commenter-thumb.slick-active {
  opacity: .3;
  cursor: pointer;
}

.testimonial .testimonial-nav .commenter-thumb.slick-active.slick-current {
  opacity: 1;
}

/*---------------------------------------------
  16.0 Advice Style
---------------------------------------------*/
.advice {
  padding: 30px;
  padding-bottom: 0;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  margin-bottom: 30px;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.advice .body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.advice .body .date .day {
  font-size: 2.4rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  line-height: 1;
}

.advice .body .date .month {
  font-size: 1.4rem;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: #246df8;
  text-transform: uppercase;
}

.advice .body .content {
  padding-left: 20px;
}

.advice .body .content h4 {
  font-size: 1.6rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  margin-bottom: 15px;
}

.advice .body .content p {
  color: #6f7484;
}

.advice .info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 15px 0;
}

.advice .info a {
  color: #6f7484;
}

.advice .info a svg {
  height: 16px;
  margin-right: 2px;
}

.advice .info a:hover {
  color: #246df8;
}

.advice:hover {
  -webkit-box-shadow: 0px 15px 40px 0px rgba(237, 237, 237, 0.4);
  box-shadow: 0px 15px 40px 0px rgba(237, 237, 237, 0.4);
}

.advice:hover .body .content h4 {
  color: #246df8;
}

/*---------------------------------------------
  17.0 Pricing Style
---------------------------------------------*/
.pricing-controller {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 50px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.pricing-controller .switch-wrap {
  background: rgba(36, 109, 248, 0.1);
  height: 26px;
  width: 50px;
  border-radius: 30px;
  position: relative;
  margin: 0 15px;
  cursor: pointer;
}

.pricing-controller .switch-wrap .price-switch {
  background: #19bb4c;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 3px;
  left: 3px;
  -webkit-transition: all .6s ease;
  -o-transition: all .6s ease;
  transition: all .6s ease;
}

.pricing-controller .switch-wrap .price-switch.year-active {
  left: 27px;
}

.pricing-controller .duration {
  font-size: 1.6rem;
  font-weight: 500;
  color: #aaa;
}

.pricing-controller .duration.active {
  color: #101725;
}

.pricing-controller .duration span {
  color: #19bb4c;
  font-size: 1.2rem;
  text-transform: uppercase;
  padding: 2px 4px;
  border-radius: 3px;
}

.package-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.package-wrapper .package {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.package-wrapper .package .package-header {
  padding: 40px;
  background: #246df8;
  color: #ffffff;
}

.package-wrapper .package .package-header .cost {
  font-size: 2.6rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.package-wrapper .package .package-header .cost span {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
  margin-left: 5px;
}

.package-wrapper .package .package-header .cost.hidden {
  display: none;
  visibility: hidden;
}

.package-wrapper .package .package-header .title {
  font-size: 1.6rem;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  letter-spacing: 3px;
  margin-top: 5px;
  display: block;
}

.package-wrapper .package.professional .package-header {
  background: url(../images/bg/package-header-bg.jpg) no-repeat center;
  background-size: cover;
}

.package-wrapper .package .package-features {
  padding: 40px;
  padding-bottom: 0;
}

.package-wrapper .package .package-features ul {
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 0;
}

.package-wrapper .package .package-features ul li {
  font-size: 1.4rem;
  color: #bdbdbd;
  position: relative;
  padding-left: 30px;
}

.package-wrapper .package .package-features ul li.active {
  color: #6f7484;
}

.package-wrapper .package .package-features ul li:before {
  content: '\f058';
  font-family: 'Font Awesome\ 5 Free';
  font-weight: 900;
  position: absolute;
  font-size: 1.2rem;
  top: 2px;
  left: 0;
}

.package-wrapper .package .package-features ul li.active:before {
  color: #19bb4c;
}

.package-wrapper .package .package-features ul li + li {
  margin-top: 10px;
}

.package-wrapper .package .package-features .button {
  margin-top: 30px;
  background: #246df8;
  padding: 11px 25px;
  color: #ffffff;
}

@media all and (max-width: 767px) {
  .package-wrapper {
    display: block;
  }
  .package-wrapper .package + .package {
    margin-top: 40px;
  }
}

/*---------------------------------------------
  18.0 Progress-bar Style
---------------------------------------------*/
.professonal-skill .progress-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.professonal-skill .progress-group .progress-item {
  width: calc(50% - 15px);
  margin-top: 10px;
}

.professonal-skill .progress-group .progress-item .progress-body {
  position: relative;
}

.professonal-skill .progress-group .progress-item .progress-body .progress {
  border-radius: 0;
  background: #f0f5fb;
  height: 5px;
}

.professonal-skill .progress-group .progress-item .progress-body .progress .progress-bar {
  background: #023b92;
}

.professonal-skill .progress-group .progress-item .progress-body .progress-to {
  position: absolute;
  top: -25px;
  right: 0;
}

.professonal-skill .progress-group .progress-item:nth-child(2n) {
  flex-break: before;
}

@media all and (max-width: 767px) {
  .professonal-skill .progress-group {
    display: block;
  }
  .professonal-skill .progress-group .progress-item {
    width: 100%;
    margin-top: 20px;
  }
}

/*----------------------------------------------
  19.0 Pagination Style
-----------------------------------------------*/
.pagination-list .pagination {
  padding: 40px 0 30px;
  margin: 0;
  display: block;
  font-size: 0;
}

.pagination-list .pagination.navigation {
  margin-top: 10px;
}

.pagination-list .pagination.navigation.padding-top-60 {
  margin-top: 0;
}

.pagination-list .pagination .nav-links .page-numbers {
  height: 35px;
  width: 35px;
  line-height: 35px;
  border-radius: 3px;
  text-align: center;
  font-size: 1.5rem;
  display: inline-block;
  margin: 0 5px;
  font-weight: 400;
  color: #6f7484;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  position: relative;
}

.pagination-list .pagination .nav-links .page-numbers:hover {
  color: #246df8;
}

.pagination-list .pagination .nav-links .page-numbers:last-child:before {
  border-right: 1px solid rgba(0, 0, 0, 0.08);
}

.pagination-list .pagination .nav-links .page-numbers.current {
  color: #ffffff;
  background: #023b92;
  border-color: #023b92;
}

.pagination-list .pagination .next,
.pagination-list .pagination .prev {
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: #f9fbfe;
}

@media all and (max-width: 767px) {
  .pagination-list .pagination .nav-links .page-numbers {
    height: 32px;
    width: 32px;
    line-height: 32px;
    margin: 0 3px;
  }
}

/*---------------------------------------------
  20.0 Footer Style
---------------------------------------------*/
.footer-bg {
  background: #ffffff;
}

.footer-social {
  text-align: right;
}

.footer-social .social-icons {
  padding: 0;
  margin: 12px 0 0;
  list-style: none;
  display: inline-block;
}

.footer-social .social-icons li {
  display: inline-block;
  padding-left: 25px;
}

.footer-social .social-icons li a {
  color: rgba(111, 116, 132, 0.5);
}

.footer-social .social-icons li a:hover {
  color: #6f7484;
}

.footer-social .social-icons li:first-child {
  padding-left: 0;
}

.footer-social .select-language {
  margin-left: 20px;
  padding-left: 20px;
  border-left: 1px solid rgba(0, 0, 0, 0.08);
}

.footer-social .select-language.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  width: 60px;
  outline: none !important;
}

.footer-social .select-language.bootstrap-select .dropdown-toggle:focus {
  outline: none !important;
}

.footer-social .select-language.bootstrap-select .dropdown-menu li a {
  font-size: 1.4rem;
}

.footer-social .select-language .btn-light {
  background: transparent;
  padding: 5px 0;
  border: 0;
  font-size: 1.5rem;
}

.footer-widget {
  margin-bottom: 40px;
}

.footer-widget h4 {
  font-size: 1.8rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-bottom: 35px;
}

.footer-widget.widget-about .description {
  font-size: 1.4rem;
  margin-bottom: 20px;
  color: #6f7484;
}

.footer-widget.widget-about .about-contact {
  display: block;
  color: #6f7484;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
}

.footer-widget.widget-about .about-contact svg {
  height: 15px;
  margin-right: 15px;
}

.footer-widget.footer-shortcut-link ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.footer-widget.footer-shortcut-link ul li + li {
  margin-top: 10px;
}

.footer-widget.footer-shortcut-link ul li {
  color: #6f7484;
}

.footer-widget.footer-shortcut-link ul li:hover {
  color: #246df8;
}

.footer-widget.footer-newsletter p {
  font-size: 1.4rem;
  color: #6f7484;
  max-width: 300px;
}

.footer-widget.footer-newsletter .newsletter-form {
  position: relative;
}

.footer-widget.footer-newsletter .newsletter-form .form-group .form-control {
  border-bottom-color: rgba(36, 109, 248, 0.4);
  font-family: "Poppins", sans-serif;
  color: #6f7484;
}

.footer-widget.footer-newsletter .newsletter-form .form-group .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #6f7484;
}

.footer-widget.footer-newsletter .newsletter-form .form-group .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #6f7484;
}

.footer-widget.footer-newsletter .newsletter-form .form-group .form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: #6f7484;
}

.footer-widget.footer-newsletter .newsletter-form .form-group .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #6f7484;
}

.footer-widget.footer-newsletter .newsletter-form .form-group .form-control:focus {
  border-bottom-color: #246df8;
}

.footer-widget.footer-newsletter .newsletter-form .button {
  background: #023b92;
  border-color: #023b92;
}

.footer-widget.footer-newsletter .newsletter-form .button:hover {
  color: #ffffff;
}

.footer-widget.footer-newsletter .newsletter-form .button:focus {
  outline: none;
}

.footer-bottom {
  padding: 40px 0;
}

.footer-bottom .copyright-text {
  color: #6f7484;
  padding-top: 15px;
}

.footer-bottom .copyright-text a:hover {
  color: #246df8;
}

.footer-bottom .footer-app-download {
  text-align: center;
}

.footer-bottom .footer-app-download a {
  border: 2px solid rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  padding: 12px 15px 12px 45px;
  margin: 0 5px;
  font-size: 1.5rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #101725;
  position: relative;
}

.footer-bottom .footer-app-download a:before {
  position: absolute;
  top: 11px;
  left: 15px;
}

.footer-bottom .footer-app-download a:hover {
  color: #246df8;
  border-color: #246df8;
}

.footer-bottom .footer-app-download .apple-app:before {
  content: url(../images/apple.png);
}

.footer-bottom .footer-app-download .android-app:before {
  content: url(../images/google.png);
}

.footer-bottom .back-to-top {
  text-align: right;
}

.footer-bottom .back-to-top a {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 1.3rem;
  text-transform: uppercase;
  color: #6f7484;
}

.footer-bottom .back-to-top a i {
  height: 55px;
  width: 55px;
  font-size: 2.2rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 50%;
  background: #023b92;
  color: white;
  line-height: 55px;
  text-align: center;
  margin-left: 15px;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.footer-bottom .back-to-top a:hover {
  color: #246df8;
}

.footer-bottom .back-to-top a:hover i {
  border-color: #246df8;
  color: #246df8;
}

.slim-footer {
  padding: 47px 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.slim-footer p {
  margin-bottom: 0;
  color: #6f7484;
}

.slim-footer p a:hover {
  color: #246df8;
}

.slim-footer .footer-social-link ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.slim-footer .footer-social-link ul li {
  display: inline-block;
  margin-right: 25px;
}

.slim-footer .footer-social-link ul li a {
  color: rgba(111, 116, 132, 0.5);
}

.slim-footer .footer-social-link ul li a:hover {
  color: #6f7484;
}

.slim-footer .footer-social-link ul li:last-child {
  margin-right: 0;
}

@media all and (min-width: 1200px) {
  .slim-footer {
    width: 1040px;
  }
}

@media (max-width: 1199px) and (min-width: 992px) {
  .footer-bottom .footer-app-download {
    text-align: left;
  }
}

@media all and (max-width: 991px) {
  .footer-widget h4 {
    margin-bottom: 20px;
  }
  .footer-bottom {
    padding: 40px 0;
  }
  .footer-bottom .copyright-text {
    padding-top: 25px;
    text-align: center;
  }
  .footer-bottom .back-to-top {
    text-align: center;
    margin-top: 20px;
  }
}

@media all and (max-width: 767px) {
  .footer-logo {
    text-align: center;
    margin-bottom: 25px;
  }
  .footer-social {
    text-align: center;
  }
  .footer-social .social-icons li {
    padding-left: 10px;
  }
  .footer-widget.footer-newsletter .newsletter-form .form-group {
    width: 100%;
    display: block;
    margin-bottom: 20px;
    margin-right: 0;
    max-width: 350px;
  }
  .slim-footer {
    display: block;
    text-align: center;
  }
  .slim-footer .footer-social-link {
    margin-top: 20px;
  }
}

@media all and (max-width: 379px) {
  .footer-bottom .footer-app-download {
    text-align: center;
  }
  .footer-bottom .footer-app-download a {
    display: block;
    width: 160px;
    margin: 0 auto;
  }
  .footer-bottom .footer-app-download a + a {
    margin-top: 15px;
  }
}

/*---------------------------------------------
  21.0 Employer Style
---------------------------------------------*/
.employer-filter-result.grid {
  position: relative;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
}

.employer-filter-result.grid:before {
  position: absolute;
  top: 30px;
  left: 50%;
  bottom: 0;
  width: 1px;
  background: rgba(0, 0, 0, 0.08);
  content: '';
}

.employer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 30px 20px;
  border-radius: 3px;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.employer .thumb {
  width: 80px;
  height: 80px;
  background: #f9f9f9;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.employer .thumb a {
  height: 80px;
  width: 80px;
  position: relative;
}

.employer .thumb a img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.employer .thumb + .body {
  padding-left: 20px;
}

.employer .body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: calc(100% - 80px);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.employer .body .content h4 {
  font-size: 1.8rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  margin-bottom: 12px;
}

.employer .body .content h4:hover {
  color: #246df8;
}

.employer .body .content .info span {
  color: #6f7484;
  margin-right: 15px;
}

.employer .body .content .info span.work-post svg {
  color: rgba(36, 109, 248, 0.4);
}

.employer .body .content .info span.location svg {
  color: #6f7484;
}

.employer .body .content .info span svg {
  height: 15px;
  color: #aaa;
}

.employer .body .button-area a {
  font-size: 1.4rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  padding: 8px 15px;
  color: #246df8;
  border-radius: 3px;
  background: rgba(36, 109, 248, 0.15);
}

.employer:hover {
  border-bottom-color: transparent;
  -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);
}

.employer:hover .body .button-area a {
  background: #246df8;
  border-color: #246df8;
  color: #ffffff;
}

.employer.half-grid {
  width: calc(50% - 30px);
}

.employer.half-grid .body {
  display: block;
  margin-top: 0;
}

.employer.half-grid .body .content span {
  display: inline-block;
  margin-bottom: 8px;
}

.employer.half-grid .body .button-area {
  margin-top: 10px;
}

/*---------------------------------------------
  Employer Details
---------------------------------------------*/
.company-details {
  background: #ffffff;
  padding: 30px;
  -webkit-box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.03);
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.03);
}

.company-details .title-and-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.company-details .title-and-info .title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.company-details .title-and-info .title .thumb {
  width: 100px;
  height: 100px;
  background: #f9f9f9;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  overflow: hidden;
  position: relative;
}

.company-details .title-and-info .title .thumb img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.company-details .title-and-info .title .thumb + .title-body {
  padding-left: 20px;
}

.company-details .title-and-info .title .title-body h4 {
  font-size: 2.6rem;
  font-weight: 600;
  padding-bottom: 15px;
}

.company-details .title-and-info .title .title-body .info span {
  font-size: 1.4rem;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  margin-right: 15px;
  color: #6f7484;
}

.company-details .title-and-info .title .title-body .info span svg {
  height: 15px;
  margin-right: 5px;
  color: #aaa;
}

.company-details .title-and-info .download-resume a {
  padding: 15px 30px;
  border-radius: 3px;
  background: #023b92;
  color: #ffffff;
  font-size: 1.4rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.company-details .title-and-info .download-resume a svg {
  height: 18px;
  margin-left: 10px;
}

.company-details .title-and-info .download-resume a:hover {
  color: #ffffff;
  background: #246df8;
}

.company-details .title-and-info .download-resume .save-btn {
  text-align: center;
  background: transparent;
  margin-right: 10px;
  color: #6f7484;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(247, 249, 252, 0)), to(#f7f9fc));
  background-image: -webkit-linear-gradient(right, rgba(247, 249, 252, 0), #f7f9fc);
  background-image: -o-linear-gradient(right, rgba(247, 249, 252, 0), #f7f9fc);
  background-image: linear-gradient(to left, rgba(247, 249, 252, 0), #f7f9fc);
}

.company-details .title-and-info .download-resume .save-btn svg {
  margin-left: 0;
  margin-right: 7px;
}

.company-details .title-and-info .download-resume .save-btn:hover {
  background: transparent;
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(247, 249, 252, 0)), to(#f7f9fc));
  background-image: -webkit-linear-gradient(right, rgba(247, 249, 252, 0), #f7f9fc);
  background-image: -o-linear-gradient(right, rgba(247, 249, 252, 0), #f7f9fc);
  background-image: linear-gradient(to left, rgba(247, 249, 252, 0), #f7f9fc);
  border-color: #ff8fa6;
  color: #ff8fa6;
}

.open-job .job-list {
  padding: 30px 20px;
}

.open-job .job-list .body {
  padding-left: 0;
  width: 100%;
}

.open-job .job-list .body .content h4 {
  margin-bottom: 0;
}

.company-details .details-information {
  border-bottom: 0;
}

.information-and-contact .information ul li a {
  color: #246df8;
}

.company-details .job-location {
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(247, 249, 252, 0)), to(#f7f9fc));
  background-image: -webkit-linear-gradient(right, rgba(247, 249, 252, 0), #f7f9fc);
  background-image: -o-linear-gradient(right, rgba(247, 249, 252, 0), #f7f9fc);
  background-image: linear-gradient(to left, rgba(247, 249, 252, 0), #f7f9fc);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  margin-top: 50px;
  padding: 30px;
}

.company-details .job-location h4 {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 20px;
}

.company-details .job-location .cp-map {
  height: 250px;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

@media all and (max-width: 991px) {
  .company-details .title-and-info {
    display: block;
  }
  .company-details .title-and-info .download-resume {
    margin-top: 20px;
  }
  .company-details .title-and-info .download-resume a {
    margin-bottom: 10px;
  }
}

@media all and (max-width: 767px) {
  .employer .body {
    display: block;
  }
  .employer .body .button-area {
    margin-top: 20px;
  }
  .company-details {
    padding: 20px;
  }
}

@media all and (max-width: 439px) {
  .employer {
    display: block;
  }
  .employer .thumb + .body {
    padding-left: 0;
    margin-top: 15px;
  }
  .employer .body {
    width: 100%;
  }
  .company-details .title-and-info .title {
    display: block;
  }
  .company-details .title-and-info .title .thumb + .title-body {
    padding-left: 0;
  }
  .information {
    padding: 20px;
  }
  .information h4 {
    margin-bottom: 20px;
  }
}

/*---------------------------------------------
  22.0 About Style
---------------------------------------------*/
.others-block {
  -webkit-box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.03);
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.03);
}

.about-company {
  padding: 60px 30px;
  background: url(../images/bg/bg-map.jpg) no-repeat center;
  background-size: cover;
}

.feature-content h3 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 2.6rem;
  margin-bottom: 30px;
}

.feature-content p {
  color: #6f7484;
}

.feature-content p + p {
  margin-top: 20px;
}

.feature-content .button {
  background: #023b92;
  color: #ffffff;
  border-radius: 3px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1.4rem;
  padding: 11px 30px;
  margin-top: 20px;
}

.infobox-wrap {
  padding: 0 30px 60px;
}

.info-box {
  position: relative;
  padding-left: 65px;
  padding-bottom: 30px;
}

.info-box:before {
  position: absolute;
  content: '';
  top: 60px;
  left: 25px;
  bottom: 30px;
  width: 2px;
  border-left: 2px dashed rgba(0, 0, 0, 0.08);
}

.info-box .icon {
  position: absolute;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  left: 0;
  top: 0;
  border: 1px solid rgba(0, 0, 0, 0.08);
}

.info-box .icon svg {
  height: 20px;
  width: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #246df8;
}

.info-box h4 {
  font-size: 1.6rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  padding-bottom: 10px;
}

.info-box p {
  color: #6f7484;
}

@media all and (max-width: 991px) {
  .feature-content {
    margin-top: 30px;
  }
}

/*---------------------------------------------
  23.0 Working Process Style
---------------------------------------------*/
.how-it-work {
  padding: 60px 30px;
}

.working-process {
  position: relative;
  overflow: hidden;
}

.working-process:before {
  position: absolute;
  content: url(../images/dot.png);
  top: 35px;
  left: 13px;
}

.working-process h3 {
  font-size: 1.8rem;
  padding-bottom: 15px;
}

.working-process h3 span {
  color: #246df8;
  margin-right: 10px;
}

.working-process p {
  padding-left: 47px;
  color: #6f7484;
}

.working-process + .working-process {
  margin-top: 60px;
}

@media all and (max-width: 991px) {
  .working-process-thumb {
    margin-bottom: 50px;
  }
}

/*---------------------------------------------
  24.0 FAQ Style
---------------------------------------------*/
.faq-tab {
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-bottom: 0;
  margin-bottom: 60px;
}

.faq-tab .nav-item {
  margin-bottom: 0;
}

.faq-tab .nav-item .nav-link {
  color: #6f7484;
  background: transparent;
  border: 0;
  padding: 10px 40px 15px;
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  position: relative;
}

.faq-tab .nav-item .nav-link:before {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  height: 2px;
  width: 100%;
  background: #ebebeb;
}

.faq-tab .nav-item .nav-link:hover, .faq-tab .nav-item .nav-link:focus {
  display: block;
}

.faq-tab .nav-item .nav-link.active {
  background: transparent;
  color: #246df8;
}

.faq-tab .nav-item .nav-link.active:before {
  background: #246df8;
}

.card {
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.card .card-header {
  padding: 0;
  border-bottom: 0;
  background: transparent;
}

.card .card-header h5 .btn-link {
  padding: 20px 30px 20px 0;
  font-size: 1.8rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #101725;
  display: block;
  width: 100%;
  text-align: left;
  position: relative;
  white-space: normal;
}

.card .card-header h5 .btn-link:before {
  position: absolute;
  top: 22px;
  right: 0;
  content: "\e622";
  font-family: 'themify';
  font-size: 1.4rem;
  height: 25px;
  width: 25px;
  line-height: 25px;
  text-align: center;
  border-radius: 50%;
  color: #6f7484;
  border: 1px solid rgba(0, 0, 0, 0.08);
}

.card .card-header h5 .btn-link.collapsed:before {
  content: "\e61a";
}

.card .card-header h5 .btn-link:hover, .card .card-header h5 .btn-link:focus {
  text-decoration: none;
}

.card .card-body {
  padding: 0;
  padding-bottom: 20px;
}

.card .card-body p {
  font-size: 1.5rem;
  color: #6f7484;
}

.card .card-body p a {
  color: #246df8;
}

.card .card-body p + p {
  margin-top: 20px;
}

.accordion .card:first-of-type,
.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.quick-contact {
  padding: 0 30px;
  text-align: center;
}

.quick-contact .icon {
  margin-bottom: 30px;
}

.quick-contact h4 {
  font-size: 2.6rem;
  font-weight: 500;
  margin-bottom: 10px;
}

.quick-contact p {
  font-size: 1.4rem;
  color: #6f7484;
}

.quick-contact .buttons {
  margin-top: 20px;
}

.quick-contact .buttons a {
  padding: 8px 25px;
  border-radius: 3px;
  font-family: "Poppins", sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  margin: 0 8px 10px;
}

.quick-contact .buttons a.email {
  background: #023b92;
  color: #ffffff;
}

.quick-contact .buttons a.chat {
  background: rgba(25, 187, 76, 0.15);
  color: #19bb4c;
}

.quick-contact .call {
  margin-top: 15px;
  font-size: 1.4rem;
}

.quick-contact .call span {
  font-size: 1.6rem;
  color: #246df8;
}

@media all and (max-width: 767px) {
  .faq-tab {
    display: block;
  }
  .faq-tab .nav-item .nav-link {
    padding: 15px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.08);
  }
  .faq-tab .nav-item .nav-link.active {
    border-color: #246df8;
  }
  .faq-tab .nav-item .nav-link:before {
    height: 0;
    width: 0;
  }
  .faq-tab .nav-item + .nav-item {
    margin-top: 15px;
  }
}

/*---------------------------------------------
  25.0 Blog Style
---------------------------------------------*/
.blog-post-wrapper {
  background: #ffffff;
  padding: 60px 30px;
  -webkit-box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.03);
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.03);
}

.blog-post-wrapper .pagination-list .pagination {
  padding-bottom: 0;
}

.blog-post-wrapper .pagination-list .pagination.padding-top-10 {
  padding-top: 10px;
}

.blog-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.blog-list .info {
  width: 220px;
}

.blog-list .info span {
  display: block;
  font-size: 1.4rem;
  color: #6f7484;
}

.blog-list .info span svg {
  height: 15px;
  width: 15px;
  margin-right: 7px;
  color: #aaa;
}

.blog-list .info span.date {
  font-size: 1.6rem;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.blog-list .info span.author {
  margin-bottom: 10px;
}

.blog-list .info span.author:hover {
  color: #246df8;
}

.blog-list .content {
  width: auto;
  max-width: 700px;
}

.blog-list .content h3 {
  font-size: 1.8rem;
  margin-bottom: 15px;
}

.blog-list .content p {
  color: #6f7484;
}

.blog-list .read-more {
  min-width: 150px;
  margin-left: auto;
  text-align: right;
}

.blog-list .read-more .button {
  background: rgba(36, 109, 248, 0.15);
  color: #246df8;
  border: 0;
  border-radius: 3px;
  padding: 10px 25px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.blog-list .read-more .button:hover {
  background: #246df8;
  color: #ffffff;
}

.blog-list + .blog-list {
  margin-top: 50px;
}

.blog-grid {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  margin-bottom: 30px;
  overflow: hidden;
}

.blog-grid .thumb {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.blog-grid .thumb img {
  width: auto;
  max-width: 100%;
  min-height: 100%;
}

.blog-grid .body {
  padding: 30px;
  padding-left: 80px;
  position: relative;
  z-index: 2;
}

.blog-grid .body:before {
  position: absolute;
  content: '';
  bottom: 0;
  left: 30px;
  right: 30px;
  height: 1px;
  background: rgba(0, 0, 0, 0.08);
}

.blog-grid .body .date {
  position: absolute;
  top: 30px;
  left: 30px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 1.3;
}

.blog-grid .body .date span {
  display: block;
  font-size: 1.4rem;
  font-family: "Roboto", sans-serif;
  color: #246df8;
  text-transform: uppercase;
}

.blog-grid .body h3 {
  font-size: 1.6rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  padding-bottom: 10px;
}

.blog-grid .body p {
  color: #6f7484;
}

.blog-grid .thumb + .body {
  color: #ffffff;
}

.blog-grid .thumb + .body:before {
  background: rgba(255, 255, 255, 0.15);
}

.blog-grid .thumb + .body p {
  color: rgba(255, 255, 255, 0.7);
}

.blog-grid .thumb + .body + .info {
  color: #ffffff;
}

.blog-grid .thumb + .body + .info .author, .blog-grid .thumb + .body + .info .comments {
  color: rgba(255, 255, 255, 0.7);
}

.blog-grid .info {
  position: relative;
  z-index: 2;
  padding: 20px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: #6f7484;
}

.blog-grid .info svg {
  height: 14px;
  width: 14px;
  margin-right: 7px;
  color: #aaa;
}

.blog-details-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 60px 30px;
}

.blog-details-wrapper .info {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
}

.blog-details-wrapper .info span {
  display: block;
  color: #6f7484;
}

.blog-details-wrapper .info span.date {
  font-size: 1.6rem;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}

.blog-details-wrapper .info span.author {
  margin-bottom: 10px;
}

.blog-details-wrapper .info span svg {
  height: 15px;
  width: 15px;
  margin-right: 7px;
  color: #aaa;
}

.blog-details-wrapper .post-content {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 80%;
  flex: 0 0 80%;
  max-width: 80%;
  word-wrap: break-word;
}

.blog-details-wrapper .post-content h2 {
  font-size: 2.6rem;
  font-weight: 600;
  margin-bottom: 30px;
}

.blog-details-wrapper .post-content p {
  margin-bottom: 0;
  font-size: 1.4rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #6f7484;
}

.blog-details-wrapper .post-content img {
  border-radius: 3px;
}

.blog-details-wrapper .post-content blockquote {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  text-align: center;
  color: #232630;
  max-width: 700px;
  margin: 0 auto;
}

.blog-details-wrapper .post-content p + p,
.blog-details-wrapper .post-content p + img,
.blog-details-wrapper .post-content img + p,
.blog-details-wrapper .post-content img + blockquote,
.blog-details-wrapper .post-content blockquote + p {
  margin-top: 30px;
}

.blog-details-wrapper .post-content .images {
  padding: 0 0 30px;
  font-size: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.blog-details-wrapper .post-content .images .image {
  margin-top: 30px;
  width: 48%;
}

.blog-post-details-container {
  background: #ffffff;
  -webkit-box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.03);
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.03);
}

.post-meta {
  background: #f7f9fc;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.post-meta:before, .post-meta:after {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  background: rgba(0, 0, 0, 0.08);
  content: '';
}

.post-meta:before {
  left: 33.33%;
}

.post-meta:after {
  right: 33.33%;
}

.post-meta .post-author {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.post-meta .post-author .avatar {
  width: 70px;
  height: 70px;
  border: 5px solid #ffffff;
  border-radius: 50%;
  overflow: hidden;
}

.post-meta .post-author .name {
  padding-left: 20px;
}

.post-meta .post-author .name p {
  margin-bottom: 0;
  color: #6f7484;
}

.post-meta .post-author .name h5 {
  font-size: 1.6rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.post-meta .post-tag {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 40px;
}

.post-meta .post-tag h6 {
  font-size: 1.4rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-bottom: 15px;
  color: #232630;
}

.post-meta .post-tag .tags a {
  background: #ffffff;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  padding: 3px 10px;
  font-size: 1.2rem;
}

.post-meta .post-tag .tags a:hover {
  border-color: #246df8;
  color: #246df8;
}

.post-meta .post-share {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 40px;
}

.post-meta .post-share h6 {
  font-size: 1.4rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-bottom: 15px;
  color: #232630;
}

.post-meta .post-share .social-buttons a {
  border-radius: 50%;
  font-size: 1.3rem;
  height: 30px;
  width: 30px;
  text-align: center;
  line-height: 30px;
  color: #ffffff;
  margin-right: 5px;
}

.post-meta .post-share .social-buttons a.facebook {
  background: #3b5998;
}

.post-meta .post-share .social-buttons a.twitter {
  background: #1da1f2;
}

.post-meta .post-share .social-buttons a.google {
  background: #ea4335;
}

.post-meta .post-share .social-buttons a.linkedin {
  background: #0077b5;
}

.post-meta .post-share .social-buttons a.pinterest {
  background: #bd081c;
}

.post-meta .post-share .social-buttons a.instagram {
  background: #fd1d1d;
}

.post-comment-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.post-comment-block .comment-respond,
.post-comment-block .comment-area {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 60px 30px;
}

.post-comment-block .comment-respond h4,
.post-comment-block .comment-area h4 {
  font-size: 1.8rem;
  color: #101725;
  margin-bottom: 20px;
}

.post-comment-block .comment-respond {
  position: relative;
}

.post-comment-block .comment-respond:before {
  position: absolute;
  content: '';
  top: 60px;
  right: 0;
  bottom: 60px;
  width: 1px;
  background: rgba(0, 0, 0, 0.08);
}

.post-comment-block .comment-respond .form-group {
  margin-bottom: 30px;
}

.post-comment-block .comment-respond .form-group .form-control {
  height: 50px;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 0;
  font-size: 1.4rem;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.post-comment-block .comment-respond .form-group .form-control:focus {
  border-bottom-color: #246df8;
}

.post-comment-block .comment-respond .form-group textarea.form-control {
  height: 100px;
}

.post-comment-block .comment-respond .button {
  padding: 10px 30px;
  font-size: 1.4rem;
  font-family: "Poppins", sans-serif;
  color: #ffffff;
}

.post-comment-block .comment-area .comments {
  padding: 0;
  margin: 0;
  list-style: none;
}

.post-comment-block .comment-area .comments .comment .comment-wrap {
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.post-comment-block .comment-area .comments .comment .comment-wrap .comment-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.post-comment-block .comment-area .comments .comment .comment-wrap .comment-info .commenter-thumb {
  height: 55px;
  width: 55px;
  border-radius: 50%;
  overflow: hidden;
}

.post-comment-block .comment-area .comments .comment .comment-wrap .comment-info .commenter-info {
  padding-left: 20px;
}

.post-comment-block .comment-area .comments .comment .comment-wrap .comment-info .commenter-info span {
  display: block;
}

.post-comment-block .comment-area .comments .comment .comment-wrap .comment-info .commenter-info span.commenter-name {
  font-size: 1.4rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #101725;
}

.post-comment-block .comment-area .comments .comment .comment-wrap .comment-info .commenter-info span.date {
  font-size: 1.2rem;
  color: #6f7484;
}

.post-comment-block .comment-area .comments .comment .comment-wrap .comment-info .reply {
  margin-left: auto;
}

.post-comment-block .comment-area .comments .comment .comment-wrap .comment-info .reply .reply-button {
  font-size: 1.2rem;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: pointer;
  text-transform: uppercase;
  color: #6f7484;
  background: #f7f9fc;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  padding: 3px 10px;
}

.post-comment-block .comment-area .comments .comment .comment-wrap .comment-info .reply .reply-button:hover {
  color: #246df8;
  border-color: #246df8;
}

.post-comment-block .comment-area .comments .comment .comment-wrap .comment-info .reply .modal-body .comment-respond:before {
  height: 0;
  width: 0;
}

.post-comment-block .comment-area .comments .comment .comment-wrap .comment-body {
  padding-top: 20px;
  color: #6f7484;
}

.post-comment-block .comment-area .comments .comment .child-comment {
  margin-left: 40px;
  margin-top: 30px;
}

.post-comment-block .comment-area .comments .comment:last-child .comment-wrap {
  border-bottom: 0;
  padding-bottom: 0;
}

.post-comment-block .comment-area .comments .comment:last-child .child-comment .comment:last-child .comment-wrap {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  padding-bottom: 30px;
}

.post-comment-block .comment-area .comments .comment + .comment {
  margin-top: 30px;
}

.post-comment-block .comment-area .view-all {
  margin-top: 20px;
  text-align: center;
  font-size: 1.4rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #246df8;
  text-decoration: underline;
}

@media all and (max-width: 991px) {
  .blog-list .content {
    max-width: 470px;
  }
  .post-meta .post-author,
  .post-meta .post-tag,
  .post-meta .post-share {
    padding: 20px;
  }
  .post-meta .post-author .avatar {
    height: 50px;
    width: 50px;
  }
  .post-meta .post-author .name {
    padding-left: 12px;
  }
  .post-meta .post-share {
    width: 100%;
  }
  .blog-details-wrapper {
    display: block;
  }
  .blog-details-wrapper .info {
    padding-bottom: 20px;
  }
  .blog-details-wrapper .post-content {
    max-width: 100%;
  }
}

@media all and (max-width: 767px) {
  .blog-list {
    display: block;
  }
  .blog-list .content {
    max-width: 100%;
    padding: 20px 0;
  }
  .blog-list .read-more {
    margin-left: 0;
    text-align: left;
  }
  .post-meta {
    display: block;
    padding: 20px 0;
  }
  .post-meta:before, .post-meta:after {
    height: 0;
    width: 0;
  }
  .post-comment-block {
    display: block;
  }
}

@media all and (max-width: 439px) {
  .blog-post-wrapper {
    padding: 60px 20px;
  }
  .blog-grid .body {
    padding: 20px;
    padding-left: 60px;
  }
  .blog-grid .body:before {
    left: 20px;
    right: 20px;
  }
  .blog-grid .body .date {
    top: 20px;
    left: 20px;
  }
  .blog-grid .info {
    padding: 10px 20px;
  }
  .blog-details-wrapper .post-content .images {
    display: block;
  }
  .blog-details-wrapper .post-content .images .image {
    width: 100%;
  }
  .post-comment-block .comment-area .comments .comment .child-comment {
    margin-left: 20px;
  }
}

/*---------------------------------------------
  26.0 Contact Style
---------------------------------------------*/
.contact-block {
  padding: 60px 30px 30px;
  background: #ffffff;
  -webkit-box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.03);
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.03);
}

.contact-address {
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  padding: 30px;
  background-image: -webkit-gradient(linear, right top, left top, from(#ffffff), to(#f7f9fc));
  background-image: -webkit-linear-gradient(right, #ffffff, #f7f9fc);
  background-image: -o-linear-gradient(right, #ffffff, #f7f9fc);
  background-image: linear-gradient(to left, #ffffff, #f7f9fc);
}

.contact-address h4 {
  font-size: 1.5rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  color: #232630;
}

.contact-address ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.contact-address ul li {
  padding-left: 25px;
  position: relative;
  color: #6f7484;
}

.contact-address ul li svg {
  position: absolute;
  top: 5px;
  left: 0;
  height: 15px;
  width: 15px;
  color: #aaa;
}

.contact-address ul li + li {
  margin-top: 15px;
}

.contact-form h4 {
  font-size: 1.8rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #232630;
  margin-bottom: 20px;
}

.contact-form form .form-group {
  margin-bottom: 30px;
}

.contact-form form .form-group .form-control {
  height: 50px;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  font-size: 1.4rem;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.contact-form form .form-group .form-control:focus {
  border-bottom-color: #246df8;
}

.contact-form form .form-group textarea.form-control {
  height: 100px;
}

.contact-form form .button {
  background: #023b92;
  border-radius: 3px;
  border: 0;
  color: #ffffff;
  padding: 10px 30px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
}

.contact-form .input-success {
  position: absolute;
  top: 100%;
  left: 0;
}

.contact-form .input-error {
  position: absolute;
  top: 100%;
  left: 0;
}

.input-success {
  border-radius: 3px;
  color: #ffffff;
  padding: 5px 15px !important;
  margin-bottom: 0;
  margin-top: 10px;
  display: none;
}

.input-error {
  border-radius: 3px;
  color: #ffffff;
  padding: 5px 15px !important;
  margin-bottom: 0;
  margin-top: 10px;
  display: none;
}

.input-success {
  background-color: #27ae60;
}

.input-error {
  background-color: #e74c3c;
}

.contact-map {
  margin-top: 60px;
}

.contact-map .cp-map {
  height: 300px;
}

@media all and (max-width: 991px) {
  .contact-form {
    margin-top: 60px;
  }
}

@media all and (max-width: 767px) {
  .contact-block {
    padding: 60px 20px 30px;
  }
}

/*---------------------------------------------
  27.0 Payment Style
---------------------------------------------*/
.block-wrapper {
  background: #ffffff;
  padding: 60px 30px;
  -webkit-box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.03);
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.03);
}

.checkout-form label {
  font-family: "Poppins", sans-serif;
  font-size: 1.4rem;
  color: #6f7484;
}

.checkout-form .package-select {
  display: block;
  padding-bottom: 10px;
}

.checkout-form .package-select .package-select-inputs {
  width: 100%;
}

.checkout-form .package-select .package-select-inputs .form-group {
  margin-bottom: 20px;
}

.checkout-form .payment-method {
  padding-top: 60px;
  padding-bottom: 20px;
}

.checkout-form .payment-method a {
  padding: 5px 15px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  background: #f7f9fc;
  color: #6f7484;
  margin-right: 10px;
  margin-bottom: 10px;
}

.checkout-form .payment-method a i {
  margin-right: 7px;
}

.checkout-form .payment-method a.active, .checkout-form .payment-method a:hover {
  background: #023b92;
  color: #ffffff;
  border-color: #023b92;
}

.checkout-form .form-group {
  margin-bottom: 30px;
}

.checkout-form .form-group .form-control {
  height: 50px;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 0;
  font-size: 1.4rem;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.checkout-form .form-group .form-control:focus {
  border-bottom-color: #246df8;
}

.checkout-form .terms label {
  color: #6f7484;
}

.checkout-form .terms label a {
  color: #246df8;
  text-decoration: underline;
}

.checkout-form .button {
  background: #023b92;
  color: #ffffff;
  padding: 10px 30px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1.4rem;
  border: 0;
  border-radius: 3px;
}

.payment-summery {
  padding: 30px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  background-image: -webkit-gradient(linear, right top, left top, from(#ffffff), to(#f7f9fc));
  background-image: -webkit-linear-gradient(right, #ffffff, #f7f9fc);
  background-image: -o-linear-gradient(right, #ffffff, #f7f9fc);
  background-image: linear-gradient(to left, #ffffff, #f7f9fc);
}

.payment-summery h4 {
  font-size: 1.5rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  margin-bottom: 30px;
}

.payment-summery ul {
  padding: 0;
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  margin: 0;
  list-style: none;
}

.payment-summery ul li {
  color: #6f7484;
  font-size: 1.4rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.payment-summery ul li .amount {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.payment-summery ul li + li {
  margin-top: 10px;
}

.payment-summery .total-amount {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #6f7484;
  font-size: 1.4rem;
  padding-top: 20px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.payment-summery .total-amount .amount {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #246df8;
}

.payment-result {
  text-align: center;
  color: #6f7484;
}

.payment-result .icon {
  height: 100px;
  width: 100px;
  background: #023b92;
  border-radius: 50%;
  text-align: center;
  line-height: 100px;
  margin: 0 auto;
}

.payment-result .icon svg {
  color: #ffffff;
  height: 40px;
  width: 40px;
}

.payment-result h3 {
  font-size: 2.6rem;
  margin-top: 30px;
  margin-bottom: 10px;
  color: #101725;
}

.payment-result h3 span {
  color: #023b92;
  text-decoration: underline;
}

.payment-result > p {
  font-size: 1.8rem;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #6f7484;
}

.payment-result .result {
  margin-top: 60px;
  margin-bottom: 120px;
  border: 1px solid rgba(36, 109, 248, 0.15);
  border-radius: 3px;
  background: #f4f8ff;
  padding: 15px 20px;
  display: inline-block;
}

.payment-result .view-invoice {
  background: #e9f0fe;
  border: 0;
  border-radius: 3px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1.4rem;
  color: #246df8;
  padding: 14px 30px;
}

.payment-result .view-invoice:hover {
  background: #246df8;
  color: #ffffff;
}

.invoice {
  background: #ffffff;
  padding: 60px 30px;
  -webkit-box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.03);
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.03);
}

.invoice-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 60px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.invoice-header .logo-area {
  background: #246df8;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 10px 0;
  position: relative;
  margin-right: 15px;
}

.invoice-header .logo-area:before {
  position: absolute;
  content: '';
  top: 0;
  left: -30px;
  bottom: 0;
  width: 30px;
  background: #246df8;
}

.invoice-header .title {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-left: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.invoice-header .title span {
  font-size: 6.0rem;
  line-height: 1;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-weight: 100;
  color: #6f7484;
}

.invoice-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.invoice-info .left-info h6 {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 15px;
}

.invoice-info .left-info .name {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  margin-bottom: 10px;
}

.invoice-info .left-info p {
  max-width: 250px;
}

.invoice-info .right-info ul {
  padding: 0;
  margin: 0;
  min-width: 170px;
  list-style: none;
}

.invoice-info .right-info ul li {
  padding: 5px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: #6f7484;
}

.invoice-info .right-info ul li .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #101725;
}

.invoice-table {
  padding-top: 60px;
  margin-bottom: 60px;
  overflow-x: auto;
}

.invoice-table table {
  min-width: 400px;
}

.invoice-table table thead {
  width: 100%;
}

.invoice-table table thead tr {
  width: 100%;
}

.invoice-table table tr th {
  background: #f2f2f2;
  border-bottom: 0;
  border-top: 0;
  padding: 10px;
}

.invoice-table table tr td {
  padding: 15px;
  border-top: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.invoice-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.invoice-footer .invoice-by {
  color: #6f7484;
}

.invoice-footer .invoice-by h4 {
  font-size: 1.8rem;
  color: #101725;
  margin-bottom: 30px;
}

.invoice-footer .invoice-by .name {
  font-size: 1.4rem;
  margin-bottom: 10px;
  color: #101725;
}

.invoice-footer .invoice-by .invoice-contact span {
  display: block;
}

.invoice-footer .invoice-by .invoice-contact span a {
  color: #246df8;
}

.invoice-footer .bill ul {
  width: 170px;
  padding: 0;
  margin: 0;
  margin-left: auto;
  list-style: none;
}

.invoice-footer .bill ul li {
  padding: 5px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.invoice-footer .bill ul li .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #101725;
}

.invoice-footer .bill .total {
  margin-top: 30px;
  background: #246df8;
  color: #ffffff;
  min-width: 200px;
  padding: 10px 0 10px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-family: "Poppins", sans-serif;
  position: relative;
}

.invoice-footer .bill .total:before {
  position: absolute;
  content: '';
  top: 0;
  right: -30px;
  width: 30px;
  bottom: 0;
  background: #246df8;
}

.invoice-print {
  text-align: center;
  margin-top: 40px;
}

.invoice-print a {
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  background: #ffffff;
  color: #6f7484;
  padding: 10px 30px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.invoice-print a:hover {
  background: #246df8;
  color: #ffffff;
}

.invoice-print a svg {
  height: 15px;
  width: 15px;
  margin-right: 7px;
}

.terms-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.03);
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.03);
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.terms-container .terms-sidebar {
  background-image: -webkit-gradient(linear, left top, right top, from(#ffffff), to(#f7f9fc));
  background-image: -webkit-linear-gradient(left, #ffffff, #f7f9fc);
  background-image: -o-linear-gradient(left, #ffffff, #f7f9fc);
  background-image: linear-gradient(to right, #ffffff, #f7f9fc);
  padding: 30px;
  width: 25%;
}

.terms-container .terms-sidebar h5 {
  font-size: 1.5rem;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.terms-container .terms-sidebar h5 svg {
  height: 15px;
  width: 15px;
  margin-right: 7px;
  color: #aaa;
}

.terms-container .terms-sidebar .sidebar-menu {
  padding: 0;
  padding-bottom: 10px;
  margin: 0;
  list-style: none;
}

.terms-container .terms-sidebar .sidebar-menu li {
  padding: 5px 0;
  position: relative;
}

.terms-container .terms-sidebar .sidebar-menu li a {
  color: #6f7484;
  font-weight: 400;
  font-size: 1.4rem;
  padding: 5px 0;
}

.terms-container .terms-sidebar .sidebar-menu li:before {
  position: absolute;
  content: '';
  bottom: 0;
  left: 0;
  height: 1px;
  width: 0;
  background: rgba(0, 0, 0, 0.08);
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.terms-container .terms-sidebar .sidebar-menu li:hover a {
  color: #246df8;
}

.terms-container .terms-sidebar .sidebar-menu li:hover:before {
  width: 100%;
}

.terms-container .terms-sidebar .signin-option {
  background: rgba(36, 109, 248, 0.15);
  border: 1px solid rgba(36, 109, 248, 0.4);
  border-radius: 3px;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 0;
}

.terms-container .terms-sidebar .signin-option .buttons .signin {
  background: #023b92;
  border-radius: 3px;
  color: #ffffff;
  padding: 8px 20px;
  margin-right: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.terms-container .terms-sidebar .signin-option .buttons .register {
  color: #246df8;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-decoration: underline;
}

.terms-container .terms-content-wrapper {
  width: 75%;
  padding: 20px;
  background: #ffffff;
}

.policy-tab .nav-tabs {
  font-size: 0;
  border-bottom: 2px solid rgba(0, 0, 0, 0.08);
}

.policy-tab .nav-tabs .nav-item {
  margin-bottom: 0;
  border: 0;
}

.policy-tab .nav-tabs .nav-item .nav-link {
  font-size: 1.6rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #6f7484;
  position: relative;
  padding: 6px 20px;
  border: 0;
}

.policy-tab .nav-tabs .nav-item .nav-link:before {
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  height: 2px;
  content: '';
}

.policy-tab .nav-tabs .nav-item .nav-link:focus, .policy-tab .nav-tabs .nav-item .nav-link:hover {
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.policy-tab .nav-tabs .nav-item .nav-link.active {
  border: 0;
  color: #246df8;
}

.policy-tab .nav-tabs .nav-item .nav-link.active:before {
  background: #246df8;
}

.policy-tab .tab-content {
  padding: 60px 0;
  color: #6f7484;
}

.policy-tab .tab-content h4 {
  font-size: 1.8rem;
  color: #101725;
  margin-bottom: 20px;
}

.policy-tab .tab-content h5 {
  font-size: 1.6rem;
  color: #101725;
  margin-bottom: 20px;
}

.policy-tab .tab-content p + h5,
.policy-tab .tab-content ul + h5 {
  margin-top: 40px;
}

.policy-tab .tab-content ul {
  padding: 0;
  margin: 0;
  margin-top: 20px;
  list-style: none;
  font-size: 0;
  padding-left: 40px;
}

.policy-tab .tab-content ul li {
  font-size: 1.4rem;
  position: relative;
}

.policy-tab .tab-content ul li:before {
  position: absolute;
  content: '\f058';
  font-family: 'Font Awesome\ 5 Free';
  font-weight: 900;
  top: 2px;
  left: -20px;
  color: #aaa;
  font-size: 1.2rem;
}

.policy-tab .tab-content ul li + li {
  margin-top: 8px;
}

.policy-tab .tab-content .substyle {
  padding-left: 20px;
}

.policy-tab .tab-content .substyle li:before {
  content: '';
}

.policy-tab .tab-content .substyle li span {
  font-size: 1.4rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #101725;
}

.policy-tab .tab-content .substyle li span:before {
  content: '\f0da';
  font-family: 'Font Awesome\ 5 Free';
  font-weight: 900;
  margin-right: 5px;
}

@media all and (max-width: 991px) {
  .payment-summery {
    margin-top: 60px;
  }
  .terms-container {
    display: block;
  }
  .terms-container .terms-content-wrapper {
    width: 100%;
  }
  .terms-container .terms-sidebar {
    width: 100%;
  }
}

@media all and (max-width: 539px) {
  .invoice-header .logo-area img {
    height: 30px;
  }
  .invoice-header .title span {
    font-size: 3.0rem;
  }
  .invoice-info {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .invoice-info .left-info {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .invoice-info .right-info {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    margin-bottom: 20px;
  }
  .invoice-info .right-info ul li {
    padding: 0;
  }
  .invoice-footer {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .invoice-footer .invoice-by {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .invoice-footer .bill {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    padding-bottom: 20px;
  }
  .invoice-footer .bill .total {
    margin-top: 10px;
  }
}

@media all and (max-width: 479px) {
  .policy-tab .nav-tabs {
    display: block;
    border-bottom: 0;
  }
  .policy-tab .nav-tabs .nav-item {
    width: 100%;
    text-align: center;
  }
  .policy-tab .nav-tabs .nav-item .nav-link {
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 3px;
  }
  .policy-tab .nav-tabs .nav-item .nav-link.active {
    border: 1px solid #246df8;
    border-radius: 3px;
    color: #246df8;
  }
  .policy-tab .nav-tabs .nav-item .nav-link.active:before {
    height: 0;
    width: 0;
  }
  .policy-tab .nav-tabs .nav-item + .nav-item {
    margin-top: 15px;
  }
}

/*---------------------------------------------
  28.0 Account Style
---------------------------------------------*/
.access-page-bg {
  background: url("../images/bg/access-bg.jpg") no-repeat center;
  background-size: cover;
}

.header-2.access-page-nav {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.header-2.access-page-nav .header-top {
  margin-bottom: 0;
  padding: 30px 0;
}

.top-nav .account-page-link {
  background: #023b92;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1.4rem;
  padding: 9px 30px;
  border-radius: 3px;
}

.access-form .form-header {
  background: #f5f5f5;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 50px;
}

.access-form .form-header h5 {
  font-size: 1.4rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #232630;
}

.access-form .form-header h5 svg {
  height: 14px;
  width: 14px;
  margin-right: 8px;
}

.access-form .account-type {
  margin-bottom: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.access-form .account-type label {
  cursor: pointer;
  width: 45%;
}

.access-form .account-type label span {
  padding: 15px 10px;
  background: #f7f9fc;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1.4rem;
  color: #232630;
  text-align: center;
  width: 100%;
  display: block;
}

.access-form .account-type label input[type="radio"]:checked + span {
  color: #246df8;
  background: rgba(36, 109, 248, 0.2);
  border-color: transparent;
}

.access-form .account-type label input {
  display: none;
}

.access-form .form-group {
  margin-bottom: 30px;
}

.access-form .form-group .form-control {
  height: 50px;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 0;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 1.4rem;
}

.access-form .form-group .form-control:focus {
  border-bottom-color: #246df8;
}

.access-form .more-option {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: #6f7484;
  margin-bottom: 30px;
}

.access-form .more-option .form-check .form-check-input {
  margin-top: 6px;
}

.access-form .more-option .form-check .form-check-label {
  margin-left: 7px;
}

.access-form .more-option .custom-radio + label .dot {
  border-radius: 5px;
}

.access-form .more-option .custom-radio + label .dot:before {
  border-radius: 2px;
}

.access-form .more-option.terms .form-check-label a {
  color: #246df8;
  text-decoration: underline;
}

.access-form .more-option a:hover {
  color: #246df8;
}

.access-form .button {
  color: #ffffff;
  padding: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.access-form .shortcut-login {
  margin-top: 30px;
}

.access-form .shortcut-login span {
  font-size: 1.4rem;
  font-family: "Roboto", sans-serif;
  color: #6f7484;
  margin-bottom: 10px;
  display: inline-block;
}

.access-form .shortcut-login .buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.access-form .shortcut-login .buttons a {
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  padding: 14px 30px;
  font-size: 1.4rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #101725;
  text-align: center;
  width: 45%;
}

.access-form .shortcut-login .buttons a i {
  margin-right: 7px;
}

.access-form .shortcut-login .buttons a.facebook i {
  color: #3b5998;
}

.access-form .shortcut-login .buttons a.google i {
  color: #ea4335;
}

.access-form .shortcut-login p {
  font-size: 1.4rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #6f7484;
  margin-top: 20px;
}

.access-form .shortcut-login p a {
  font-weight: 500;
}

.access-form .shortcut-login p a:hover {
  color: #246df8;
}

@media all and (max-width: 991px) {
  .access-page-nav .header-top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .access-page-nav .header-top .top-nav {
    display: block !important;
    padding: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    position: static;
    width: auto;
  }
}

@media all and (max-width: 767px) {
  .access-page-bg {
    background: transparent;
  }
}

@media all and (max-width: 575px) {
  .access-page-nav .header-top .top-nav {
    display: none !important;
  }
}

@media all and (max-width: 375px) {
  .access-form .shortcut-login .buttons {
    display: block;
  }
  .access-form .shortcut-login .buttons a {
    width: 100%;
  }
  .access-form .shortcut-login .buttons a + a {
    margin-top: 20px;
  }
  .access-form .shortcut-login {
    text-align: center;
  }
  .access-form .shortcut-login .buttons {
    text-align: center;
  }
  .access-form .shortcut-login .buttons a {
    width: 180px;
    margin: 0 auto;
  }
}
