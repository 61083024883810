/*---------------------------------------------
	23.0 Working Process Style
---------------------------------------------*/

$primaryColor: #246df8;
$fontColor: #6f7484;
$white: #ffffff;
$black: #000000;
$blackRussian: #101725;
$blackRussianLite: #232630;
$aliceBlue: #f7f9fc;
$aliceBlueLite: #f9fbfe;
$aliceBluedark: #515666;
$solitude: #f0f3f7;
$snow: #fbfbfb;


$headerFont: 'Poppins', sans-serif;
$bodyFont: 'Roboto', sans-serif;

.how-it-work {
	padding: 60px 30px;
}

.working-process {
	position: relative;
	overflow: hidden;
	&:before {
		position: absolute;
		content: url(../images/dot.png);
		top: 35px;
		left: 13px;
	}
	h3 {
		font-size: 1.8rem;
		padding-bottom: 15px;
		span {
			color: $primaryColor;
			margin-right: 10px;
		}
	}
	p {
		padding-left: 47px;
		color: $fontColor;
	}
}

.working-process + .working-process {
	margin-top: 60px;
}


@media all and (max-width: 991px) {
	.working-process-thumb {
		margin-bottom: 50px;
	}
}