.mainlogo {
  height: 68px;
}

.footer-widget.footer-newsletter .newsletter-form .button {
  background: rgb(2, 59, 146) !important;
  border-color: rgb(2, 59, 146) !important;
}

.fa-angle-up {
  background: rgb(2, 59, 146) !important;
  color: white !important;
}

.cp-nav-2 .navbar-collapse .navbar-nav .menu-item.post-job a {
  background: rgb(2, 59, 146) !important;
  color: #ffffff !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500 !important;
  border-radius: 3px !important;
  padding: 11px 20px !important;
}

.searchAndFilter.searchAndFilter-2 {
  background: rgb(2, 59, 146) !important;
}

.loader {
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.location-wise-dropdown:before {
  border-bottom: 2px solid rgba(255, 255, 255, 0.4) !important;
}