
/*---------------------------------------------
	16.0 Advice Style
---------------------------------------------*/

$primaryColor: #246df8;
$fontColor: #6f7484;
$white: #ffffff;
$black: #000000;
$blackRussian: #101725;
$blackRussianLite: #232630;
$aliceBlue: #f7f9fc;
$aliceBlueLite: #f9fbfe;
$aliceBluedark: #515666;
$solitude: #f0f3f7;
$snow: #fbfbfb;


$headerFont: 'Poppins', sans-serif;
$bodyFont: 'Roboto', sans-serif;

.advice {
	padding: 30px;
	padding-bottom: 0;
	border: 1px solid rgba($black, .08);
	border-radius: 3px;
	margin-bottom: 30px;
	transition: all .3s ease;
	.body {
		display: flex;
		padding-bottom: 20px;
		border-bottom: 1px solid rgba($black, .08);
		.date {
			.day {
				font-size: 2.4rem;
				font-family: $headerFont;
				font-weight: 500;
				line-height: 1;
			}
			.month {
				font-size: 1.4rem;
				font-weight: 500;
				font-family: $headerFont;
				color: $primaryColor;
				text-transform: uppercase;
			}
		}
		.content {
			padding-left: 20px;
			h4 {
				font-size: 1.6rem;
				font-family: $headerFont;
				font-weight: 500;
				margin-bottom: 15px;
			}
			p {
				color: $fontColor;
			}
		}
	}
	.info {
		display: flex;
		justify-content: space-between;
		padding: 15px 0;
		a {
			color: $fontColor;
			svg {
				height: 16px;
				margin-right: 2px;
			}
			&:hover {
				color: $primaryColor;
			}
		}
	}
	&:hover {
	  box-shadow: 0px 15px 40px 0px rgba(237, 237, 237, 0.4);
	  .body {
	  	.content {
	  		h4 {
	  			color: $primaryColor;
	  		}
	  	}
	  }
	}
}


