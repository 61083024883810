
/*---------------------------------------------
    10.0 Form Style
---------------------------------------------*/


$primaryColor: #246df8;
$fontColor: #6f7484;
$white: #ffffff;
$black: #000000;
$blackRussian: #101725;
$blackRussianLite: #232630;
$aliceBlue: #f7f9fc;
$aliceBlueLite: #f9fbfe;
$aliceBluedark: #515666;
$solitude: #f0f3f7;
$snow: #fbfbfb;


$headerFont: 'Poppins', sans-serif;
$bodyFont: 'Roboto', sans-serif;

form {
    position: relative;
}

.newsletter-form .newsletter-error, 
.newsletter-form .newsletter-success {
    position: absolute;
    top: 100%;
    left: 0;
    color: $white !important;
    margin-top: 20px;
    display: none;
}

.newsletter-error {
    background: #e74c3c;
    text-align: left;
    padding: 0 5px;
    color: $white;
}

.newsletter-success {
    background: #16a085;
    padding: 0 5px;
    text-align: left;
    color: $white;
}