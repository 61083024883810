

/*---------------------------------------------
	14.0 Call-to-action Style
---------------------------------------------*/
$primaryColor: #246df8;
$fontColor: #6f7484;
$white: #ffffff;
$black: #000000;
$blackRussian: #101725;
$blackRussianLite: #232630;
$aliceBlue: #f7f9fc;
$aliceBlueLite: #f9fbfe;
$aliceBluedark: #515666;
$solitude: #f0f3f7;
$snow: #fbfbfb;


$headerFont: 'Poppins', sans-serif;
$bodyFont: 'Roboto', sans-serif;

.call-to-action-box {
	padding: 40px 20px;
	border-radius: 5px;
	border: 1px solid rgba($black, .08);
	background-image: linear-gradient(to bottom, rgba($primaryColor, .1), rgba($primaryColor, 0));
	padding-left: 45%;
	position: relative;
	.icon {
		position: absolute;
		bottom: 0px;
		left: 40px;
	}
	span {
		font-size: 1.5rem;
		font-weight: 400;
		color: #6e7383;
		text-transform: uppercase;
	}
	h3 {
		font-size: 2.6rem;
		font-weight: 500;
		color: $blackRussian;
	}
	a {
		text-transform: uppercase;
		font-family: $headerFont;
		font-weight: 600;
		margin-top: 40px;
		i {
			opacity: 0;
			transition: all .3s ease;
			position: relative;
			transform: translateX(-10px);
			margin-left: 5px;
		}
		&:hover {
			i {
				opacity: 1;
				transform: translateX(0);
			}
		}
	}
	&.candidate-box {
		a {
			color: #35c38f;
		}
	}
	&.employer-register {
		a {
			color: #246df8;
		}
	}
}

.call-to-action-bg {
	background: url(../images/bg/cta-2-bg.jpg) no-repeat center;
	background-size: cover;
}

.call-to-action-2 {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	.call-to-action-content {
		color: $white;
		// margin-bottom: 30px;
		h2 {
			font-size: 2.6rem;
			margin-bottom: 20px;
			font-weight: 500;
		}
		p {
			max-width: 450px;
		}
	}
	.call-to-action-button {
		.button {
			border: 2px solid rgba($white, .2);
			border-radius: 3px;background: rgba($white, .1);
			padding: 15px 30px;
			color: $white;
			font-size: 1.4rem;
			font-family: $headerFont;
			font-weight: 600;
			&:before {
				content: '\f067';
				font-family: 'Font Awesome\ 5 Free';
				font-weight: 900;
				margin-right: 10px;
			}
			&:hover {
				background: $white;
				border-color: $white;
				color: $primaryColor;
			}
		}
		span {
			margin: 0 10px;
			color: $white;
		}
	}
}

.newsletter-bg {
	background: url(../images/bg/newsletter-bg.jpg) no-repeat center center;
	background-size: cover;
}

.newsletter-wrap {
	color: $white;
	h3 {
		font-size: 2.6rem;
		font-family: $headerFont;
		font-weight: 500;
		margin-bottom: 10px;
	}
	p {
		max-width: 350px;
	}
}


.newsletter-form {
	margin-top: 30px;
	max-width: 450px;
	.form-group {
		width: calc(100% - 150px);
		margin-right: 30px;
		.form-control {
			height: 60px;
			border: 0;
			padding: 0;
			width: 100%;
			border-radius: 0;
			border-bottom: 2px solid rgba($white, .2);
			background: transparent;
			outline: none;
			color: $white;
			box-shadow: none;
			font-size: 1.4rem;
			font-family: $headerFont;
			font-weight: 400;
			&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			  color: $white;
			}
			&::-moz-placeholder { /* Firefox 19+ */
			  color: $white;
			}
			&:-ms-input-placeholder { /* IE 10+ */
			  color: $white;
			}
			&:-moz-placeholder { /* Firefox 18- */
			  color: $white;
			}
			&:focus {
				border-bottom-color: $white;
			}
		}
	}
	.button {
		padding: 17px 25px;
		background: rgba($white, .1);
		border: 2px solid rgba($white, .2);
		border-radius: 5px;
		color: $white;
		font-family: $headerFont;
		font-weight: 500;
		font-size: 1.4rem;
		i {
			margin-left: 10px;
		}
		&:hover {
			background: $white;
			border-color: $white;
			color: $primaryColor;
		}
	}
}

@media all and (max-width: 991px) {
	.call-to-action-box.employer-register {
		margin-top: 30px;
	}
	.call-to-action-2 .call-to-action-content {
		margin-bottom: 30px;
	}
}

@media all and (max-width: 479px) {
	.call-to-action-box {
		padding-left: 20px;
		.icon {
			position: static;
			margin-bottom: 30px;
		}
	}
}

@media all and (max-width: 439px) {
	.newsletter-wrap {
		.newsletter-form {
			.form-group {
				width: 100%;
				margin-right: 0;
				margin-bottom: 20px;
			}
		}
	}
	.call-to-action-2 {
		.call-to-action-button {
			span {
				display: block;
				margin: 10px 0;
			}
		}
	}
}