

/*----------------------------------------------
	1.0 General Style
-----------------------------------------------*/
$primaryColor: #246df8;
$fontColor: #6f7484;
$white: #ffffff;
$black: #000000;
$blackRussian: #101725;
$blackRussianLite: #232630;
$aliceBlue: #f7f9fc;
$aliceBlueLite: #f9fbfe;
$aliceBluedark: #515666;
$solitude: #f0f3f7;
$snow: #fbfbfb;


$headerFont: 'Poppins', sans-serif;
$bodyFont: 'Roboto', sans-serif;


html {
  font-size: 62.5%;
}

body {
	padding: 0;
	margin: 0;
	font-family: $bodyFont;
	font-size: 1.4rem;
	line-height: 1.85;
	position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $headerFont;
	font-weight: 700;
	line-height: 1.3;
	margin-top: 0;
	margin-bottom: 0;
	color: inherit;
	a {
		color: inherit;
		&:hover {
			color: inherit;
		}
	}
}

h1 {
	font-size: 6.4rem;
}

h2 {
	font-size: 4.8rem;
}

h3 {
	font-size: 3.6rem;
}

h4 {
	font-size: 2.8rem;
}

h5 {
	font-size: 2.2rem;
}

h6 {
	font-size: 1.8rem;
}

p {
	color: inherit;
	line-height: 1.85;
	font-size: 1.4rem;
	&:last-child {
	  margin-bottom: 0;
	  padding-bottom: 0;
	}
}

a {
	transition: all .3s ease;
}
 
a,
a:hover,
a:focus {
	text-decoration: none;
	display: inline-block;
	color: inherit;
	outline: none;
}

.button {
	border: 1px solid transparent;
	outline: none;
	box-shadow: none;
	border-radius: 3px;
	cursor: pointer;
	transition: all .3s ease;
}

.primary-color {
	color: $primaryColor;
}

.primary-bg {
	background: $primaryColor;
}

.white-text {
	color: $white;
}

.white-bg {
	background: $white;
}

.alice-bg {
	background: $aliceBlue;
}

.section-padding {
	padding: 120px 0;
}

.section-padding-top {
	padding-top: 120px;
}

.section-padding-bottom {
	padding-bottom: 120px;
}

.section-padding-150 {
	padding: 150px 0;
}

.padding-top-90 {
	padding-top: 90px;
}

.padding-bottom-90 {
	padding-bottom: 90px;
}

.padding-top-100 {
	padding-top: 100px;
}

.padding-bottom-100 {
	padding-bottom: 100px;
}

.padding-top-80 {
	padding-top: 80px;
}

.padding-bottom-70 {
	padding-bottom: 70px;
}

.padding-top-70 {
	padding-top: 70px;
}

.padding-top-60 {
	padding-top: 60px;
}

.padding-bottom-60 {
	padding-bottom: 60px;
}

.padding-bottom-40 {
	padding-bottom: 40px;
}

.section-padding-60 {
	padding: 60px 0;
}

.border-bottom {
	border-bottom: 1px solid rgba($black, .08) !important;
}

.border-top {
	border-top: 1px solid rgba($black, .08) !important;
}

.margin-balance {
	margin-bottom: -30px;
	zoom: 1;
	position: relative;
	overflow: hidden;
}

.section-wrapper+.section-wrapper {
	margin-top: 50px;
}

.fix {
	&:before {
		display: block;
		content: '';
		clear: both;
	}
}


/*------------------------------------------
	Responsive
-------------------------------------------*/

@media (max-width: 991px) {
	.right-content-md {
		margin-top: 60px;
	}
}


@media all and (max-width: 767px) {
	h1 {
		font-size: 4.8rem;
	}

	h2 {
		font-size: 3.6rem;
	}

	h3 {
		font-size: 3.2rem;
	}

	h4 {
		font-size: 2.4rem;
	}
	
	.right-content-sm {
		margin-top: 60px;
	}
}