
/*---------------------------------------------
	20.0 Footer Style
---------------------------------------------*/
$primaryColor: #246df8;
$fontColor: #6f7484;
$white: #ffffff;
$black: #000000;
$blackRussian: #101725;
$blackRussianLite: #232630;
$aliceBlue: #f7f9fc;
$aliceBlueLite: #f9fbfe;
$aliceBluedark: #515666;
$solitude: #f0f3f7;
$snow: #fbfbfb;


$headerFont: 'Poppins', sans-serif;
$bodyFont: 'Roboto', sans-serif;

.footer-bg {
	background: $white;
}

.footer-social {
	text-align: right;
	.social-icons {
		padding: 0;
		margin: 12px 0 0;
		list-style: none;
		display: inline-block;
		li {
			display: inline-block;
			padding-left: 25px;
			// color: $fontColor;
			a {
				color: rgba($fontColor, .5);
			}
			a:hover {
				color: $fontColor;
			}
			&:first-child {
				padding-left: 0;
			}
		}
	}
	.select-language {
		margin-left: 20px;
		padding-left: 20px;
		border-left: 1px solid rgba($black, .08);
		&.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
			width: 60px;
			outline: none !important;
		}
		&.bootstrap-select .dropdown-toggle:focus {
			outline: none !important;
		}
		&.bootstrap-select .dropdown-menu li a {
			font-size: 1.4rem;
		}
		.btn-light {
			background: transparent;
			padding: 5px 0;
			border: 0;
			font-size: 1.5rem;
		}
	}
}

.footer-widget {
	margin-bottom: 40px;
	h4 {
		font-size: 1.8rem;
		font-family: $headerFont;
		font-weight: 600;
		margin-bottom: 35px;
	}
	&.widget-about {
		.description {
			font-size: 1.4rem;
			margin-bottom: 20px;
			color: $fontColor;
		}
		.about-contact {
			display: block;
			color: $fontColor;
			font-family: $headerFont;
			font-weight: 400;
			font-size: 1.5rem;
			svg {
				height: 15px;
				margin-right: 15px;
			}
		}
	}
	&.footer-shortcut-link {
		ul {
			padding: 0;
			margin: 0;
			list-style: none;
			li + li {
				margin-top: 10px;
			}
			li {
				color: $fontColor;
				&:hover {
					color: $primaryColor;
				}
			}
		}
	}
	&.footer-newsletter {
		p {
			font-size: 1.4rem;
			color: $fontColor;
			max-width: 300px;
		}
		.newsletter-form {
			position: relative;
			.form-group {
				.form-control {
					border-bottom-color: rgba($primaryColor, .4);
					font-family: $headerFont;
					color: $fontColor;
					&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
					  color: $fontColor;
					}
					&::-moz-placeholder { /* Firefox 19+ */
					  color: $fontColor;
					}
					&:-ms-input-placeholder { /* IE 10+ */
					  color: $fontColor;
					}
					&:-moz-placeholder { /* Firefox 18- */
					  color: $fontColor;
					}
					&:focus {
						border-bottom-color: $primaryColor;
					}
				}
			}
			.button {
				background: $primaryColor;
				border-color: $primaryColor;
				&:hover {
					color: $white;
				}
				&:focus {
					outline: none;
				}
			}
		}
	}
}

.footer-bottom {
	padding: 40px 0;
	.copyright-text {
		color: $fontColor;
		padding-top: 15px;
		a:hover {
			color: $primaryColor;
		}
	}
	.footer-app-download {
		text-align: center;
		a {
			border: 2px solid rgba($black, .08);
			border-radius: 3px;
			padding: 12px 15px 12px 45px;
			margin: 0 5px;
			font-size: 1.5rem;
			font-family: $headerFont;
			font-weight: 500;
			color: $blackRussian;
			position: relative;
			&:before {
				position: absolute;
				top: 11px;
				left: 15px;
			}
			&:hover {
				color: $primaryColor;
				border-color: $primaryColor;
			}
		}
		.apple-app:before  {
			content: url(../images/apple.png);
		}
		.android-app:before  {
			content: url(../images/google.png);
		}
	}
	.back-to-top {
		text-align: right;
		a {
			font-family: $headerFont;
			font-weight: 400;
			font-size: 1.3rem;
			text-transform: uppercase;
			color: $fontColor;
			i {
				height: 55px;
				width: 55px;
				font-size: 2.2rem;
				border: 1px solid rgba($black, .08);
				border-radius: 50%;
				background: $snow;
				color: $fontColor;
				line-height: 55px;
				text-align: center;
				margin-left: 15px;
				transition: all .3s ease;
			}
			&:hover {
				color: $primaryColor;
				i {
					border-color: $primaryColor;
					color: $primaryColor;
				}
			}
		}
	}
}

.slim-footer {
	padding: 47px 50px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	text-align: center;
	p {
		margin-bottom: 0;
		color: $fontColor;
		a:hover {
			color: $primaryColor;
		}
	}
	.footer-social-link {
		ul {
			padding: 0;
			margin: 0;
			list-style: none;
			li {
				display: inline-block;
				margin-right: 25px;
				a {
					color: rgba($fontColor, .5);
					&:hover {
						color: $fontColor;
					}
				}
				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
}

@media all and (min-width: 1200px) {
	.slim-footer {
		width: 1040px;
	}
}

@media (max-width: 1199px) and (min-width: 992px) {
	.footer-bottom {
		.footer-app-download {
			text-align: left;
		}
	}
}

@media all and (max-width: 991px) {
	.footer-widget {
		h4 {
			margin-bottom: 20px;
		}
	}
	.footer-bottom {
		padding: 40px 0;
		.copyright-text {
			padding-top: 25px;
			text-align: center;
		}
		.back-to-top {
			text-align: center;
			margin-top: 20px;
		}
	}
}

@media all and (max-width: 767px) {
	.footer-logo {
		text-align: center;
		margin-bottom: 25px;
	}
	.footer-social {
		text-align: center;
	}
	.footer-social .social-icons li {
		padding-left: 10px;
	}
	.footer-widget {
		&.footer-newsletter {
			.newsletter-form {
				.form-group {
					width: 100%;
					display: block;
					margin-bottom: 20px;
					margin-right: 0;
					max-width: 350px;
				}
			}
		}
	}
	.slim-footer {
		display: block;
		text-align: center;
		.footer-social-link {
			margin-top: 20px;
		}
	}
}

@media all and (max-width: 379px) {
	.footer-bottom {
		.footer-app-download {
			text-align: center;
			a {
				display: block;
				width: 160px;
				margin: 0 auto;
				+ a {
					margin-top: 15px;
				}
			}
		}
	}
}