
/*---------------------------------------------
	15.0 Testimonial Style
---------------------------------------------*/

$primaryColor: #246df8;
$fontColor: #6f7484;
$white: #ffffff;
$black: #000000;
$blackRussian: #101725;
$blackRussianLite: #232630;
$aliceBlue: #f7f9fc;
$aliceBlueLite: #f9fbfe;
$aliceBluedark: #515666;
$solitude: #f0f3f7;
$snow: #fbfbfb;


$headerFont: 'Poppins', sans-serif;
$bodyFont: 'Roboto', sans-serif;

.testimonial {
	text-align: center;
	.testimonial-quote {
		margin-bottom: 40px;
	}
	.testimonial-for {
		.testimonial-item {
			padding-bottom: 60px;
			outline: none;
			p {
				font-size: 2.0rem;
				font-family: $headerFont;
				font-weight: 400;
				max-width: 700px;
				margin: 0 auto;
			}
			h5 {
				font-size: 1.4rem;
				font-family: $headerFont;
				font-weight: 500;
				color: $primaryColor;
				text-transform: uppercase;
				margin-top: 30px;
			}
		}
	}
	.testimonial-nav {
		max-width: 500px;
		margin: 0 auto;
		.commenter-thumb {
			text-align: center;
			outline: none;
			.commenter {
				padding: 0 20px;
				border-radius: 50%;
			}
			.comapnyLogo {
				display: inline-block;
				margin-top: 15px;
			}
			&.slick-active {
				opacity: .3;
				cursor: pointer;
				&.slick-current {
					opacity: 1;
				}
			}
		}
	}
}