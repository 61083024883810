
/*---------------------------------------------
	24.0 FAQ Style
---------------------------------------------*/
$primaryColor: #246df8;
$fontColor: #6f7484;
$white: #ffffff;
$black: #000000;
$blackRussian: #101725;
$blackRussianLite: #232630;
$aliceBlue: #f7f9fc;
$aliceBlueLite: #f9fbfe;
$aliceBluedark: #515666;
$solitude: #f0f3f7;
$snow: #fbfbfb;


$headerFont: 'Poppins', sans-serif;
$bodyFont: 'Roboto', sans-serif;

.faq-tab {
	text-align: center;
	justify-content: center;
	border-bottom: 0;
	margin-bottom: 60px;
	.nav-item {
		margin-bottom: 0;
		.nav-link {
			color: $fontColor;
			background: transparent;
			border: 0;
			padding: 10px 40px 15px;
			font-family: $headerFont;
			font-size: 1.5rem;
			font-weight: 700;
			line-height: 1;
			position: relative;
			&:before {
				content: '';
				position: absolute;
				bottom: -2px;
				left: 0;
				height: 2px;
				width: 100%;
				background: #ebebeb;
			}
			&:hover,
			&:focus {
				display: block;
			}
			&.active {
				background: transparent;
				color: $primaryColor;
				&:before {
					background: $primaryColor;
				}
			}
		}
	}
}

.card {
	border: 0;
	border-radius: 0;
	border-bottom: 1px solid rgba($black, .08);
	.card-header {
		padding: 0;
		border-bottom: 0;
		background: transparent;
		h5 {
			.btn-link {
				padding: 20px 30px 20px 0;
				font-size: 1.8rem;
				font-family: $headerFont;
				font-weight: 500;
				color: $blackRussian;
				display: block;
				width: 100%;
				text-align: left;
				position: relative;
				white-space: normal;
				&:before {
					position: absolute;
					top: 22px;
					right: 0;
					content: "\e622";
					font-family: 'themify';
					font-size: 1.4rem;
					height: 25px;
					width: 25px;
					line-height: 25px;
					text-align: center;
					border-radius: 50%;
					color: $fontColor;
					border: 1px solid rgba($black, .08);
				}
				&.collapsed {
					&:before {
						content: "\e61a";
					}
				}
				&:hover,
				&:focus {
					text-decoration: none;
				}
			}
		}
	}
	.card-body {
		padding: 0;
		padding-bottom: 20px;
		p {
			font-size: 1.5rem;
			color: $fontColor;
			a {
				color: $primaryColor;
			}
			+p {
				margin-top: 20px;
			}
		}
	}
}

.accordion .card:first-of-type,
.accordion .card:not(:first-of-type):not(:last-of-type) {
	border-bottom: 1px solid rgba($black, .08);
}

.quick-contact {
	padding: 0 30px;
	text-align: center;
	.icon {
		margin-bottom: 30px;
	}
	h4 {
		 font-size: 2.6rem;
		 font-weight: 500;
		 margin-bottom: 10px;
	}
	p {
		font-size: 1.4rem;
		color: $fontColor;
	}
	.buttons {
		margin-top: 20px;
		a {
			padding: 8px 25px;
			border-radius: 3px;
			font-family: $headerFont;
			font-size: 1.4rem;
			font-weight: 500;
			margin: 0 8px 10px;
			&.email {
				background: $primaryColor;
				color: $white;
			}
			&.chat {
				background: rgba(#19bb4c, .15);
				color: #19bb4c;
			}
		}
	}
	.call {
		margin-top: 15px;
		font-size: 1.4rem;
		span {
			font-size: 1.6rem;
			color: $primaryColor;
		}
	}
}


@media all and (max-width: 767px) {
	.faq-tab {
		display: block;
		.nav-item {
			.nav-link {
				padding: 15px;
				border-radius: 5px;
				border: 1px solid rgba($black, .08);
				&.active {
					border-color: $primaryColor;
				}
				&:before {
					height: 0;
					width: 0;
				}
			}
		}
		.nav-item + .nav-item {
			margin-top: 15px;
		}
	}
}