
/*---------------------------------------------
	13.0 Animated-number Style
---------------------------------------------*/
$primaryColor: #246df8;
$fontColor: #6f7484;
$white: #ffffff;
$black: #000000;
$blackRussian: #101725;
$blackRussianLite: #232630;
$aliceBlue: #f7f9fc;
$aliceBlueLite: #f9fbfe;
$aliceBluedark: #515666;
$solitude: #f0f3f7;
$snow: #fbfbfb;


$headerFont: 'Poppins', sans-serif;
$bodyFont: 'Roboto', sans-serif;

.fact-bg {
	background: url(../images/bg/fact-bg.jpg) no-repeat center center;
	background-size: cover;
}

.fact {
	text-align: center;
	color: $white;
	margin-bottom: 30px;
	.fact-icon {
		svg {
			height: 35px;
		}
	}
	.fact-number {
		font-size: 30px;
		margin-top: 20px;
		font-family: $headerFont;
		font-weight: 500;
		margin-bottom: 0;
		line-height: 1;
	}
	.fact-name {
		font-family: $headerFont;
		font-weight: 400;
		font-size: 1.5rem;
		margin-top: 0;
	}
}